import { Checkbox, TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import momentTimeZone from 'moment-timezone';

import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import BasicTable from '../../components/basic/BasicTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgressBar from '../../components/basic/ProgressBar';
import { faSquare, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { generateColumnsDef, getRendersInfo } from '../../libs/utilsReports';



function Payouts(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCompany(company);
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const lang = utils.getSessionItem('calimaco-lang').split("-")[0]
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_Payouts')
    const { formValidation: formValidationMessage } = useFormValidation('Form_Payouts_Message')
    let from = new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000));
    let to = new Date();
    if (from !== null) from = momentTimeZone(from).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
    if (to != null) to = momentTimeZone(to).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));

    const cleanFilter = {
        status: "",
        method: "",
        created_date_from: from, //Para que filtre desde ayer
        created_date_to: to,
        playerID: "",
        payoutID: "",
        amountFrom: "",
        amountTo: "",
        currency: companyDetails.currencies.length === 1 ? companyDetails.currencies[0] : "",
        currencyReport: companyDetails.currencies.length === 1 ? companyDetails.currencies[0] : "",
        bankName: ""
    }
    const [filter, setFilter] = useState(cleanFilter);
    const [excelFilter, setExcelFilter] = useState(null)
    const initialCheckboxFilter = {
        verified: 2,
        low_odds: 2,
        promotions_redeemed: 2,
        magic_number: 2,
        cashouts: 2,
        depositante: 2,
    }

    const [valueFilter, setValueFilter] = useState(initialCheckboxFilter)
    const [operations, setOperations] = useState([]);
    const [nextStatus, setNextStatus] = useState([]);
    const [selectedOperations, setSelectedOperations] = useState([]);
    const [progressBar, setProgressBar] = useState({
        show: false,
        percentage: '0%',
        textProcess: t('Processing')
    })
    const [changeStatus, setChangeStatus] = useState({
        to_status: "",
        rejectReason: "",
        rejectClientMessage: ""
    });
    const [groups, setGroups] = useState([]);
    const [reportColumnsDef, setReportColumnsDef] = useState([]);
    const [filtersReport, setFiltersReport] = useState([])
    const [tableColumns, setTableColumns] = useState([])

    const goBack = () => {
        props.history.push('/home');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Manage payouts'),
        urlHelp: '',
        idDoc: props.idDoc
    };


    //Consultamos todos los grupos de las compañia
    useEffect(function () {
        adminClient.getGroups(session, company).then(
            function (msg) {
                setGroups(msg.groups);
            }, function (err) {
                console.log("Payout error " + err.code);
            }
        );

    }, []);


    const handleSelectionRow = (operations) => {
        console.log('Se ha seleccionado')
        const operationsIds = operations.map((objeto) => objeto.operation);
        setSelectedOperations(operationsIds);
    }

    const handleClickDetails = (db, user, operation) => {
        utils.setSessionItem("current-operation", operation);
        utils.setSessionItem("current-user", operation.split('.')[0] + "." + user);
        window.open('/cashflow/payouts/details/' + user, '_blank');
    }

    const handleClickLowOdds = (cellValue, rowOriginal, t, cellProps) => {
        const { user, operation } = rowOriginal
        adminClient.getPayoutExtraLowOdds(
            session,
            company,
            operation.split(".")[0],
            user,
            operation.split(".")[1]
        ).then(
            function (response) {
                let data = response.data;
                let columns = [
                    { Header: t("Operation"), accessor: 'operation', typeCell: 'text_translated', filter: 'text_translated', Filter: true },
                    { Header: t("Bet type"), accessor: 'bet_type', typeCell: 'text_translated', filter: 'text_translated', Filter: true },
                    { Header: t("Operation date"), accessor: 'operation_date', typeCell: 'date', filter: 'text', Filter: true },
                    {
                        Header: t("Currency"), accessor: 'currency', typeCell: 'text', filter: 'text', Filter: true,
                        Cell: (props) => {
                            let amount = props.row.original.amount;
                            let currency = props.row.original.currency;
                            return utils.formatCurrency(amount / 100, currency)
                        }
                    },
                    { Header: t("Odds"), accessor: 'odds', typeCell: 'text', filter: 'text', Filter: true },
                ]
                props.showAlert(t('Low odds'), <BasicTable key="table-LowOdds" data={data} columns={columns} showFilterColumn={true} hidePagination />, undefined, 'm');
            }, function (err) {
                alert(err.description);
            }
        )
    }

    const handleClickPromotionsRedeemed = (cellValue, rowOriginal, t, cellProps) => {

        const { user, operation } = rowOriginal


        adminClient.getPayoutExtraPromotionsRedeemed(
            session,
            company,
            operation.split(".")[0],
            user,
            operation.split(".")[1]
        ).then(
            function (response) {
                let data = response.data;
                let columns = [
                    { Header: t("Promotion"), accessor: 'promotion', typeCell: 'text_translated', filter: 'text_translated', Filter: true },
                    { Header: t("Operation"), accessor: 'operation', typeCell: 'text', filter: 'text', Filter: true },
                    { Header: t("Operation date"), accessor: 'operation_date', typeCell: 'date', filter: 'text', Filter: true },
                    {
                        Header: t("Currency"), accessor: 'currency', typeCell: 'text', filter: 'text', Filter: true,
                        Cell: (props) => {
                            let amount = props.row.original.amount;
                            let currency = props.row.original.currency;
                            return utils.formatCurrency(amount / 100, currency)
                        }
                    }
                ]
                props.showAlert(t('Redeemed promotions'), <BasicTable key="table-PromotionsRedeemed" data={data} columns={columns} showFilterColumn={true} hidePagination />, undefined, 'm');


            }, function (err) {
                alert(err.description);
            }
        )
    }

    const filtersToView = (filters) => {
        let filtersField = filters.map(obj => obj.field)
        setFiltersReport(filtersField)
    }

    const configColumnsToView = (columnsReport) => {

        let columns = {}

        columns["at_category"] = {
            Header: t("AT"), typeCell: 'text', filter: 'text', accessor: "at_category", Filter: true,
            Cell: (props) => {
                let at_category = parseInt(props.row.original.at_category);
                let htmlValue
                if (at_category !== undefined && at_category !== null && at_category !== 0) {
                    htmlValue = "<span class='calimaco_table_red'>" + groups[at_category].name.replace("AT - ", "") + "</span>"
                }
                else {
                    htmlValue = '<span class="calimaco_table_green">---</span>'
                }
                return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />

            }
        }
        columns["altenar_category"] = {
            Header: t("Altenar"), typeCell: 'text', filter: 'text', accessor: "altenar_category", Filter: true,
            Cell: (props) => {
                let altenar_category = parseInt(props.row.original.altenar_category);
                return altenar_category !== undefined && altenar_category !== null && altenar_category !== 0 ? groups[altenar_category].name.replace("ALTENAR - ", "") : '-'
            }
        }
        columns["bc_category"] = {
            Header: t("BC"), typeCell: 'text', filter: 'text', accessor: "bc_category", Filter: true,

            Cell: (props) => {
                let bc_category = parseInt(props.row.original.bc_category);
                return bc_category !== undefined && bc_category !== null && bc_category !== 0 ? groups[bc_category].name.replace("BETCONSTRUCT - ", "") : '-'
            }
        }
        columns["register_days"] =
        {
            Header: t("register_days"), typeCell: 'text', filter: 'text', accessor: "register_days", Filter: true,
            Cell: (props) => {
                let registerDays = props.row.original.register_days;
                return registerDays !== undefined && registerDays !== null && registerDays !== 0 ? parseInt(registerDays) : '-'
            }
        }


        let configColumns = [{
            Header: t("ID"), accessor: 'operation', typeCell: 'text', filter: 'text', Filter: true, disableSortBy:true,
            Cell: (props) => {
                let operation = props.row.original.operation;
                return <div className={" cursor-pointer "} style={{ display: "flex" }}
                    onClick={(evt) => handleClickDetails(props.row.original.db, props.row.original.user, props.row.original.operation, evt)}>
                    <FontAwesomeIcon icon="eye" style={{ marginRight: '5px' }} /> {" " + operation}
                </div>
            }
        }]

        columnsReport.forEach((col) => {


            if (columns[col.accessor] !== undefined) {
                if (columns[col.accessor] !== null) configColumns.push(columns[col.accessor])
            } else {
                if (col.accessor === "low_odds") {
                    col.renderData.renderData["ZeroHandleClick"] = handleClickLowOdds
                }
                if (col.accessor === "promotions_redeemed") {
                    col.renderData.renderData["ZeroHandleClick"] = handleClickPromotionsRedeemed
                }
                configColumns.push(col)
            }
        })

        setTableColumns(configColumns)

    };

    useEffect(function () {
        adminClient.getReport(
            session, company, "payouts_cashflow", [], null, null,
            "", "").then(
                async function (msg) {
                    let columnsDef = generateColumnsDef(msg.data.def, t, {}, props.showAlert, props.handleCloseAlert)
                    columnsDef.map(col => {
                        if (col.typeCell !== 'light') col["Filter"] = true
                        //col["disableSortBy"]=true
                        return col
                    })
                    filtersToView(msg.data.def.config.filters)
                    setReportColumnsDef(columnsDef)
                    configColumnsToView(columnsDef)
                }
                , function (err) {
                    console.log("ERROR, getReport Payouts Config" + err)
                }
            );
    }, [])

    const onChangeHandlerFilter = (e) => {
        const { name, value } = e.target || e;
        setFilter((prevState) => ({ ...prevState, [name]: value }));
    };

    const onChangeHandlerStatus = (e) => {
        const { name, value } = e.target || e;
        setChangeStatus((prevState) => ({ ...prevState, [name]: value }));
    };

    const refreshColumns = () => {
        configColumnsToView(reportColumnsDef)
    }

    const loadNextStatus = (from_status) => {
        adminClient.getGrantedNextPayoutStatus(
            session,
            company,
            from_status).then(
                function (response) {
                    let valores = response.status.map(objeto => objeto.status);
                    setNextStatus(valores);
                },
                function (err) {
                    console.error("ERROR in load next payout status")
                }
            );
    }

    const showLoadingSearch = () => {
        if (props.showAlert) props.showAlert("LOADING", t("Searching..."), true);
    }
    const showLoadingDownload = () => {
        if (props.showAlert) props.showAlert("LOADING", t("Downloading..."), true);
    }
    const closeLoading = () => {
        if (props.handleCloseAlert) props.handleCloseAlert()
    }

    const search = (e) => {
        if (e !== "reload") {
            setProgressBar({ show: false })
        }
        if (formValidation.validate()) {
            refreshColumns()
            showLoadingSearch();
            loadNextStatus(filter.status);
            let searchFilter = [];


            if (filter.payoutID !== "") {
                searchFilter.push({ "field": "t.db", "type": "text", "value": filter.payoutID.split(".")[0] });
                searchFilter.push({ "field": "t.operation", "type": "text", "value": filter.payoutID.split(".")[1] });
            }
            else {
                searchFilter.push({ "field": "t.operation_date", "type": "time_range", "value": [utils.unformatDate(filter.created_date_from), utils.unformatDate(filter.created_date_to)] });
                searchFilter.push({ "field": "t.status", "type": "string", "value": filter.status });
                if (filter.playerID !== "") {
                    searchFilter.push({ "field": "t.user", "type": "text", "value": filter.playerID });
                }
                if (filter.amountFrom !== "") {
                    searchFilter.push({ "field": "t.amount", "type": "number", "value": ">= " + filter.amountFrom * 100 });
                }
                if (filter.amountTo !== "") {
                    searchFilter.push({ "field": "t.amount", "type": "number", "value": "<= " + filter.amountTo * 100 });
                }
                if (filter.method !== "") {
                    searchFilter.push({ "field": "t.method", "type": "string", "value": filter.method });
                }
                if (filter.bankName !== "") {
                    searchFilter.push({ "field": 'pa.details', "type": "like", "value": filter.bankName });
                }
                if (filter.currency !== "") {
                    searchFilter.push({ "field": "t.currency", "type": "=", "value": filter.currency })
                }
                if (filter.atGroupsAllowed !== undefined && filter.atGroupsAllowed.length > 0) {
                    let elements = "";
                    filter.atGroupsAllowed.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.at_category", "type": "number_set", "value": "in " + elements });
                }

                if (filter.atGroupsForbidden !== undefined && filter.atGroupsForbidden.length > 0) {
                    let elements = "";
                    filter.atGroupsForbidden.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.at_category", "type": "number_set", "value": "notin " + elements });
                }

                if (filter.altenarGroupsAllowed !== undefined && filter.altenarGroupsAllowed.length > 0) {
                    let elements = "";
                    filter.altenarGroupsAllowed.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.altenar_category", "type": "number_set", "value": "in " + elements });
                }

                if (filter.altenarGroupsForbidden !== undefined && filter.altenarGroupsForbidden.length > 0) {
                    let elements = "";
                    filter.altenarGroupsForbidden.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.altenar_category", "type": "number_set", "value": "notin " + elements });
                }

                if (filter.betConstructGroupsAllowed !== undefined && filter.betConstructGroupsAllowed.length > 0) {
                    let elements = "";
                    filter.betConstructGroupsAllowed.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.bc_category", "type": "number_set", "value": "in " + elements });
                }

                if (filter.betConstructGroupsForbidden !== undefined && filter.betConstructGroupsForbidden.length > 0) {
                    let elements = "";
                    filter.betConstructGroupsForbidden.forEach(element => {
                        if (elements.length > 0) {
                            elements += ",";
                        }
                        elements += element;
                    });
                    searchFilter.push({ "field": "e.bc_category", "type": "number_set", "value": "notin " + elements });
                }
            }
            setExcelFilter(searchFilter);
            const limit = companyDetails?.config?.backoffice?.payouts?.payoutsManagementLimit || 1000;
            adminClient.getReport(
                session, company, "payouts_cashflow", searchFilter, null, null,
                "limit 0 ," + limit + " ", "created_date desc").then(
                    function (msg) {
                        closeLoading();
                        let operationsResult = msg.data.data;
                        setValueFilter(initialCheckboxFilter);
                        setOperations(operationsResult);
                        setSelectedOperations([])
                        setFilter((prevState) => ({ ...prevState, currencyReport: filter.currency }));

                    }
                    , function (err) {
                        console.log("ERROR, getReport Payouts " + err)
                    }
                );
        }
    }

    const clean = (e) => {
        setFilter(cleanFilter);
        setExcelFilter([]);
        setValueFilter(initialCheckboxFilter)
    }

    const excel = (e) => {
        showLoadingDownload();
        adminClient.getExcelReport(session, company, "payouts_cashflow", excelFilter, null, null, "", lang).then(
            function (resp) {
                closeLoading();
                let a = document.createElement("a");
                var file = new Blob([resp], { type: 'application/xls' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL
                a.download = "payouts.xls";
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }, function (datos) {
                //Mostramos el error en la respuesta 
                console.log("HAY UN ERROR")
            }
        );
    }

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total
        setProgressBar({ show: true, percentage: percentage + '%', textProcess: t('Processing') + " " + data.partial + "/" + data.total })
    }

    const getResultOK = (data) => {
        return (<label>{t("Processed OK")}: {data.length} </label>)
    }

    const getResultNOK = (data) => {
        let resultNok = {}
        if (data.length > 0) {
            resultNok = data.map((element) => element.db + "." + element.operation + ":" + element.error)
            return (<label>{t("Processed NOK") + "(" + data.length + ")"}: {resultNok.join(' ')} </label>)
        } else {
            return (<label>{t("Without Error")}</label>)
        }
    }

    const update = (e) => {
        if (formValidationMessage.validate()) {
            props.showAlert(
                t('Payouts batch'),
                <p>{t('Are you sure you want to change the status to ') + " " + t(changeStatus.to_status) + "?"}</p>,
                [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
                <button type='button' className='btn btn-primary'
                    onClick={(evt) => {
                        updateAux(evt)
                        props.handleCloseAlert()
                    }}>
                    {t('Ok')}</button>]
            )
        }
        const updateAux = (evt) => {
            if (formValidationMessage.validate()) {
                setProgressBar({ show: true, percentage: '0%', textProcess: t('Processing') })
                adminClient.batchUpdatePayouts(
                    session,
                    company,
                    selectedOperations,
                    changeStatus.to_status,
                    changeStatus.rejectReason,
                    changeStatus.rejectClientMessage,
                    handleResponse).then(
                        function (msg) {
                            setProgressBar({ show: true, textProcess: t('Processed'), textOK: getResultOK(msg.data), textNOK: getResultNOK(msg.operationsErrors) })
                            setValueFilter(initialCheckboxFilter);
                            search("reload");
                            props.showAlert(t('Manage payouts'), t('Updated succesfull'));
                            setChangeStatus({ to_status: "", rejectReason: "", rejectClientMessage: "" })
                            setSelectedOperations([])
                        }
                        , function (err) {
                            setProgressBar({ show: false })
                            props.showAlert(t('Manage payouts'), t('Updating error'));

                        }
                    );
            }
        }
    }


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_Payouts">
                <div className="row" >
                    <div className="col-12 col-md-3">
                        <BasicSelect
                            label={t("Status")}
                            idProperty="status"
                            basicInfoName="PayoutStatus"
                            onChange={onChangeHandlerFilter}
                            name="status"
                            value={filter.status}
                            translate={{ property: "name", prefix: "" }}
                            required
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <BasicSelect
                            label={t("Payment method")}
                            idProperty="method"
                            basicInfoName="PayoutMethods"
                            name="method"
                            onChange={onChangeHandlerFilter}
                            value={filter.method}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <DateAndTimePicker
                            required
                            label={t("Requested from")}
                            value={filter.created_date_from}
                            showTime={true}
                            callBackFunction={(value) => onChangeHandlerFilter({ name: "created_date_from", value: value })}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <DateAndTimePicker
                            required
                            label={t("To")}
                            value={filter.created_date_to}
                            showTime={true}
                            callBackFunction={(value) => onChangeHandlerFilter({ name: "created_date_to", value: value })}
                        />
                    </div>
                    <div className="col-12 col-md-2">
                        <TextField
                            label={t("Player ID")}
                            value={filter.playerID}
                            name="playerID"
                            onChange={onChangeHandlerFilter}
                            placeholder="db.playerId"
                        ></TextField>
                    </div>
                    <div className="col-12 col-md-2">
                        <TextField
                            label={t("Payout ID")}
                            value={filter.payoutID}
                            name="payoutID"
                            onChange={onChangeHandlerFilter}
                            placeholder="db.payoutId"
                        ></TextField>
                    </div>
                    <div className="col-12 col-md-2">
                        <TextField
                            label={t("Amount from")}
                            value={filter.amountFrom}
                            name="amountFrom"
                            onChange={onChangeHandlerFilter}
                            type="number"
                        ></TextField>
                    </div>
                    <div className="col-12 col-md-2">
                        <TextField
                            label={t("To")}
                            value={filter.amountTo}
                            name="amountTo"
                            onChange={onChangeHandlerFilter}
                            type="number"
                        ></TextField>
                    </div>
                    <div className="col-12 col-md-2">
                        <BasicSelect
                            label="Currencies"
                            idProperty="currency"
                            nameProperty="currency"
                            name="currency"
                            data="currencies"
                            basicInfoName="CurrenciesCompany"
                            onChange={onChangeHandlerFilter}
                            value={filter.currency}
                        />

                    </div>
                    <div className="col-12 col-md-2">
                        <TextField label={t("Bank")} value={filter.bankName} name="bankName" onChange={onChangeHandlerFilter}></TextField>
                    </div>
                </div>

                {/* Comprobamos si vienen los filtros opcionales en los filtros del report */}
                {/* PARTE OPCIONAL */}
                <div className='row'>
                    {filtersReport.includes("e.bc_category") &&
                        <>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("Betconstruct Allowed")}
                                    basicInfoName="GroupsByRange"
                                    onChange={onChangeHandlerFilter}
                                    name="betConstructGroupsAllowed"
                                    params={[
                                        { property: "init", value: 400 },
                                        { property: "end", value: 499 }
                                    ]}
                                    value={filter.betConstructGroupsAllowed}
                                    multiple
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("Betconstruct Forbbiden")}
                                    basicInfoName="GroupsByRange"
                                    onChange={onChangeHandlerFilter}
                                    name="betConstructGroupsForbidden"
                                    params={[
                                        { property: "init", value: 400 },
                                        { property: "end", value: 499 }
                                    ]}
                                    value={filter.betConstructGroupsForbidden}
                                    multiple
                                />
                            </div>
                        </>
                    }
                    {filtersReport.includes("e.altenar_category") &&
                        <>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("Altenar Allowed")}
                                    basicInfoName="GroupsByRange"
                                    onChange={onChangeHandlerFilter}
                                    name="altenarGroupsAllowed"
                                    params={[
                                        { property: "init", value: 100 },
                                        { property: "end", value: 199 }
                                    ]}
                                    value={filter.altenarGroupsAllowed}
                                    multiple
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("Altenar Forbbiden")}
                                    basicInfoName="GroupsByRange"
                                    onChange={onChangeHandlerFilter}
                                    name="altenarGroupsForbidden"
                                    params={[
                                        { property: "init", value: 100 },
                                        { property: "end", value: 199 }
                                    ]}
                                    value={filter.altenarGroupsForbidden}
                                    multiple
                                />
                            </div>
                        </>
                    }
                    {filtersReport.includes("e.at_category") &&
                        <>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("AT Allowed")}
                                    basicInfoName="Groups"
                                    onChange={onChangeHandlerFilter}
                                    name="atGroupsAllowed"
                                    value={filter.atGroupsAllowed}
                                    filters={[{ property: "category", value: "AT" }]}
                                    multiple
                                />
                            </div>
                            <div className="col-12 col-md-6">
                                <BasicSelect
                                    idProperty="group"
                                    label={t("AT Forbbiden")}
                                    basicInfoName="Groups"
                                    onChange={onChangeHandlerFilter}
                                    name="atGroupsForbbiden"
                                    value={filter.atGroupsForbbiden}
                                    filters={[{ property: "category", value: "AT" }]}
                                    multiple
                                />
                            </div>
                        </>
                    }
                </div>
            </form>
            <div className="row">
                <div className="col-12">

                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={search}
                    >
                        {t('Search')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={clean}
                    >
                        {t('Clean')}
                    </button>
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={excel}
                        disabled={operations.length === 0}
                    >
                        {t('Excel')}
                    </button>
                </div>
            </div>
            <form id="Form_Payouts_Message">

                <div className='row'>
                    {operations.length > 0 &&
                        <div style={{ textAlign: "center" }}>
                            <label style={{ fontWeight: "bold" }}>
                                Resultados: {operations.length}
                                {filter.currencyReport !== "" && (
                                    <>
                                        &nbsp;Cantidad total: {utils.formatCurrency(operations.reduce((total, objeto) => total + objeto.amount, 0) / 100, filter.currencyReport)}
                                    </>
                                )}
                                &nbsp;&nbsp;&nbsp;Seleccionados: {selectedOperations.length}
                                {filter.currencyReport !== "" && (
                                    <>
                                        &nbsp;Cantidad seleccionada: {utils.formatCurrency(selectedOperations.reduce((total, id) => {
                                            const objetoEncontrado = operations.find(objeto => objeto.operation === id);
                                            if (objetoEncontrado) {
                                                return total + objetoEncontrado.amount;
                                            }
                                            return total;
                                        }, 0) / 100, filter.currencyReport)}
                                    </>
                                )}
                            </label>
                        </div>
                    }
                    {selectedOperations.length > 0 &&
                        nextStatus.length > 0 &&
                        <div className="row mt-4" >
                            <div className="col-12 col-md-4">
                                <BasicSelect
                                    required
                                    label={t("Change to status")}
                                    idProperty="status"
                                    nameProperty="status"
                                    name="to_status"
                                    basicInfoName="PayoutStatus"
                                    filters={[{ property: "status", value: nextStatus }]}
                                    onChange={onChangeHandlerStatus}
                                    value={changeStatus.to_status}
                                    translate={{ property: "status", prefix: "" }}
                                />
                            </div>

                            {changeStatus.to_status === 'DENIED' &&
                                <div className="row" >
                                    <div className="col-12 col-md-6">
                                        <TextField multiline label={t("Reason")} name="rejectReason" value={changeStatus.rejectReason} onChange={onChangeHandlerStatus}
                                            required={companyDetails.config?.backoffice?.payouts?.deniedMessage}
                                        ></TextField>
                                    </div>
                                    <div className="col-12 col-md-6">
                                        <TextField multiline label={t("Message to client")} name="rejectClientMessage" value={changeStatus.rejectClientMessage} onChange={onChangeHandlerStatus}
                                            required={companyDetails.config?.backoffice?.payouts?.deniedMessage}
                                        ></TextField>
                                    </div>
                                </div>
                            }

                        </div>

                    }
                    <div className="col-12  ">
                        {progressBar.show &&
                            <ProgressBar percentage={progressBar.percentage} text={progressBar.textProcess} textOK={progressBar.textOK} textNOK={progressBar.textNOK} />
                        }
                    </div>
                </div>
            </form>
            {selectedOperations.length > 0 &&
                nextStatus.length > 0 &&
                <div className="row">

                    <div className="col-12 col-md-4">
                        <button type="button" className="btn btn-primary" onClick={update}>{t("Change status")}</button>
                    </div>
                </div>
            }
            <div className='row'>
                {tableColumns.length > 0 &&
                    <BasicTable key="table-payouts" data={operations} columns={tableColumns} showFilterColumn={true} showSelection={nextStatus?.length > 0} showAlert={props.showAlert} handleCloseAlert={props.handleCloseAlert} setSelected={handleSelectionRow} sizePageOptions={[10, 50, 100, 400, 1000]} />
                }
            </div>
        </LayoutSection >
    )
}
export default withTranslation()(Payouts);