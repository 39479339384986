
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function MixIcon({ label,mainIcon,secondIcon }) {
  
    const iconWrapperStyle = {
      position: "relative",
      display: "inline-block"    
    };
  
    const plusIconStyle = {
      position: "absolute",
      top: -2, 
      right: -8, 
      backgroundColor: "none",
      borderRadius: "50%",
      padding: "2px",
    };
  
    const labelStyle = {
      display: "inline-block",
      verticalAlign: "middle",
      fontSize: "1rem",
      fontWeight: "normal",
      marginLeft: "10px"
    };
  
    return (
      <div style={{ display: "flex", alignItems: "center" }}>    
        <div style={iconWrapperStyle}>
          {mainIcon && <FontAwesomeIcon icon={mainIcon} size="1x" /> }
          {secondIcon && <FontAwesomeIcon icon={secondIcon} style={plusIconStyle} /> }
        </div>     
        {label && <span style={labelStyle}>{label}</span>}
      </div>
    );
  }
  export default (MixIcon)