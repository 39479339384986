import React from "react";
import utils from "../utils";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class AgentsMenu extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { t } = this.props;
        return (
            <>

            {/* <div className="container mt-3 clmc-agents-menu-panel">
                {utils.isGranted('VIEW_SUBAGENTS_SECTION') &&
                    <div className="row justify-content-center">
                        <div className="col-6 ">
                            <button tabIndex={4} type="button" className="btn btn-primary btn-agents-menu col-12  " onClick={() => { this.props.history.push("/agents/agents_search") }} >{t("Search agents")}</button>

                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/new_agent") }}  >{t("New agent")}</button>
                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/my_agents") }}  >{t("My agents")}</button>

                        </div>
                        <div className="col-6 ">
                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/user_search") }} >{t("Search players")}</button>
                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/new_user") }}  >{t("New player")}</button>
                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/my_users") }} >{t("My players")}</button>
                        </div>
                        <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/activity") }} >{t("Summary")}</button>

                    </div>
                }
                {!utils.isGranted('VIEW_SUBAGENTS_SECTION') &&
                    <div className="row justify-content-center">
                        <div className="col-12 ">
                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/user_search") }} >{t("Search players")}</button>
                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/new_user") }}  >{t("New player")}</button>
                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/my_users") }} >{t("My players")}</button>
                            <button tabIndex={4} type="button" className="btn btn-primary  btn-agents-menu col-12 " onClick={() => { this.props.history.push("/agents/activity") }} >{t("Summary")}</button>
                        </div>

                    </div>
                }
            </div > */}
</>


        );
    }
}
export default withRouter(withTranslation()(AgentsMenu));