import React from "react"
import { withRouter } from 'react-router-dom';
import AdminClient from "../AdminClient";
import { withTranslation } from "react-i18next";

import utils from "../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Report from "../components/basic/Report";

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import Autocomplete from '@mui/material/Autocomplete';

import BasicSelect from "../components/basic/BasicSelect";
import WeeksCheckbox from "../components/basic/WeeksCheckbox";




import { InputAdornment } from "@mui/material";


import { CKEditor } from 'ckeditor4-react';
import Alert from "../components/Alert";

import { DateAndTimePicker } from "../components/DateAndTimePicker";
import AllowedForbiddenList from "../components/basic/AllowedForbiddenList";
import DoubleList from "../components/basic/DoubleList";
import TreeSelector from "../components/basic/TreeSelector";
import momentTimeZone from 'moment-timezone';

import File from "../components/basic/File";
import Tab from "../components/basic/BasicTabs";
import CMS from "../components/basic/CMS";
import WebContentData from "../components/basic/WebContentData";
import DocButton from "../components/basic/DocButton";
import Editor from "../components/basic/Editor";


class MarketingPromotionDetails extends React.Component {

  constructor(props) {

    super(props);


    this.goBack = this.goBack.bind(this);
    this.handleUpdateStatus = this.handleUpdateStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.closePopup = this.closePopup.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.onTagsChange = this.onTagsChange.bind(this);
    this.handleUpdateEmailSubject = this.handleUpdateEmailSubject.bind(this);
    this.handleUpdateEmail = this.handleUpdateEmail.bind(this);
    this.handleUpdateEmailCK = this.handleUpdateEmailCK.bind(this);
    this.deleteImage = this.deleteImage.bind(this);
    this.handleUpdateMaxPercentageRedeeem = this.handleUpdateMaxPercentageRedeeem.bind(this);
    this.handleVerifyPromotion = this.handleVerifyPromotion.bind(this);
    this.handleUpdatePercentageAmount = this.handleUpdatePercentageAmount.bind(this);
    this.handleUpdateTotalCodes = this.handleUpdateTotalCodes.bind(this);
    this.handleUpdateCodesLength = this.handleUpdateCodesLength.bind(this);
    this.handleUpdateCodesPrefix = this.handleUpdateCodesPrefix.bind(this);
    this.handleUpdateCodes = this.handleUpdateCodes.bind(this);
    this.handleUpdateMaxTimesPerUser = this.handleUpdateMaxTimesPerUser.bind(this);
    this.handleUpdateMaxUsers = this.handleUpdateMaxUsers.bind(this);
    this.generateCodes = this.generateCodes.bind(this);
    //this.handleUpdateManualInternalMachines = this.handleUpdateManualInternalMachines.bind(this);
    this.onTreeProviderMachinesChecked = this.onTreeProviderMachinesChecked.bind(this);
    this.handleUpdateMaxDailyUsers = this.handleUpdateMaxDailyUsers.bind(this);
    this.handleUpdateExternalDefinition = this.handleUpdateExternalDefinition.bind(this);
    this.handleUpdateVirtualGood = this.handleUpdateVirtualGood.bind(this);
    this.handleUpdatePromotion = this.handleUpdatePromotion.bind(this);
    this.handleUpdateName = this.handleUpdateName.bind(this);
    this.handleUpdateInitDate = this.handleUpdateInitDate.bind(this);
    this.handleUpdateEndDate = this.handleUpdateEndDate.bind(this);
    this.handleUpdateInitDate2 = this.handleUpdateInitDate2.bind(this);
    this.handleUpdateEndDate2 = this.handleUpdateEndDate2.bind(this);
    this.handleUpdateAmount = this.handleUpdateAmount.bind(this);
    this.handleUpdateCurrency = this.handleUpdateCurrency.bind(this);
    this.handleUpdatePercentage = this.handleUpdatePercentage.bind(this);
    this.handleUpdateAccount = this.handleUpdateAccount.bind(this);
    this.handleUpdateWR = this.handleUpdateWR.bind(this);
    this.handleUpdateWeekDays = this.handleUpdateWeekDays.bind(this);
    this.handleUpdateFirstDateOfMonth = this.handleUpdateFirstDateOfMonth.bind(this);
    this.handleUpdateLastDateOfMonth = this.handleUpdateLastDateOfMonth.bind(this);
    this.handleUpdateProvider = this.handleUpdateProvider.bind(this);
    this.handleUpdateExternalMachineId = this.handleUpdateExternalMachineId.bind(this);
    this.handleUpdateExternalMachines = this.handleUpdateExternalMachines.bind(this);
    this.handleUpdateInternalMachines = this.handleUpdateInternalMachines.bind(this);
    this.handleUpdateExternalPromotionId = this.handleUpdateExternalPromotionId.bind(this);
    this.handleUpdateType = this.handleUpdateType.bind(this);
    this.handleUpdateChoice = this.handleUpdateChoice.bind(this);
    this.handleUpdateBatchPromotionAmount = this.handleUpdateBatchPromotionAmount.bind(this);
    this.handleUpdateExpirationDays = this.handleUpdateExpirationDays.bind(this);
    this.handleUpdateActivationExpirationDays = this.handleUpdateActivationExpirationDays.bind(this);

    this.handleUpdateRegisterSelected = this.handleUpdateRegisterSelected.bind(this);
    this.handleUpdateBuddy = this.handleUpdateBuddy.bind(this);
    this.handleUpdateNotCancel = this.handleUpdateNotCancel.bind(this);
    this.handleUpdateExternalRoundsNumber = this.handleUpdateExternalRoundsNumber.bind(this);
    this.handleUpdateExternalRoundAmount = this.handleUpdateExternalRoundAmount.bind(this);
    this.handleUpdateMaxRedeeem = this.handleUpdateMaxRedeeem.bind(this);
    this.handleUpdateAllWeekDays = this.handleUpdateAllWeekDays.bind(this);
    this.handleUpdateMaxAwardedAmount = this.handleUpdateMaxAwardedAmount.bind(this);
    this.handleUpdateMinAwardedAmount = this.handleUpdateMinAwardedAmount.bind(this);
    this.handleUpdateMaxAmount = this.handleUpdateMaxAmount.bind(this);
    this.handleUpdateMinAmount = this.handleUpdateMinAmount.bind(this);
    this.handleUpdateSummaryTitle = this.handleUpdateSummaryTitle.bind(this);
    this.handleUpdateSummaryImage = this.handleUpdateSummaryImage.bind(this);
    this.handleUpdateTitle = this.handleUpdateTitle.bind(this);
    this.handleUpdateImage = this.handleUpdateImage.bind(this);
    this.handleUpdateCalculator = this.handleUpdateCalculator.bind(this);
    this.handleDuplicate = this.handleDuplicate.bind(this);
    this.handleUpdateMaxInAccount = this.handleUpdateMaxInAccount.bind(this);
    this.handleDuplicatePromotion = this.handleDuplicatePromotion.bind(this);
    this.handleUpdateCodeType = this.handleUpdateCodeType.bind(this);
    this.handleUpdateInternalForbiddenMachines = this.handleUpdateInternalForbiddenMachines.bind(this);
    this.handleDuplicateName = this.handleDuplicateName.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.submitDuplicate = this.submitDuplicate.bind(this);
    //this.submitDuplicate2 = this.submitDuplicate2.bind(this);
    this.handleUpdateAffiliates = this.handleUpdateAffiliates.bind(this);
    this.onTreeInternalMachinesChecked = this.onTreeInternalMachinesChecked.bind(this);
    this.handleUpdateInternalForbiddenTags = this.handleUpdateInternalForbiddenTags.bind(this);
    this.handleUpdateInternaAllowedTags = this.handleUpdateInternaAllowedTags.bind(this);
    this.handleUpdateTerms = this.handleUpdateTerms.bind(this);
    this.setCkEditors = this.setCkEditors.bind(this);
    this.saveDataCMS = this.saveDataCMS.bind(this);
    this.saveDataCMSSumary = this.saveDataCMSSumary.bind(this);
    this.saveDataPromotion = this.saveDataPromotion.bind(this);
    this.getDefinitionFields = this.getDefinitionFields.bind(this);
    this.submitDelete = this.submitDelete.bind(this);
    this.handleUpdateMaxActivationCash = this.handleUpdateMaxActivationCash.bind(this);
    this.adminClient = new AdminClient();
    this.onChangeBasicSelect = this.onChangeBasicSelect.bind(this);
    this.onChangeHandlerConfigCMS = this.onChangeHandlerConfigCMS.bind(this);
    this.onChangeHandlerConfigCMS2 = this.onChangeHandlerConfigCMS2.bind(this);
    this.checkGroupsMembers=this.checkGroupsMembers.bind(this)
    this.savePromotions=this.savePromotions.bind(this);

    this.isLoaging = false;

    if (utils.getSessionItem("user") === undefined || utils.getSessionItem("user") === null) {
      this.props.history.push('/login');
    }

    var promotionId = utils.getSessionItem("current-promotion-id");
    var promotionDeleted = utils.getSessionItem("current-promotion-deleted");

    var grantedStatus = utils.isGranted("SAVE_MARKETING_PROMOTIONS_STATUS");

    let urlName = this.props.location.pathname.split('/')
    if (urlName.length > 4) promotionId = urlName[urlName.length - 1]
    var newPromotion = promotionId === "-1";
    if (newPromotion) {
      promotionId = "";
    }

    var thisAux = this;

    var companyAux = {};
    JSON.parse(localStorage.getItem("Companies")).forEach(company => {

      if (company.company === localStorage.getItem("Company")) {
        companyAux = company;

      }

    });

    const langs = utils.getCompany(utils.getSessionItem("Company")).langs.split(",")
    var cmsInit = langs.length === 1
      ? {
        summary_title: "",
        summary_body: "",
        summary_image: "",
        title: "",
        body: "",
        image: "",
      }
      : langs.reduce((result, lang) => {
        result[lang] = {
          summary_title: "",
          summary_body: "",
          summary_image: "",
          title: "",
          body: "",
          image: "",
        };
        return result;
      }, {});

    var termsInit = langs.length === 1
      ? ""
      : langs.reduce((result, lang) => {
        result[lang] = "";
        return result;
      }, {});

    this.state = {
      newPromotion: promotionId === "",
      hideExternalConfig: {},
      promotionDeleted: promotionDeleted === "1",
      showModal: false,
      emailHTML: '',
      company: companyAux,
      newPromotion: newPromotion,
      providers: [],
      provider: {},
      duplicatePromotion: promotionId + " - NEW",

      selectedMachines: [],
      selectedInternalMachines: [],
      selectedInternalForbiddenMachines: [],

      selectedInternalForbiddenTags: [],
      selectedInternalAllowedTags: [],
      editCodes: true,

      promotion: {
        type: "INTERNAL",
        status: "DISABLED",
        promotion: promotionId,
        company: utils.getSessionItem("Company"),
        groups: [],
        config: {
          buddy: false,
          not_cancel_on_payout: false,
          register_selected: false,
          cms: cmsInit,
          terms: termsInit,
          calendar: { first_day_of_month: false, last_day_of_month: false, week_days: '1111111', init_date: utils.getDateTZTodayObj().getTime(), end_date: utils.getDateTZTodayObj().getTime() },
          name: "",
          internalConfig: { amount: 1000, account: "", percentage: false, calculator: 'NO_CALCULATOR' },
          code_type: 'NO_CODE',
          totalCodes: 0,
          codesPrefix: '',
          codesLength: 10,
          codes: '',
          affiliates: [],
          maxactivationcash: 0

        },
        tags: []
      },
      week: "0000000",
      methods: [],
      methods_availables: [],
      choices: [],
      calculators: [],
      events: [],
      events_availables: [],

      groups_allowed: [],
      groups_availables: [],
      groups_forbbiden: [],

      tags: [],
      promotion_tags: [],


      providerMachines: [],
      machines: [],

      treeProviderMachines: [],
      providerMachinesChecked: [],

      treeInternalMachines: [],
      treeInternalMachinesChecked: [],
      langs: langs,

      internalMachines: [],
    };

    this.refreshFilterReports(promotionId)

    // var filter_p = [{ "field": "promotion", "operator": "=", "value": promotionId }];

    // const from = momentTimeZone(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
    // const to = momentTimeZone(new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));


    // var filter_logs = [
    //   { "field": "promotion", "operator": "=", "value": promotionId },
    //   { "field": "log_date", "type": "time_range", "value": [utils.unformatDate(from), utils.unformatDate(to)] },
    // ];

    // utils.setSessionItem("ReportFilter-promotion-details", JSON.stringify(filter_p));
    // utils.setSessionItem("ReportFilter-promotions_codes", JSON.stringify(filter_p));
    // utils.setSessionItem("ReportFilterView-promotions-codes", JSON.stringify([]));

    // utils.setSessionItem("ReportFilter-promotions_logs", JSON.stringify(filter_logs));
    // utils.setSessionItem("ReportFilterView-promotions_logs", JSON.stringify([{ field: "promotion", visibility: "hidden" }]));
    // utils.setSessionItem("ReportFilter-promotions_codes_logs", JSON.stringify(filter_logs));
    // utils.setSessionItem("ReportFilterView-promotions_codes_logs", JSON.stringify([{ field: "promotion", visibility: "hidden" }]));
    // utils.setSessionItem("ReportFilter-promotions_groups_logs", JSON.stringify(filter_logs));
    // utils.setSessionItem("ReportFilterView-promotions_groups_logs", JSON.stringify([{ field: "promotion", visibility: "hidden" }]));
    // utils.setSessionItem("ReportFilter-promotions_tags_promotions_logs", JSON.stringify(filter_logs));
    // utils.setSessionItem("ReportFilterView-promotions_tags_promotions_logs", JSON.stringify([{ field: "promotion", visibility: "hidden" }]));


  }

  refreshFilterReports(promotionId) {
    var filter_p = [{ "field": "t.promotion", "operator": "=", "value": promotionId }];

    const from = momentTimeZone(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
    const to = momentTimeZone(new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));


    var filter_logs = [
      { "field": "promotion", "operator": "=", "value": promotionId },
      { "field": "log_date", "type": "time_range", "value": [utils.unformatDate(from), utils.unformatDate(to)] },
    ];

    //utils.setSessionItem("ReportFilter-promotion-details", JSON.stringify(filter_p));
    utils.setSessionItem("ReportFilter-promotions_codes", JSON.stringify(filter_p));
    utils.setSessionItem("ReportFilterView-promotions-codes", JSON.stringify([]));

    utils.setSessionItem("ReportFilter-promotions_logs", JSON.stringify(filter_logs));
    utils.setSessionItem("ReportFilterView-promotions_logs", JSON.stringify([{ field: "promotion", visibility: "hidden" }]));
    utils.setSessionItem("ReportFilter-promotions_codes_logs", JSON.stringify(filter_logs));
    utils.setSessionItem("ReportFilterView-promotions_codes_logs", JSON.stringify([{ field: "promotion", visibility: "hidden" }]));
    utils.setSessionItem("ReportFilter-promotions_groups_logs", JSON.stringify(filter_logs));
    utils.setSessionItem("ReportFilterView-promotions_groups_logs", JSON.stringify([{ field: "promotion", visibility: "hidden" }]));
    utils.setSessionItem("ReportFilter-promotions_tags_promotions_logs", JSON.stringify(filter_logs));
    utils.setSessionItem("ReportFilterView-promotions_tags_promotions_logs", JSON.stringify([{ field: "promotion", visibility: "hidden" }]));
  }


  deleteImage(img) {
    var promotion = this.state.promotion;
    var images = promotion.config.images;
    images = images.filter((item) => item !== img)

    promotion.config.images = images;
    this.setState({ promotion: promotion });
  }

  updateEvents(eventsConfigured) {
    this.setState({ events: eventsConfigured })
  }
  updateGroups(allowed, forbidden) {

    this.setState({ groups_allowed: allowed, groups_forbbiden: forbidden })
  }
  updateMethods(methodsConfigured) {
    this.setState({ methods: methodsConfigured })
  }

  componentDidMount() {

    const { t } = this.props
    console.log("componentDidMount this.state.newPromotion=" + this.state.newPromotion);
    this.showLoading()
    if (!this.state.newPromotion) {
      var thisAux = this;


      Promise.all([
        this.adminClient.getPromotionDetails(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company, this.state.promotion.promotion),
        this.adminClient.getPromotionChoices(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company),
        this.adminClient.getPromotionCalculators(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company),
        this.adminClient.getProviders(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company),
        this.adminClient.getMachinesTags(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company),
        this.adminClient.getPromotionsTags(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company),
        this.adminClient.getPromotionCodes(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company, this.state.promotion.promotion)

      ]
      ).then(
        async function (result) {
          var promotion = result[0].data.promotion;
          utils.setSessionItem("current-promotion-id", promotion.promotion)


          thisAux.setCkEditors(promotion);

          var groups_allowed = [];
          var groups_forbbiden = [];
          promotion.groups.forEach(element => {
            //   console.log("element : " + JSON.stringify(element))
            if (element.relationship === 'ALLOWED') {
              groups_allowed.push(element);
            } else {
              groups_forbbiden.push(element);
            }

          });

          var externalBLocked = false;
          if (promotion.type === "EXTERNAL") {
            externalBLocked = promotion.config.externalConfig.created !== undefined && promotion.config.externalConfig.created && !promotion.config.externalConfig.updatable;
          }
          console.log("externalBLocked = " + externalBLocked);

          if (promotion.config.cms === undefined) {
            promotion.config.cms = thisAux.state.promotion.config.cms;
          }
          else {
            if (thisAux.state.langs.length > 1) {
              //multidioma de cms y de terms
              let existeClaveCms = false;
              let cmsParse;
              let clavesCms = Object.keys(promotion.config.cms)
              for (const lang of thisAux.state.langs) {
                if (clavesCms.includes(lang)) {
                  existeClaveCms = lang;
                  break; // Puedes salir del bucle tan pronto como encuentres una coincidencia
                }
              }

              if (!existeClaveCms) {
                cmsParse = thisAux.state.langs.reduce((obj, key) => {
                  obj[key] = JSON.parse(JSON.stringify(promotion.config.cms));
                  return obj;
                }, {});
              }
              else {
                cmsParse = thisAux.state.langs.reduce((obj, key) => {
                  if (promotion.config.cms[key] !== undefined) {
                    obj[key] = promotion.config.cms[key]
                  }
                  else {
                    obj[key] = JSON.parse(JSON.stringify(promotion.config.cms[existeClaveCms]));
                  }
                  return obj;
                }, {});
              }

              promotion.config.cms = cmsParse
            }
          }

          if (promotion.config.terms === undefined) {
            let undefinedTermsInit = thisAux.state.langs.length === 1
              ? "  "
              : thisAux.state.langs.reduce((result, lang) => {
                result[lang] = "  ";
                return result;
              }, {});

            promotion.config.terms = undefinedTermsInit

          } else {
            if (thisAux.state.langs.length > 1) {
              //multidioma de cms y de terms
              let existeClaveTerms = false;
              let termsParse;
              let clavesTerms = Object.keys(promotion.config.terms)

              for (const lang of thisAux.state.langs) {
                if (clavesTerms.includes(lang)) {
                  existeClaveTerms = lang;
                  break; // Puedes salir del bucle tan pronto como encuentres una coincidencia
                }
              }

              if (!existeClaveTerms) {
                termsParse = thisAux.state.langs.reduce((obj, key) => {
                  obj[key] = JSON.parse(JSON.stringify(promotion.config.terms));
                  if (obj[key] == "") {
                    obj[key] = "  "
                  }
                  return obj;
                }, {});
              }
              else {
                termsParse = thisAux.state.langs.reduce((obj, key) => {
                  if (promotion.config.terms[key] !== undefined) {
                    obj[key] = promotion.config.terms[key]
                  }
                  else {
                    obj[key] = JSON.parse(JSON.stringify(promotion.config.terms[existeClaveTerms]));
                  }
                  if (obj[key] == "") {
                    obj[key] = "  "
                  }
                  return obj;
                }, {});
              }

              promotion.config.terms = termsParse
            } else {
              if (promotion.config.terms == "") {
                promotion.config.terms = "  "
              }
            }
          }

          if (promotion.config.internalConfig === undefined) {
            promotion.config.internalConfig = {};
          }
          if (promotion.config.internalConfig.percentage === undefined) {
            promotion.config.internalConfig.percentage = "";
          }
          if (promotion.config.calendar.first_day_of_month === undefined) {
            promotion.config.calendar.first_day_of_month = "";
          }
          if (promotion.config.calendar.week_days === undefined) {
            promotion.config.calendar.week_days = "1111111";
          }
          if (promotion.config.calendar.last_day_of_month === undefined) {
            promotion.config.calendar.last_day_of_month = "";
          }

          if (promotion.config.code_type === undefined) {
            promotion.config.code_type = 'UNIQUE';
          }

          if (promotion.config.internalConfig.currency !== undefined) {
            promotion.config.currency = promotion.config.internalConfig.currency;
            delete promotion.config.internalConfig.currency;
          }

          if (promotion.config.calendar !== undefined) {
            if (promotion.config.calendar.init_date !== undefined) {
              promotion.config.calendar.init_date = utils.formatDate(promotion.config.calendar.init_date);
            }
            if (promotion.config.calendar.end_date !== undefined) {
              promotion.config.calendar.end_date = utils.formatDate(promotion.config.calendar.end_date);
            }
          }


          var methods = promotion.config.methods;
          if (methods === undefined) {
            methods = [];
          }

          var providers = [];
          result[3].data.forEach(element => {
            //     console.log("Element = " + JSON.stringify(element));
            if (element.config === null || element.config === '') element.config = '{}'
            try {
              element.config = JSON.parse(element.config);
              if (element.config.promotions !== undefined) {
                providers.push(element);
              }
            } catch (err) {
              console.log(`Provider:${element.provider} no config`)
            }
          });

          var provider = null;
          if (promotion.config.externalConfig !== undefined) {
            providers.forEach(element => {
              if (element.provider === promotion.config.externalConfig.provider) {
                provider = element;
              }
            });
          }

          var data = { machines: [] };
          var selectedMachines = [];

          // External promotion machines
          if (provider !== null) {
            data = await thisAux.adminClient.getProviderMachines(JSON.parse(utils.getSessionItem("user")).session, thisAux.state.promotion.company, provider.provider);
            var machines = {};
            data.machines.forEach(element => {
              if (element.tags === undefined || element.tags === null || element.tags.indexOf("no-freespins") === -1) {
                machines[element.machine] = element;
              }
            });
            var treeInternalMachinesChecked = [];
            if (promotion.config.externalConfig !== undefined && promotion.config.externalConfig.machines !== undefined) {
              promotion.config.externalConfig.machines.forEach(element => {
                treeInternalMachinesChecked.push(element.machine);
              });
            }

            // thisAux.setState({ providerMachines: machines, treeProviderMachines: treeInternalMachines, providerMachinesChecked: treeInternalMachinesChecked });
            thisAux.setState({ providerMachines: machines, providerMachinesChecked: treeInternalMachinesChecked });

          }

          if (promotion.config.externalConfig !== undefined) {
            selectedMachines = promotion.config.externalConfig.machines;
          }




          // Internal promotion machines
          var internalData = { machines: [] };
          var selectedInternalMachines = [];
          var selectedInternalForbiddenMachines = [];
          var selectedInternalForbiddenTags = [];
          var selectedInternalAllowedTags = [];



          internalData = await thisAux.adminClient.getMachines(JSON.parse(utils.getSessionItem("user")).session, thisAux.state.promotion.company);
          var internalMachines = {};
          internalData.machines.forEach(element => {
            internalMachines[element.machine] = element;
          });

          var treeInternalMachinesChecked = [];
          if (promotion.config.internalConfig !== undefined && (promotion.config.internalConfig.account === "DEPOSIT-CASH" || promotion.config.internalConfig.account === "BETTING-BONUS")) {
            promotion.config.internalConfig.account = "BINGO-BONUS" //Para parsear los antiguos casos
          }
          if (promotion.config.internalConfig !== undefined && promotion.config.internalConfig.machines !== undefined) {
            selectedInternalMachines = promotion.config.internalConfig.machines;

            selectedInternalMachines.forEach(element => {
              treeInternalMachinesChecked.push(element.machine);
            });
          }

          if (promotion.config.internalConfig !== undefined && promotion.config.internalConfig.forbidden_machines !== undefined) {
            selectedInternalForbiddenMachines = promotion.config.internalConfig.forbidden_machines;
          }
          if (promotion.config.internalConfig !== undefined && promotion.config.internalConfig.forbidden_tags !== undefined) {
            selectedInternalForbiddenTags = promotion.config.internalConfig.forbidden_tags;
          }
          if (promotion.config.internalConfig !== undefined && promotion.config.internalConfig.allowed_tags !== undefined) {
            selectedInternalAllowedTags = promotion.config.internalConfig.allowed_tags;
          }


          //     console.log("Week = " + promotion.config.calendar.week_days);
          if (promotion.config.cms.summary_body === undefined) {
            promotion.config.cms.summary_body = '';
          }

          if (promotion.config.cms.body === undefined) {
            promotion.config.cms.body = '';
          }

          if (promotion.config.terms === undefined) {
            promotion.config.terms = "";
          }
          if (promotion.config.email === undefined) {
            promotion.config.email = "";
          }
          //Codigos
          let allowEditCodes = true
          if (promotion.config.code_type === 'UNIQUE' && result[6].data.length > 0) {
            promotion.config.codes = result[6].data[0].code
            if (result[6].data[0].users > 0) {
              allowEditCodes = false
            }
          }
          if (promotion.config.code_type === 'MULTIPLE' || promotion.config.code_type === 'MULTIPLE_AUTO' && result[6].data.length > 0) {
            const codeUsed = result[6].data.some(objeto => objeto.users > 0);
            let codes = result[6].data.map(objeto => objeto.code);
            promotion.config.codes = codes.join(',')
            if (codeUsed) allowEditCodes = false
          }

          let eventsAvailables=result[0].data.events_availables
          let promoEvents=promotion.events
          if(thisAux.state.company.config.backoffice?.promotions?.eventList!==undefined && thisAux.state.company.config.backoffice?.promotions?.eventList.length>0){
            eventsAvailables=result[0].data.events_availables.filter((event) =>(thisAux.state.company.config.backoffice.promotions.eventList.includes(event.event_id)))
          }
          if(thisAux.state.company.config.backoffice?.promotions?.eventList!==undefined && thisAux.state.company.config.backoffice?.promotions?.eventList.length>0){
            promoEvents=promotion.events.filter((event) =>(thisAux.state.company.config.backoffice.promotions.eventList.includes(event.event_id)))
          }

          thisAux.setState({

            externalBLocked: externalBLocked,

            //treeInternalMachines: treeInternalMachines,

            internalMachines: internalData.machines,
            selectedInternalMachines: selectedInternalMachines,
            treeInternalMachinesChecked: treeInternalMachinesChecked,
            selectedInternalForbiddenMachines: selectedInternalForbiddenMachines,
            selectedInternalForbiddenTags: selectedInternalForbiddenTags,
            selectedInternalAllowedTags: selectedInternalAllowedTags,

            machines: data.machines,
            selectedMachines: selectedMachines,
            provider: provider,
            editCodes: allowEditCodes,

            promotion: promotion,
            choices: result[1].choices,
            calculators: result[2].calculators,
            methods: methods,
            week: promotion.config.calendar.week_days,
            // events: promotion.events,
            events: promoEvents,
            events_availables: eventsAvailables,
            // events_availables: result[0].data.events_availables,
            methods_availables: result[0].data.methods_availables,
            groups_allowed: groups_allowed,
            groups_forbbiden: groups_forbbiden,
            groups_availables: result[0].data.groups_availables,
            providers: providers,
            tags: result[4].tags,
            promotion_tags: result[5].tags
          });

          thisAux.closeLoading()
        },
        function (err) {
          thisAux.closeLoading()
          this.showPopup(t("Error"),
          <>
            <p>{t("Cannot get the promotion")}</p>
            <button type="button"                  
              className="btn btn-primary"
              onClick={(evt) => {
                
                this.closePopup()
                
                
              }}
            >
              {t("Ok")}
            </button>
          </>
        );
          //thisAux.showAlert(t("Error"), t("Cannot get the promotion"))
        }

      );
    } else {



      Promise.all([
        this.adminClient.getNewPromotionDetails(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company, this.state.promotion.promotion),
        this.adminClient.getPromotionChoices(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company),
        this.adminClient.getProviders(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company)
      ]
      ).then(
        function (result) {

          var providers = [];
          result[2].data.forEach(element => {
            //     console.log("Element = " + JSON.stringify(element));
            element.config = JSON.parse(element.config);
            if (element.config.promotions !== undefined) {
              providers.push(element);
            }
          });

          let newTermsInit = this.state.langs.length === 1
            ? "  "
            : this.state.langs.reduce((result, lang) => {
              result[lang] = "  ";
              return result;
            }, {});

          this.state.promotion.config.terms = newTermsInit;
          let eventsAvailables=result[0].data.events_availables
          if(this.state.company.config.backoffice?.promotions?.eventList!==undefined && this.state.company.config.backoffice?.promotions?.eventList.length>0){
            eventsAvailables=result[0].data.events_availables.filter((event) =>(this.state.company.config.backoffice.promotions.eventList.includes(event.event_id)))
          }
          this.closeLoading()
          this.setState({
            promotion: this.state.promotion,
            choices: result[1].choices,
            // events_availables: result[0].data.events_availables,
            events_availables: eventsAvailables,
            methods_availables: result[0].data.methods_availables,
            groups_availables: result[0].data.groups_availables,
            providers: providers,
          });
        }.bind(this),
        function (err) {
          console.error(err);
        }

      );
    }
  }


  setCkEditors(promotion) {

    if (promotion.config.cms !== undefined &&
      this.state.emailCKEditor !== undefined &&
      this.state.termsCKEditor !== undefined
    ) {
      console.log("Setting ckeditors contents...");
      console.log("Setting ckeditors email : " + promotion.config.email);
      this.state.emailCKEditor.setData(promotion.config.email);
      console.log("Setting ckeditors terms : " + promotion.config.terms);
      this.state.termsCKEditor.setData(promotion.config.terms);
    } else {
      setTimeout(function () { this.setCkEditors(promotion); }.bind(this), 1000);
    }
  }


  handleDuplicatePromotion(evt) {
    this.setState({ duplicatePromotion: evt.target.value });
  }

  handleDuplicateName(evt) {
    this.setState({ duplicateName: evt.target.value });
  }

  handleDuplicate() {
    const { t } = this.props;
    let messageCodes = ''
    if (this.state.promotion.config.code_type !== 'NO_CODE') {
      messageCodes = t('Duplicated codes')
    }

    this.showPopup(t("Duplicate promotion"),
      <>
        {messageCodes != '' && <div className='content'><strong>{messageCodes}</strong></div>}
        <TextField id="new_promotion" key="new_promotion" label={t("New Identifier")} defaultValue={this.state.duplicatePromotion} onChange={this.handleDuplicatePromotion} />
        <TextField id="new_promotion_name" key="new_promotion_name" label={t("New Name")} defaultValue={this.state.duplicateName} onChange={this.handleDuplicateName} />
        <button type="button" className="btn btn-primary" onClick={this.submitDuplicate}>{t("Duplicate")}</button>
        <button type="button" className="btn btn-secondary" onClick={this.closePopup}>{t("Cancel")}</button>
      </>
    );
    window.scrollTo(0, 0);
  }

  handleDelete() {
    const { t } = this.props;
    var promotion = this.state.promotion;
    var promotionId = promotion.promotion;


    //Comprobamos si la promoción esta asociada a algun jugador.
    this.adminClient.isPromoInUse(JSON.parse(utils.getSessionItem("user")).session,this.state.promotion.company,promotionId).then((result) => { 
      if(result.data>0){
        this.showPopup(t("Delete Promotion"),
          <>
            <p>{t("Promo in Use")}</p>
            <button type="button" className="btn btn-primary" onClick={this.closePopup}>{t("Close")}</button>
          </>
        );
      }else{
        this.showPopup(t("Delete Promotion"),
          <>
            <p>{t("TEXT_DELETED_PROMOTION")}</p>
            <button type="button" className="btn btn-danger" onClick={this.submitDelete}>{t("Delete")}</button>
            <button type="button" className="btn btn-secondary" onClick={this.closePopup}>{t("Cancel")}</button>
          </>
        );
        window.scrollTo(0, 0);
      }
     })

    
  }

  submitDelete() {
    var promotion = this.state.promotion;
    var promotionId = promotion.promotion;
    const { t } = this.props;
    var promise = this.adminClient.deletePromotion(JSON.parse(utils.getSessionItem("user")).session, promotionId, this.state.promotion.company);
    let thisAux = this;
    promise.then(
      function (msg) {
        thisAux.goBack();
      }
      , function (err) {
        thisAux.showAlert(t("Promotion deleted error"), <p>{t("Promotion deleted error explanation") + " : " + err.description}</p>);
        thisAux.closeAlert();
      });

  }

  // async submitDuplicate() {

  //   var promotion = this.state.promotion;
  //   var error = false;
  //   const { t } = this.props;
  //   var errorContent = <></>;

  //   if (this.state.duplicatePromotion === undefined || this.state.duplicatePromotion.trim().length === 0) {
  //     error = true;
  //     errorContent = <>{errorContent}<p>{t("Duplicate promotion ID can not be empty")}</p></>
  //   } else {

  //     // comprobamos que el id de promocion no este dupolicado
  //     var response = await this.adminClient.promotionExists(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.duplicatePromotion);

  //     if (response.exists === 1) {
  //       error = true;
  //       errorContent = <>{errorContent}<p>{t("Promotion ID exists")}</p></>
  //     }
  //   }

  //   if (this.state.duplicateName === undefined || this.state.duplicateName.trim().length === 0) {
  //     error = true;
  //     errorContent = <>{errorContent}<p>{t("Duplicate name can not be empty")}</p></>
  //   }

  //   if (error) {

  //     this.setState({ showModal: true, errorTitle: t("Error"), errorContent: errorContent })
  //   } else {

  //     this.setState({ showModal: false });

  //     var thisAux = this;

  //     var promise = this.adminClient.duplicatePromotion(JSON.parse(utils.getSessionItem("user")).session, promotion.company, promotion.promotion, this.state.duplicatePromotion.trim(), this.state.duplicateName);
  //     promise.then(
  //       function (msg) {
  //         if (msg.result === 'OK') {
  //           thisAux.closePopup();
  //           thisAux.setState({ showModal: true, errorTitle: t("Promotion duplicated"), errorContent: <p>{t("Promotion duplicated ok")}</p> });

  //         } else {
  //           thisAux.setState({ showModal: true, errorTitle: t("Promotion duplicated error"), errorContent: <p>{t("Promotion duplicated error explanation") + " : " + msg.description}</p> });
  //         }
  //       }, function (err) {
  //         thisAux.setState({ showModal: true, errorTitle: t("Promotion duplicated error"), errorContent: <p>{t("Promotion duplicated error explanation") + " : " + err.description}</p> });
  //       });
  //   }
  // }

  async submitDuplicate() {
    var response = await this.adminClient.promotionExists(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.duplicatePromotion.trim());
    const { t } = this.props;
    if (response.exists === 1) {
      this.closePopup();
      this.props.showAlert(t("Duplicate promotion"),
        <>
          <p>{t("Promotion ID exists")}</p>
        </>);
    }
    else {
      let myState = this.state;
      myState.promotionDeleted = false;
      myState.promotion.promotion = this.state.duplicatePromotion;
      myState.promotion.config.name = this.state.duplicateName === undefined ? "" : this.state.duplicateName;
      myState.promotion.config.codes = ''
      myState.editCodes = true

      //this.refreshFilterReports(myState.promotion.promotion)

      var filter_p = [{ "field": "promotion", "operator": "=", "value": myState.promotion.promotion }];

      const from = momentTimeZone(new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));
      const to = momentTimeZone(new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000)).tz(utils.getCurrentCompany().config.timezone).format(("YYYY-MM-DD HH:mm:ss"));


      var filter_logs = [
        { "field": "promotion", "operator": "=", "value": myState.promotion.promotion },
        { "field": "log_date", "type": "time_range", "value": [utils.unformatDate(from), utils.unformatDate(to)] },
      ];

      // let filter_logs = utils.getSessionItem('ReportFilter-promotions_logs')
      // let filter = utils.getSessionItem('ReportFilter-promotion-details')
      window['windowRefPromotionCodes'].executeReport(filter_p);
      window['windowRefPromotionLogs'].executeReport(filter_logs);
      window['windowRefPromotionCodesLogs'].executeReport(filter_logs);
      window['windowRefPromotionGroupsLogs'].executeReport(filter_logs);
      window['windowRefPromotionPromotionsLogs'].executeReport(filter_logs);

      this.setState(myState)
      this.closePopup();
    }
  }


  handleCloseModal() {
    this.setState({ showModal: false });
  }


  onTreeInternalMachinesChecked(checked) {
    this.setState({ treeInternalMachinesChecked: checked });
  }

  onTreeProviderMachinesChecked(checked) {
    this.setState({ providerMachinesChecked: checked });
  }

  showLoading() {
    const { t } = this.props;
    var thisAux = this;
    if (thisAux.props.showAlert) thisAux.props.showAlert("LOADING", t("Loading"), false);
  }
  closeLoading() {
    const { t } = this.props;
    var thisAux = this;
    if (thisAux.props.handleCloseAlert) thisAux.props.handleCloseAlert()
  }

  savePromotions(){
    const { t } = this.props;

      var promotion = JSON.parse(JSON.stringify(this.state.promotion));


      console.log("promotion.tags = " + JSON.stringify(promotion.tags));
      promotion.config.calendar.init_date = utils.unformatDate(new Date(promotion.config.calendar.init_date));
      promotion.config.calendar.end_date = utils.unformatDate(new Date(promotion.config.calendar.end_date));


      console.log("Promotion to be save: " + JSON.stringify(promotion));

      this.setState({ errorMessage: "" });

      var thisAux = this;
      var allowed_groups = [];
      var forbidden_groups = [];

      this.state.groups_allowed.forEach(element => {
        allowed_groups.push(element.group);
      });
      this.state.groups_forbbiden.forEach(element => {
        forbidden_groups.push(element.group);
      });

      promotion.forbidden_groups = forbidden_groups.join();
      promotion.allowed_groups = allowed_groups.join();
      promotion.config.methods = this.state.methods;

      promotion.events = this.state.events;
      console.log("calimaco: MarketingPromotionDetails: handleSubmit: " + JSON.stringify(promotion.config.internalConfig));


      if (promotion.config.internalConfig === undefined) {
        promotion.config.internalConfig = {};
      }
      promotion.config.internalConfig.machines = [];
      this.state.treeInternalMachinesChecked.forEach(machine => {
        console.log("calimaco: MarketingPromotionDetails: internal machine " + machine);

        for (var i in this.state.internalMachines) {
          if (this.state.internalMachines[i].machine == machine) {
            promotion.config.internalConfig.machines.push(this.state.internalMachines[i]);
            break;
          }
        }
      });

      if (promotion.config.externalConfig !== undefined) {
        console.log("calimaco: MarketingPromotionDetails: provider this.state.providerMachines " + JSON.stringify(this.state.providerMachines));

        promotion.config.externalConfig.machines = [];
        this.state.providerMachinesChecked.forEach(machine => {
          console.log("calimaco: MarketingPromotionDetails: provider machine checked " + machine);

          for (var i in this.state.providerMachines) {
            console.log("calimaco: MarketingPromotionDetails: provider machine " + this.state.providerMachines[i].machine);

            if (this.state.providerMachines[i].machine == machine) {
              promotion.config.externalConfig.machines.push(this.state.providerMachines[i]);
              break;
            }
          }
        });
      }
      var data = JSON.stringify(promotion);
      console.log("promotion to be saved: " + data);
      //this.isLoaging = true;
      //this.showLoading()
      var promise = this.adminClient.savePromotion(JSON.parse(utils.getSessionItem("user")).session, data);
      promise.then(
        function (msg) {
          console.log("Update result " + JSON.stringify(msg));
          thisAux.closeLoading()
          thisAux.isLoaging = false;
          if (msg.result === 'OK') {
            thisAux.props.showAlert(t("Promotion saved"), <p>{t("Promotion saved ok")}</p>, [
              <button type="button" className="btn btn-primary"
                onClick={(evt) => {
                  //if (thisAux.state.newPromotion) {
                  window.location.reload()
                  //}
                  thisAux.props.handleCloseAlert();
                }}>{t("Ok")}</button>

            ]);
            window['windowRefPromotionCodes'].executeReport(0);
            window['windowRefPromotionLogs'].executeReport(0);
            window['windowRefPromotionCodesLogs'].executeReport(0);
            window['windowRefPromotionGroupsLogs'].executeReport(0);
            window['windowRefPromotionPromotionsLogs'].executeReport(0);

            utils.setSessionItem("current-promotion-id", JSON.parse(data).promotion);
          } else {
            thisAux.showAlert(t("Promotion saved error"), <p>{t("Promotion saved error explanation") + " : " + msg.description}</p>)
          }
        }, function (err) {
          thisAux.closeLoading()
          thisAux.isLoaging = false;
          thisAux.showAlert(t("Promotion saved error"), <p>{t("Promotion saved error explanation") + " : " + err.description}</p>);

        });
  }

  async handleSubmit() {

    const { t } = this.props;
    var error = false
    var errorContent;
    
    if (this.state.newPromotion) {
      var response = await this.adminClient.promotionExists(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.promotion.promotion.trim());

      if (response.exists === 1) {
        error = true;
        errorContent = <>{errorContent}<p>{t("Promotion ID exists")}</p></>
      }
    }
    if (this.state.promotion.promotion === undefined || this.state.promotion.promotion.trim().length === 0) {
      error = true;
      errorContent = <>{errorContent}<p>{t("Promotion ID is required")}</p></>;
    }
    if (this.state.promotion.config.name === undefined || this.state.promotion.config.name === null || this.state.promotion.config.name.trim().length === 0) {
      error = true;
      errorContent = <>{errorContent}<p>{t("Name is required")}</p></>
    }

    if (this.state.promotion.config.calendar.init_date === undefined || this.state.promotion.config.calendar.init_date === null) {
      error = true;
      errorContent = <>{errorContent}<p>{t("Init date is required")}</p></>
    }

    if (this.state.promotion.config.calendar.end_date === undefined || this.state.promotion.config.calendar.end_date === null) {
      error = true;
      errorContent = <>{errorContent}<p>{t("End date is required")}</p></>
    }
    if (this.state.groups_allowed === undefined || this.state.groups_allowed === null || this.state.groups_allowed.length === 0) {
      error = true;
      errorContent = <>{errorContent}<p>{t("At least one allowed group is required")}</p></>
    }

    if (this.state.promotion.config.code_type !== 'NO_CODE' && (this.state.promotion.config.max_users === undefined || this.state.promotion.config.max_users === '')) {
      error = true;
      errorContent = <>{errorContent}<p>{t("Required") + " " + t("Max users")}</p></>
    }
    if ((this.state.promotion.config.code_type === "UNIQUE" || this.state.promotion.config.code_type === "MULTIPLE" || this.state.promotion.config.code_type === "MULTIPLE_AUTO") && (this.state.promotion.config.codes.trim() === '' || this.state.promotion.config.codes === undefined)) {
      error = true;
      errorContent = <>{errorContent}<p>{t("Required") + " " + t("Codes")}</p></>
    }
    if ((this.state.promotion.config.code_type === "MULTIPLE") && (this.state.promotion.config.codes !== '' && this.state.promotion.config.codes !== undefined)) {

      let codesVal = this.state.promotion.config.codes.split(',')
      let valuesWhite = codesVal.filter(item => item === undefined || item.trim() === '')
      if (valuesWhite !== undefined && valuesWhite.length > 0) {
        error = true;
        errorContent = <>{errorContent}<p>{t("white codes")}</p></>
      }
    }
    if ((this.state.promotion.config.code_type === "MULTIPLE_AUTO") && (this.state.promotion.config.codes !== '' && this.state.promotion.config.codes !== undefined)) {
      let codesVal = this.state.promotion.config.codes.split(',')
      let valuesWhite = codesVal.filter(item => item === undefined || item.trim() === '')
      if (valuesWhite !== undefined && valuesWhite.length > 0) {
        error = true;
        errorContent = <>{errorContent}<p>{t("white codes")}</p></>
      }
    }


    if (error) {

      this.showPopup(t('Error'),
      <>
        <p>{errorContent}</p>
        <button type="button"                  
          className="btn btn-primary"
          onClick={(evt) => {
            this.isLoaging=false
            this.closeLoading()
            this.closePopup()            
          }}
        >
          {t("Ok")}
        </button>
      </>
    );
      // this.props.showAlert(
      //   t('Error'),
      //   <p>{errorContent}</p>,
      // )

    } else {

      this.checkGroupsMembers()


      // var promotion = JSON.parse(JSON.stringify(this.state.promotion));



      // console.log("promotion.tags = " + JSON.stringify(promotion.tags));
      // promotion.config.calendar.init_date = utils.unformatDate(new Date(promotion.config.calendar.init_date));
      // promotion.config.calendar.end_date = utils.unformatDate(new Date(promotion.config.calendar.end_date));


      // console.log("Promotion to be save: " + JSON.stringify(promotion));

      // this.setState({ errorMessage: "" });

      // var thisAux = this;
      // var allowed_groups = [];
      // var forbidden_groups = [];

      // this.state.groups_allowed.forEach(element => {
      //   allowed_groups.push(element.group);
      // });
      // this.state.groups_forbbiden.forEach(element => {
      //   forbidden_groups.push(element.group);
      // });

      // promotion.forbidden_groups = forbidden_groups.join();
      // promotion.allowed_groups = allowed_groups.join();
      // promotion.config.methods = this.state.methods;

      // promotion.events = this.state.events;
      // console.log("calimaco: MarketingPromotionDetails: handleSubmit: " + JSON.stringify(promotion.config.internalConfig));


      // if (promotion.config.internalConfig === undefined) {
      //   promotion.config.internalConfig = {};
      // }
      // promotion.config.internalConfig.machines = [];
      // this.state.treeInternalMachinesChecked.forEach(machine => {
      //   console.log("calimaco: MarketingPromotionDetails: internal machine " + machine);

      //   for (var i in this.state.internalMachines) {
      //     if (this.state.internalMachines[i].machine == machine) {
      //       promotion.config.internalConfig.machines.push(this.state.internalMachines[i]);
      //       break;
      //     }
      //   }
      // });

      // if (promotion.config.externalConfig !== undefined) {
      //   console.log("calimaco: MarketingPromotionDetails: provider this.state.providerMachines " + JSON.stringify(this.state.providerMachines));

      //   promotion.config.externalConfig.machines = [];
      //   this.state.providerMachinesChecked.forEach(machine => {
      //     console.log("calimaco: MarketingPromotionDetails: provider machine checked " + machine);

      //     for (var i in this.state.providerMachines) {
      //       console.log("calimaco: MarketingPromotionDetails: provider machine " + this.state.providerMachines[i].machine);

      //       if (this.state.providerMachines[i].machine == machine) {
      //         promotion.config.externalConfig.machines.push(this.state.providerMachines[i]);
      //         break;
      //       }
      //     }
      //   });
      // }
      // var data = JSON.stringify(promotion);
      // console.log("promotion to be saved: " + data);
      // this.isLoaging = true;
      // this.showLoading()
      // var promise = this.adminClient.savePromotion(JSON.parse(utils.getSessionItem("user")).session, data);
      // promise.then(
      //   function (msg) {
      //     console.log("Update result " + JSON.stringify(msg));
      //     thisAux.closeLoading()
      //     thisAux.isLoaging = false;
      //     if (msg.result === 'OK') {
      //       thisAux.props.showAlert(t("Promotion saved"), <p>{t("Promotion saved ok")}</p>, [
      //         <button type="button" className="btn btn-primary"
      //           onClick={(evt) => {
      //             //if (thisAux.state.newPromotion) {
      //             window.location.reload()
      //             //}
      //             thisAux.props.handleCloseAlert();
      //           }}>{t("Ok")}</button>

      //       ]);
      //       window['windowRefPromotionCodes'].executeReport(0);
      //       window['windowRefPromotionLogs'].executeReport(0);
      //       window['windowRefPromotionCodesLogs'].executeReport(0);
      //       window['windowRefPromotionGroupsLogs'].executeReport(0);
      //       window['windowRefPromotionPromotionsLogs'].executeReport(0);

      //       utils.setSessionItem("current-promotion-id", JSON.parse(data).promotion);
      //     } else {
      //       thisAux.showAlert(t("Promotion saved error"), <p>{t("Promotion saved error explanation") + " : " + msg.description}</p>)
      //     }
      //   }, function (err) {
      //     thisAux.closeLoading()
      //     thisAux.isLoaging = false;
      //     thisAux.showAlert(t("Promotion saved error"), <p>{t("Promotion saved error explanation") + " : " + err.description}</p>);

      //   });
    }

  }


  saveDataCMS(urls, lang) {
    let promotion = this.state.promotion;
    if (lang === undefined) {

      if (urls.length === 0) promotion.config.cms.image = "";
      else promotion.config.cms.image = urls[0];
    }
    else {
      if (urls.length === 0) promotion.config.cms[lang].image = "";
      else promotion.config.cms[lang].image = urls[0];
    }
    this.setState({ promotion: promotion, cms_image: urls[0] });
  }

  saveDataCMSSumary(urls, lang) {
    let promotion = this.state.promotion;
    if (lang === undefined) {

      if (urls.length === 0) promotion.config.cms.summary_image = "";
      else promotion.config.cms.summary_image = urls[0];
    }
    else {
      if (urls.length === 0) promotion.config.cms[lang].summary_image = "";
      else promotion.config.cms[lang].summary_image = urls[0];
    }
    this.setState({ promotion: promotion, summary_image: urls[0] });

  }

  saveDataPromotion(urls) {
    let promotion = this.state.promotion;
    if (promotion.config.images === undefined || promotion.config.images === null) {
      promotion.config.images = [];
    }
    else {
      promotion.config.images = urls;
    }
    this.setState({ promotion: promotion });
  }


  onChangeHandlerConfigCMS(e) {
    const { name, value, lang } = e.target || e;
    let promotion = this.state.promotion;

    if (lang === undefined) {
      promotion.config.cms[name] = value;
      this.setState({ promotion: promotion });
    }
    else {
      promotion.config.cms[lang][name] = value;
      this.setState({ promotion: promotion });
    }
  }

  onChangeHandlerConfigCMS2(value) {
    let promotion = this.state.promotion;
    promotion.config.cms = value;
    this.setState({ promotion: promotion });

  }

  handleUpdateStatus(evt) {

    var promotion = this.state.promotion;
    promotion.status = evt.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateName(evt) {
    var promotion = this.state.promotion;
    promotion.config.name = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }

  async handleVerifyPromotion(evt) {
    if (this.state.newPromotion) {

      var thisAux = this;
      const { t } = thisAux.props;
      var p = this.adminClient.promotionExists(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), this.state.promotion.promotion);
      p.then(function (data) {
        if (data.exists === 1) {

          thisAux.showAlert(t("Error"), <p>{t("Promotion ID exists")}</p>);

        }
      }, function (err) {

      });

    }
  }
  handleUpdatePromotion(evt) {
    var promotion = this.state.promotion;
    promotion.promotion = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateFirstDateOfMonth(evt) {
    var promotion = this.state.promotion;
    promotion.config.calendar.first_day_of_month = evt.currentTarget.checked;
    this.setState({ promotion: promotion });
  }
  handleUpdateLastDateOfMonth(evt) {
    var promotion = this.state.promotion;
    promotion.config.calendar.last_day_of_month = evt.currentTarget.checked;
    this.setState({ promotion: promotion });
  }

  setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  }

  handleUpdateWeekDays(day, evt) {
    var promotion = this.state.promotion;
    console.log("Checked = " + evt.currentTarget.checked);
    promotion.config.calendar.week_days = this.setCharAt(promotion.config.calendar.week_days, day, +evt.currentTarget.checked);
    this.setState({ promotion: promotion });
  }

  handleUpdateAllWeekDays(day, evt) {
    var promotion = this.state.promotion;
    if (promotion.config.calendar.week_days === '1111111') {
      promotion.config.calendar.week_days = '0000000';
    } else {
      promotion.config.calendar.week_days = '1111111';
    }
    this.setState({ promotion: promotion });
  }

  handleUpdateExternalPromotionId(evt) {
    var promotion = this.state.promotion;
    promotion.config.externalConfig.external_promotion_id = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }


  handleUpdateExternalPromotionType(evt) {
    var promotion = this.state.promotion;
    promotion.config.externalConfig.external_type_id = evt.value;

    // if (evt.currentTarget !== null) {
    //   promotion.config.externalConfig.external_type_id = evt.currentTarget.value;
    // } else {
    //   promotion.config.externalConfig.external_type_id = evt.target.value;
    // }
    this.setState({ promotion: promotion });
  }

  handleUpdateExternalMachines(evt, values) {
    console.log("Machines = " + JSON.stringify(values));
    var promotion = this.state.promotion;
    promotion.config.externalConfig.machines = values;
    this.setState({ promotion: promotion, selectedMachines: values });
  }

  handleUpdateInternalMachines(evt, values) {
    console.log("Machines = " + JSON.stringify(values));
    var promotion = this.state.promotion;
    promotion.config.internalConfig.machines = values;

    this.setState({ promotion: promotion, selectedInternalMachines: values });
  }

  handleUpdateInternalForbiddenMachines(evt, values) {
    console.log("Machines = " + JSON.stringify(values));
    var promotion = this.state.promotion;
    promotion.config.internalConfig.forbidden_machines = values;

    this.setState({ promotion: promotion, selectedInternalForbiddenMachines: values });
  }

  handleUpdateInternalForbiddenTags(evt, values) {
    console.log("Forbidden Tags = " + JSON.stringify(values));
    var promotion = this.state.promotion;
    promotion.config.internalConfig.forbidden_tags = values;

    this.setState({ promotion: promotion, selectedInternalForbiddenTags: values });
  }


  handleUpdateInternaAllowedTags(evt, values) {
    console.log("Allowed Tags = " + JSON.stringify(values));
    var promotion = this.state.promotion;
    promotion.config.internalConfig.allowed_tags = values;

    this.setState({ promotion: promotion, selectedInternalAllowedTags: values });
  }


  handleUpdateExternalMachineId(evt) {
    var promotion = this.state.promotion;
    promotion.config.externalConfig.external_machine_id = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }


  handleUpdateExternalDefinition(field, evt) {
    var promotion = this.state.promotion;
    var definition = {};
    this.state.provider.config.promotions.definition.forEach(element => {
      if (element.name === field) {
        definition = element;
      }
    });
    if (definition.type === "currency") {
      if (evt.target.value.trim() === '') delete promotion.config.externalConfig[field]
      else promotion.config.externalConfig[field] = evt.currentTarget.value * 100;
    } else {
      promotion.config.externalConfig[field] = evt.currentTarget.value;
    }
    this.setState({ promotion: promotion });

  }
  handleUpdateExternalRoundsNumber(evt) {
    var promotion = this.state.promotion;
    promotion.config.externalConfig.rounds_number = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateExternalRoundAmount(evt) {
    var promotion = this.state.promotion;
    promotion.config.externalConfig.round_amount = evt.currentTarget.value * 100;
    this.setState({ promotion: promotion });
  }

  async handleUpdateProvider(evt) {
    const { t } = this.props;

    var promotion = this.state.promotion;
    promotion.config.externalConfig = {}
    promotion.config.externalConfig.provider = evt.value;
    // if (evt.currentTarget !== null) {
    //   promotion.config.externalConfig.provider = evt.currentTarget.value;
    // } else {
    //   promotion.config.externalConfig.provider = evt.target.value;
    // }
    var provider = null;
    this.state.providers.forEach(element => {
      if (element.provider === promotion.config.externalConfig.provider) {
        provider = element;

      }
    });
    if (provider === null) {
      this.props.showAlert(t("Error"), <p>{t("This provider dont have promotion config")}</p>);
    }
    else {


      var data = await this.adminClient.getProviderMachines(JSON.parse(utils.getSessionItem("user")).session, this.state.promotion.company, provider.provider);


      var machines = {};
      data.machines.forEach(element => {
        console.log("updateprovider: element.name = " + element.name + " element.machine = " + element.machine + " element.tags = " + element.tags);
        if (element.tags === undefined || element.tags === null || element.tags.indexOf("no-freespins") === -1) {
          console.log("Added");
          machines[element.machine] = element;
        }
      });

      // this.setState({ machines: machines, provider: provider, promotion: promotion });

      var treeInternalMachinesChecked = [];
      // if (promotion.config.externalConfig !== undefined && promotion.config.externalConfig.machines !== undefined) {
      //   promotion.config.externalConfig.machines.forEach(element => {
      //     treeInternalMachinesChecked.push(element.machine);
      //   });
      // }

      this.setState({ machines: machines, provider: provider, promotion: promotion, providerMachines: data.machines, providerMachinesChecked: treeInternalMachinesChecked });

    }
  }

  handleUpdateVirtualGood(evt) {
    var promotion = this.state.promotion;
    promotion.config.internalConfig.virtual_good = evt.value === "" ? -1 : Number(evt.value);
    this.setState({ promotion: promotion });
  }

  handleUpdateAmount(evt) {
    var promotion = this.state.promotion;
    if (evt.target.value.trim() === '') delete promotion.config.internalConfig.amount
    else {
      promotion.config.internalConfig.amount = utils.parseNumber("" + evt.target.value) * 100;
      console.log("Amount = " + promotion.config.internalConfig.amount);
    }
    this.setState({ promotion: promotion });
  }

  handleUpdatePercentageAmount(evt) {
    var promotion = this.state.promotion;
    promotion.config.internalConfig.amount = utils.parseNumber("" + evt.target.value);
    console.log("Amount = " + promotion.config.internalConfig.amount);
    this.setState({ promotion: promotion });
  }
  handleUpdateCurrency(evt) {
    var promotion = this.state.promotion;
    if (evt.value === "") {
      delete promotion.config.currency
    }
    else {
      promotion.config.currency = evt.value;
    }
    this.setState({ promotion: promotion });
  }

  handleUpdateAffiliates(evt) {
    var promotion = this.state.promotion;
    promotion.config.affiliates = evt.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateAccount(evt) {
    var promotion = this.state.promotion;
    if (promotion !== undefined && promotion.config !== undefined && promotion.config.internalConfig !== undefined) {
      promotion.config.internalConfig.account = evt.value;
      this.setState({ promotion: promotion });
    }
  }
  handleUpdateChoice(evt) {
    var promotion = this.state.promotion;
    promotion.config.choice = evt.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateCalculator(evt) {
    var promotion = this.state.promotion;
    if (evt.objSelection !== undefined) {
      promotion.config.internalConfig.calculator = evt.objSelection.calculator;
      promotion.config.internalConfig.batch_promotion_amount = evt.objSelection.config;
    } else {
      promotion.config.internalConfig.calculator = evt.value;
      promotion.config.internalConfig.batch_promotion_amount = "";
    }
    // this.state.calculators.forEach(calculator => {
    //   if (calculator.calculator === promotion.config.internalConfig.calculator) {
    //     promotion.config.internalConfig.batch_promotion_amount = calculator.config;
    //   }
    // });
    this.setState({ promotion: promotion });
  }


  handleUpdateRegisterSelected(evt) {
    var promotion = this.state.promotion;
    promotion.config.register_selected = evt.target.checked;
    console.log("register_selected = " + evt.target.checked);
    this.setState({ promotion: promotion });
  }

  handleUpdateBuddy(evt) {
    var promotion = this.state.promotion;
    promotion.config.buddy = evt.target.checked;
    console.log("buddy = " + evt.target.checked);
    this.setState({ promotion: promotion });
  }
  handleUpdateNotCancel(evt) {
    var promotion = this.state.promotion;
    promotion.config.not_cancel_on_payout = evt.target.checked;
    console.log("not cancel on payout = " + evt.target.checked);
    this.setState({ promotion: promotion });
  }


  handleUpdatePercentage(evt) {
    var promotion = this.state.promotion;
    promotion.config.internalConfig.percentage = evt.currentTarget.checked;
    if (promotion.config.internalConfig.percentage) {
      promotion.config.internalConfig.amount = promotion.config.internalConfig.amount / 100;
    } else {
      promotion.config.internalConfig.amount = promotion.config.internalConfig.amount * 100;
    }
    this.setState({ promotion: promotion });
  }
  handleUpdateMinAwardedAmount(evt) {
    var promotion = this.state.promotion;
    if (evt.target.value.trim() === '') delete promotion.config.internalConfig.min_awarded_amount
    else promotion.config.internalConfig.min_awarded_amount = evt.currentTarget.value * 100;
    this.setState({ promotion: promotion });
  }
  handleUpdateMaxAwardedAmount(evt) {
    var promotion = this.state.promotion;
    if (evt.target.value.trim() === '') delete promotion.config.internalConfig.max_awarded_amount
    else promotion.config.internalConfig.max_awarded_amount = evt.currentTarget.value * 100;
    this.setState({ promotion: promotion });
  }
  handleUpdateMinAmount(evt) {
    var promotion = this.state.promotion;
    if (evt.target.value.trim() === '') delete promotion.config.min_amount
    else promotion.config.min_amount = evt.currentTarget.value * 100;
    this.setState({ promotion: promotion });
  }
  handleUpdateMaxAmount(evt) {
    var promotion = this.state.promotion;
    if (evt.target.value.trim() === '') delete promotion.config.max_amount
    else promotion.config.max_amount = evt.currentTarget.value * 100;
    this.setState({ promotion: promotion });
  }
  handleUpdateMaxActivationCash(evt) {
    var promotion = this.state.promotion;
    promotion.config.maxactivationcash = evt.currentTarget.value * 100;
    this.setState({ promotion: promotion });
  }
  handleUpdateWR(evt) {
    var promotion = this.state.promotion;
    promotion.config.internalConfig.wr = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }
  handleUpdateMaxInAccount(evt) {
    var promotion = this.state.promotion;
    promotion.config.internalConfig.maxInAccount = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateBatchPromotionAmount(evt) {
    var promotion = this.state.promotion;
    promotion.config.internalConfig.batch_promotion_amount = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateCodeType(evt) {

    var promotion = this.state.promotion;
    promotion.config.code_type = evt.value;
    promotion.config.codes = '';
    if (promotion.config.code_type !== 'MULTIPLE_AUTO') {
      delete promotion.config.codesPrefix
      delete promotion.config.codesLength
      delete promotion.config.totalCodes
    }
    this.setState({ promotion: promotion });
  }


  handleUpdateSummaryTitle(evt) {
    var promotion = this.state.promotion;
    promotion.config.cms.summary_title = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }
  handleUpdateSummaryImage(evt) {
    var promotion = this.state.promotion;
    promotion.config.cms.summary_image = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }



  handleUpdateEmail(evt) {
    var promotion = this.state.promotion;
    promotion.config.email = evt.currentTarget.value;
    this.setState({ promotion: promotion });
    this.state.emailCKEditor.setData(evt.currentTarget.value);

  }

  handleUpdateEmailCK(data) {
    var promotion = this.state.promotion;
    promotion.config.email = "" + data;


  }



  handleUpdateEmailSubject(evt) {
    var promotion = this.state.promotion;
    promotion.config.email_subject = evt.currentTarget.value;

    this.setState({ promotion: promotion });

  }


  handleUpdateTerms(evt) {
    var promotion = this.state.promotion;
    promotion.config.terms = evt.currentTarget.value;

    this.setState({ promotion: promotion });
    this.state.termsCKEditor.setData(evt.currentTarget.value);

  }

  handleUpdateTitle(evt) {
    var promotion = this.state.promotion;
    promotion.config.cms.title = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }
  handleUpdateImage(evt) {
    var promotion = this.state.promotion;
    promotion.config.cms.image = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateInitDate(evt) {
    var promotion = this.state.promotion;
    promotion.config.calendar.init_date = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }
  handleUpdateEndDate(evt) {
    var promotion = this.state.promotion;
    promotion.config.calendar.end_date = evt.currentTarget.value;
    this.setState({ promotion: promotion });
  }


  handleUpdateInitDate2(date) {
    console.log("Date = " + date);
    var promotion = this.state.promotion;
    promotion.config.calendar.init_date = date;
    this.setState({ promotion: promotion });
  }

  handleUpdateEndDate2(date) {
    var promotion = this.state.promotion;
    promotion.config.calendar.end_date = date;
    this.setState({ promotion: promotion });
  }

  goBack(evt) {
    //this.props.history.goBack();
    this.props.history.push('/marketing/promotions/promotions');
  }

  handleUpdateType(evt) {
    var promotion = this.state.promotion;
    promotion.type = evt.value;
    if (promotion.type === "INTERNAL") {
      if (promotion.config.internalConfig === undefined) {
        promotion.config.internalConfig = {};
      }
      promotion.config.externalConfig = undefined;
    }
    if (promotion.type === "EXTERNAL") {
      if (promotion.config.externalConfig === undefined) {
        promotion.config.externalConfig = {};
      }
      promotion.config.internalConfig = undefined;
    }

    this.setState({ promotion: promotion });
    return false;

  }

  showPopup(title, obj) {
    this.setState({ popup: obj, popupTitle: title });
    document.getElementById("popup").classList.remove("d-none");
  }



  showAlert(title, obj) {
    this.setState({ showModal: true, errorTitle: title, errorContent: obj });
  }



  closeAlert() {
    this.setState({ showModal: false });
  }

  closePopup() {
    this.setState({ showModal: false, popup: undefined, popupTitle: undefined });
    document.getElementById("popup").classList.add("d-none");

  }




  handleUpdateMaxUsers(evt) {
    var promotion = this.state.promotion;
    promotion.config.max_users = evt.target.value
    if (promotion.config.max_users === '') delete promotion.config.max_users
    this.setState({ promotion: promotion });
  }


  handleUpdateMaxDailyUsers(evt) {
    var promotion = this.state.promotion;
    promotion.config.max_daily_users = evt.target.value
    if (promotion.config.max_daily_users === '') delete promotion.config.max_daily_users
    this.setState({ promotion: promotion });
  }

  handleUpdateMaxTimesPerUser(evt) {
    var promotion = this.state.promotion;
    promotion.config.max_times_per_user = evt.target.value
    if (promotion.config.max_times_per_user === '') delete promotion.config.max_times_per_user
    this.setState({ promotion: promotion });
  }

  handleUpdateMaxRedeeem(evt) {
    var promotion = this.state.promotion;
    promotion.config.max_redeem = evt.target.value * 100;
    if (promotion.config.max_redeem === 0) delete promotion.config.max_redeem
    this.setState({ promotion: promotion });
  }

  handleUpdateMaxPercentageRedeeem(evt) {
    var promotion = this.state.promotion;
    promotion.config.max_percentage_redeem = evt.target.value;
    this.setState({ promotion: promotion });
  }

  handleUpdateExpirationDays(evt) {
    var promotion = this.state.promotion;
    promotion.config.expiration_days = evt.target.value
    if (promotion.config.expiration_days === '') delete promotion.config.expiration_days
    this.setState({ promotion: promotion });
  }

  handleUpdateActivationExpirationDays(evt) {
    var promotion = this.state.promotion;
    promotion.config.activation_expiration_days = evt.target.value
    if (promotion.config.activation_expiration_days === '') delete promotion.config.activation_expiration_days
    this.setState({ promotion: promotion });
  }

  handleUpdateTotalCodes(evt) {
    var promotion = this.state.promotion;
    promotion.config.totalCodes = evt.target.value
    promotion.config.codes = ''
    this.setState({ promotion: promotion });
  }

  onTagsChange(evt) {
    var promotion = this.state.promotion;
    let values = evt.objSelection;

    // if (values !== undefined && this.state.promotion_tags.length) {
    //   val = Array.isArray(values)
    //     ? this.state.promotion_tags.filter((x) => values.includes(x["tag"]))
    //     : this.state.promotion_tags.find((x) => x["tag"] === values);
    // }
    promotion.tags = values
    this.setState({ promotion: promotion });
  }


  handleUpdateCodesLength(evt) {
    var promotion = this.state.promotion;
    promotion.config.codesLength = evt.target.value
    promotion.config.codes = ''
    this.setState({ promotion: promotion });
  }

  handleUpdateCodesPrefix(evt) {
    var promotion = this.state.promotion;
    promotion.config.codesPrefix = evt.target.value
    promotion.config.codes = ''
    this.setState({ promotion: promotion });
  }

  handleUpdateCodes(evt) {
    var promotion = this.state.promotion;
    promotion.config.codes = evt.target.value
    this.setState({ promotion: promotion });
  }

  generateCodes() {
    const { t } = this.props;
    let alphabet = "abcdefghijklmnopqrstuvwxyz1234567890";
    let promotion = this.state.promotion;
    let codes = "";
    let codesCreated = {};
    if (promotion.config.codesLength <= promotion.config.codesPrefix.length) {
      this.props.showAlert(
        t('Error'),
        <p>{t('Promotion error Prefix')}</p>,
      )

    }
    else {
      for (let i = 0; i < parseInt(promotion.config.totalCodes); i++) {
        let code = "";
        if (promotion.config.codesPrefix !== undefined && promotion.config.codesPrefix.length > 0) {
          code = promotion.config.codesPrefix;
        } else {
          promotion.config.codesPrefix = '';
        }
        for (let c = 0; c < parseInt(promotion.config.codesLength) - promotion.config.codesPrefix.length; c++) {
          code += alphabet.charAt(Math.floor(Math.random() * alphabet.length));
        }
        if (codesCreated[code] !== undefined) {
          console.log("Duplicated code " + code);
          i = i - 1;
        } else {
          if (codes.length > 0) {
            codes += ",";
          }
          codes += code;
          codesCreated[code] = code;
        }
      }
      promotion.config.codes = codes;
      this.setState({ promotion: promotion });
    }
  }



  uploadAdapter(loader) {
    var API_URL = "";
    var UPLOAD_ENDPOINT = "";

    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("files", file);
            // let headers = new Headers();
            // headers.append("Origin", "http://localhost:3000");
            fetch(`${API_URL}/${UPLOAD_ENDPOINT}`, {
              method: "post",
              body: body
              // mode: "no-cors"
            })
              .then((res) => res.json())
              .then((res) => {
                resolve({
                  default: `${API_URL}/${res.filename}`
                });
              })
              .catch((err) => {
                reject(err);
              });
          });
        });
      }
    };
  }

  uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return this.uploadAdapter(loader);
    };
  }

  onChangeBasicSelect(datos, item) {
    if (item.optsConfig) {
      let hideExternalConfigAux = this.state.hideExternalConfig;
      if (hideExternalConfigAux[item.name] !== undefined) { //Hago visible los que habia desactivado
        for (let name of hideExternalConfigAux[item.name]) {
          const clavesEncontradas = Object.entries(hideExternalConfigAux) //Para ver si se encuentra oculto por alguna otra opcion de otro select
            .filter(([clave, array]) => array.includes(name))
            .map(([clave]) => clave);
          if (clavesEncontradas.length === 1) {
            if (document.getElementById(name)) document.getElementById(name).style.display = "";
          }
        }
      }
      hideExternalConfigAux[item.name] = []; //Borro por si la optConfig nueva es distinta a la anterior

      const optConfig = item.optsConfig.find(objeto => objeto.id === datos.value); //Busco si existe una configuración especifica
      if (optConfig) {

        let hideOpts = optConfig.hide.split(",");
        for (let name of hideOpts) {
          hideExternalConfigAux[item.name].push(name);
          if (document.getElementById(name)) {
            document.getElementById(name).style.display = "none"; //Oculto los que me pone que oculte
          }
        }
      }
      this.setState({ hideExternalConfig: hideExternalConfigAux });
    }
    this.handleUpdateExternalDefinition(datos.name, { currentTarget: { value: datos.value } });
  }

  getDefinitionFields(item) {
    const { t } = this.props;
    let readOnly = item.readOnly && !this.state.newPromotion || false;
    switch (item.type) {
      case 'select':
        return (<div className="col-12 col-sm-6 col-lg-3" id={item.name}>
          <BasicSelect
            label={t(item.label)}
            name={item.name}
            addOpts={item.opts}
            onChange={(datos) => {
              this.onChangeBasicSelect(datos, item)
            }}
            disabled={readOnly}
            value={this.state.promotion.config.externalConfig[item.name]}
            noEmptyValue
          />
        </div>)

      case 'currency':
        return (<div className="col-12 col-sm-6 col-lg-3" id={item.name}>
          <TextField disabled={this.state.externalBLocked || readOnly}
            label={t(item.label)}
            type="number"
            value={this.state.promotion.config.externalConfig[item.name] / 100}
            onChange={(newValue) => {
              this.handleUpdateExternalDefinition(item.name, newValue);
            }}
          />
        </div>)
      default:
        return (<div className="col-12 col-sm-6 col-lg-3" id={item.name} >
          <TextField disabled={this.state.externalBLocked || readOnly}
            label={t(item.label)}
            type="text"
            value={this.state.promotion.config.externalConfig[item.name]}
            onChange={(newValue) => {
              this.handleUpdateExternalDefinition(item.name, newValue);
            }}
          />
        </div>)
    }
  }

  async checkGroupsMembers(){

    const { t } = this.props;
    this.isLoaging=true
    this.showLoading()
    if(this.state.company.config.backoffice?.promotions?.eventControlGroupsMembers!==undefined && 
      this.state.company.config.backoffice?.promotions?.eventControlGroupsMembers.length>0 && 
      this.state.events.filter((event) => (this.state.company.config.backoffice?.promotions?.eventControlGroupsMembers.includes(event.event_id))).length>0)
    {
      //tiene un evento que necesita la comprobación de miembros de grupo.
      let groupsIds=this.state.groups_allowed.map((g) => g.group).join(",")
      let members=await this.adminClient.getCountMembersInGroups(JSON.parse(utils.getSessionItem("user")).session,this.state.promotion.company,groupsIds)
      

      this.showPopup(t('Promotion'),
      <>
        <p>{t('CountMembersInGroups')+": "+members.data}</p>
        <button type="button"                  
          className="btn btn-primary"
          onClick={(evt) => {
           
            this.closePopup()
            this.savePromotions();
            
          }}
        >
          {t("Ok")}
        </button>
        <button type="button"                  
          className="btn btn-primary"
          onClick={(evt) => {
            this.isLoaging=false
            this.closeLoading()
            this.closePopup()
            
          }}
        >
          {t("Cancel")}
        </button>
      </>
    );
      // this.props.showAlert(t('Promotion'),t('CountMembersInGroups')+":"+members.data,[
      //   <button type="button"                  
      //     className="btn btn-primary"
      //     onClick={(evt) => {
            
      //       this.props.handleCloseAlert();
      //       this.savePromotions();
            
      //     }}
      //   >
      //     {t("Ok")}
      //   </button>,<button type="button"                  
      //     className="btn btn-primary"
      //     onClick={(evt) => {
            
      //       this.props.handleCloseAlert();
            
      //     }}
      //   >
      //     {t("Cancel")}
      //   </button>
      // ]);
    }else{
      this.savePromotions()
    }    
  }

  render() {
    const { t } = this.props;
    // const filter = createFilterOptions();

    if (utils.getSessionItem("user") !== undefined) {
      return (
        <div className="col-12   float-left pt-3 m-0">
          <div className="card">
            <div className="card-header">
              <a href="#" className="float-start" onClick={this.goBack}><span><FontAwesomeIcon icon="arrow-left" /></span></a>

              {t("Promotion details")}
              {this.props.idDoc && <DocButton {...this.props} idDoc={this.props.idDoc} />}
              {/* <a className="help_link" onClick={e => { window.dispatchEvent(new CustomEvent("showHelp", { detail: { url: "/pages/marketing/promotionDetails" } })) }}><span><FontAwesomeIcon icon="info-circle" /></span></a> */}
            </div>
            <div className="card-body m-0 ">
              <div className="row">
                <div>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-4">
                        <TextField id="promotion" disabled={!this.state.newPromotion} label={t("Promotion")}
                          onBlur={this.handleVerifyPromotion}
                          type="text" value={this.state.promotion.promotion} onChange={this.handleUpdatePromotion} />
                      </div>
                      <div className="col-12 col-sm-4">
                        <TextField label={t("Name")} type="text" value={this.state.promotion.config.name} onChange={this.handleUpdateName} />
                      </div>
                      <div className="col-12 col-sm-2">
                        <BasicSelect
                          idProperty='type'
                          nameProperty='type'
                          basicInfoName='PromotionsTypes'
                          value={this.state.promotion.type}
                          label={t('Type')}
                          onChange={this.handleUpdateType}
                          disabled={this.state.externalBLocked}
                          translate={{ property: "type", prefix: "PROMOTION_TYPE_" }}
                          multiple={false}
                          noEmptyValue={false}
                          disableClearable={true}
                        />

                      </div>
                      <div className="col-12 col-sm-2">
                        <BasicSelect
                          idProperty='name'
                          basicInfoName='EnabledDisabled'
                          value={this.state.promotion.status}
                          name='value'
                          label={t('Status')}
                          onChange={this.handleUpdateStatus}
                          translate={{ property: 'name', prefix: '' }}
                          multiple={false}
                          noEmptyValue={false}
                          disableClearable={true}
                          disabled={!utils.isGranted("SAVE_MARKETING_PROMOTIONS_STATUS")}
                        />

                      </div>
                      <div className="col-12 col-sm-4">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.max_users !== undefined }} label={t("Max users")} type="number" value={this.state.promotion.config.max_users} onChange={this.handleUpdateMaxUsers} />
                      </div>
                      <div className="col-12 col-sm-4">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.max_daily_users !== undefined }} label={t("Max daily users")} type="number" value={this.state.promotion.config.max_daily_users} onChange={this.handleUpdateMaxDailyUsers} />
                      </div>
                      <div className="col-12 col-sm-4">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.max_times_per_user !== undefined }} label={t("Max times per user")} type="number" value={this.state.promotion.config.max_times_per_user} onChange={this.handleUpdateMaxTimesPerUser} />
                      </div>

                      <div className="col-12 col-sm-2">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.max_redeem !== undefined }} label={t("Max absolute redeem")} type="number" value={this.state.promotion.config.max_redeem / 100} onChange={this.handleUpdateMaxRedeeem} />
                      </div>
                      <div className="col-12 col-sm-2">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.max_percentage_redeem !== undefined }} label={t("Max % redeem")} type="number" value={this.state.promotion.config.max_percentage_redeem} onChange={this.handleUpdateMaxPercentageRedeeem} />
                      </div>

                      <div className="col-12 col-sm-4">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.min_amount !== undefined }} label={t("Min amount")} type="number" value={this.state.promotion.config.min_amount / 100} onChange={this.handleUpdateMinAmount} />
                      </div>
                      <div className="col-12 col-sm-4">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.max_amount !== undefined }} label={t("Max amount")} type="number" value={this.state.promotion.config.max_amount / 100} onChange={this.handleUpdateMaxAmount} />
                      </div>


                      <div className="col-12 col-sm-3">
                        {/* <SelectPromotionChoices label={t("User choice")} choices={this.state.choices} onChange={this.handleUpdateChoice} value={this.state.promotion.config.choice} ></SelectPromotionChoices> */}
                        <BasicSelect
                          idProperty="choice"
                          data="choices"
                          nameProperty="choice"
                          label={t("User choice")}
                          addOpts={[{ choice: "NO_CHOICE" }]}
                          basicInfoName="PromotionChoices"
                          onChange={this.handleUpdateChoice}
                          value={this.state.promotion.config.choice}
                          translate={{ property: "choice", prefix: "" }}
                          disableClearable
                        />
                      </div>

                      <div className="col-12 col-sm-2">
                        <FormControlLabel control={<Checkbox onChange={this.handleUpdateRegisterSelected}
                          checked={this.state.promotion.config.register_selected} />} label={t("Register selected")} />
                      </div>
                      <div className="col-12 col-sm-2">
                        <FormControlLabel control={<Checkbox onChange={this.handleUpdateBuddy}
                          checked={this.state.promotion.config.buddy} />} label={t("Buddy program")} />
                      </div>
                      <div className="col-12 col-sm-2">
                        {/* <SelectCurrencies label={t("Currency")} company={utils.getSessionItem("Company")} onChange={this.handleUpdateCurrency}
                          value={this.state.promotion.config.internalConfig.currency || this.state.promotion.config.currency}></SelectCurrencies> */}
                        <BasicSelect
                          label={t("Currency")}
                          idProperty="currency"
                          nameProperty="currency"
                          data="currencies"
                          basicInfoName="CurrenciesCompany"
                          onChange={this.handleUpdateCurrency}
                          value={this.state.promotion.config.currency}
                        />
                      </div>
                      <div className="col-12 col-sm-3">
                        <BasicSelect
                          idProperty="affiliate"
                          name="affiliate"
                          label={t("Affiliates")}
                          data="affiliates"
                          basicInfoName="Affiliates"
                          onChange={this.handleUpdateAffiliates}
                          value={this.state.promotion.config.affiliates}
                          multiple
                        />
                      </div>
                      <div className="col-12 col-sm-3">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.expiration_days !== undefined }} label={t("Expiration days")} type="number" value={this.state.promotion.config.expiration_days} onChange={this.handleUpdateExpirationDays} />
                      </div>
                      <div className="col-12 col-sm-3">
                        <TextField InputLabelProps={{ shrink: this.state.promotion.config.activation_expiration_days !== undefined }} label={t("Activation expiration days")} type="number" value={this.state.promotion.config.activation_expiration_days} onChange={this.handleUpdateActivationExpirationDays} />
                      </div>
                      <div className="col-12 col-sm-3">
                        <TextField label={t("Max activation cash")} type="number" value={this.state.promotion.config.maxactivationcash / 100} onChange={this.handleUpdateMaxActivationCash} />
                      </div>
                      {(utils.getCurrentCompany().config?.promotions?.cancel_pending_promotion_processed_payout
                        ||
                        utils.getCurrentCompany().config?.promotions?.cancel_pending_promotion_requested_payout)
                        &&
                        <div className="col-12 col-sm-2">
                          <FormControlLabel control={<Checkbox onChange={this.handleUpdateNotCancel}
                            checked={this.state.promotion.config.not_cancel_on_payout} />} label={t("not cancel on payout")} />
                        </div>}
                    </div>
                    <div className="row">

                      <div className="col-12">
                        {this.state.promotionDeleted ?
                          <>
                            <button type="button" className="btn btn-secondary" onClick={this.handleDuplicate}>{t("Duplicate")}</button>

                          </>
                          :
                          <><button type="button" className="btn btn-primary" onClick={this.handleSubmit} disabled={this.isLoaging}>{t("Save")}</button>
                            {!this.state.newPromotion && <button type="button" className="btn btn-secondary" onClick={this.handleDuplicate}>{t("Duplicate")}</button>}
                            {!this.state.newPromotion && utils.isGranted("DELETE_MARKETING_PROMOTIONS") &&  <button type="button" className="btn btn-danger" onClick={this.handleDelete}>{t("Delete")}</button>}
                          </>}

                      </div>
                    </div>

                    <div className="row">

                      <ul className="nav nav-tabs" id="myTab" role="tablist">
                        <li className="nav-item" role="presentation">
                          <button className="nav-link active" id="config-tab" data-bs-toggle="tab" data-bs-target="#config" type="button" role="tab" aria-controls="config" aria-selected="false">{t("Prize")}</button>
                        </li>
                        {this.state.promotion.type === "INTERNAL" &&
                          <li className="nav-item" role="presentation">
                            <button className="nav-link" id="machines-tab" data-bs-toggle="tab" data-bs-target="#machines" type="button" role="tab" aria-controls="machines" aria-selected="false">{t("Machines")}</button>
                          </li>
                        }
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="calendar-tab" data-bs-toggle="tab" data-bs-target="#calendar" type="button" role="tab" aria-controls="calendar" aria-selected="false">{t("Calendar")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="codes-tab" data-bs-toggle="tab" data-bs-target="#codes" type="button" role="tab" aria-controls="codes" aria-selected="false">{t("Codes")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="groups-tab" data-bs-toggle="tab" data-bs-target="#groups" type="button" role="tab" aria-controls="groups" aria-selected="false">{t("Groups")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="events-tab" data-bs-toggle="tab" data-bs-target="#events" type="button" role="tab" aria-controls="events" aria-selected="false">{t("Events")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="methods-tab" data-bs-toggle="tab" data-bs-target="#methods" type="button" role="tab" aria-controls="methods" aria-selected="false">{t("Payment methods")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="cms-tab" data-bs-toggle="tab" data-bs-target="#cms" type="button" role="tab" aria-controls="cms" aria-selected="false">{t("CMS")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="terms-tab" data-bs-toggle="tab" data-bs-target="#terms" type="button" role="tab" aria-controls="terms" aria-selected="false">{t("Terms and conditions")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="email-tab" data-bs-toggle="tab" data-bs-target="#email" type="button" role="tab" aria-controls="email" aria-selected="false">{t("Email")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="images-tab" data-bs-toggle="tab" data-bs-target="#images" type="button" role="tab" aria-controls="images" aria-selected="false">{t("Images")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="tags-tab" data-bs-toggle="tab" data-bs-target="#tags" type="button" role="tab" aria-controls="tags" aria-selected="false">{t("Tags")}</button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button className="nav-link" id="logs-tab" data-bs-toggle="tab" data-bs-target="#logs" type="button" role="tab" aria-controls="logs" aria-selected="false">{t("Logs")}</button>
                        </li>
                      </ul>
                      <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade show active" id="config" role="tabpanel" aria-labelledby="config-tab">
                          {this.state.promotion.type === "INTERNAL" &&
                            <>
                              <div className="row">
                                <div className="col-12 col-md-4">
                                  {this.state.promotion.config.internalConfig.percentage &&
                                    <TextField
                                      InputLabelProps={{ shrink: this.state.promotion.config.internalConfig.amount !== undefined }}
                                      InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                      }}

                                      id="internal_amount_percentage" label={t("Amount")} type="text" value={this.state.promotion.config.internalConfig.amount || ""} onChange={this.handleUpdatePercentageAmount} />
                                  }

                                  {!this.state.promotion.config.internalConfig.percentage &&
                                    <TextField InputLabelProps={{ shrink: this.state.promotion.config.internalConfig.amount !== undefined }} id="internal_amount" label={t("Amount")} type="text" value={this.state.promotion.config.internalConfig.amount / 100 || ""} onChange={this.handleUpdateAmount} />
                                  }
                                </div>
                                <div className="col-12 col-md-2">
                                  <FormControlLabel control={<Checkbox onChange={this.handleUpdatePercentage} checked={this.state.promotion.config.internalConfig.percentage} />}
                                    label={t("Is percentage")} />
                                </div>
                                <div className="col-12 col-md-4">
                                  {/* <SelectAccounts label={t("Account")} company={utils.getSessionItem("Company")} onChange={this.handleUpdateAccount} value={this.state.promotion.config.internalConfig.account}></SelectAccounts> */}
                                  <BasicSelect
                                    idProperty="account"
                                    name="account"
                                    label={t("Account")}
                                    data="accounts"
                                    basicInfoName="Accounts"
                                    onChange={this.handleUpdateAccount}
                                    filters={["custom"]}
                                    filterOptionsFunction={(item) => {
                                      return !(item.account === "BETTING-BONUS" || item.account === "DEPOSIT-CASH")
                                    }}
                                    value={this.state.promotion.config.internalConfig.account}
                                    noEmptyValue
                                  />
                                </div>
                              </div>
                              {this.state.promotion.config.internalConfig.percentage &&
                                <div className="row">
                                  <div className="col-12 col-md-6">
                                    <TextField InputLabelProps={{ shrink: this.state.promotion.config.internalConfig !== undefined && this.state.promotion.config.internalConfig.min_awarded_amount !== undefined }}
                                      id="min_awarded_amount" label={t("Minimun awarded amount")} type="number" value={this.state.promotion.config.internalConfig.min_awarded_amount / 100} onChange={this.handleUpdateMinAwardedAmount} />
                                  </div>
                                  <div className="col-12 col-md-6">
                                    <TextField InputLabelProps={{ shrink: this.state.promotion.config.internalConfig !== undefined && this.state.promotion.config.internalConfig.max_awarded_amount !== undefined }}
                                      id="max_awarded_amount" label={t("Maximun awarded amount")} type="number" value={this.state.promotion.config.internalConfig.max_awarded_amount / 100} onChange={this.handleUpdateMaxAwardedAmount} />
                                  </div>
                                </div>
                              }
                              <div className="row">
                                <div className="col-12 col-md-6">
                                  <TextField InputLabelProps={{ shrink: this.state.promotion.config.internalConfig.wr !== undefined }} id="internal_wr" label={t("WR Multiplier")} type="number" value={this.state.promotion.config.internalConfig.wr} onChange={this.handleUpdateWR} />
                                </div>
                                <div className="col-12 col-md-6">
                                  {/* <SelectPromotionCalculators label={t("Promotion calculator")} calculators={this.state.calculators} onChange={this.handleUpdateCalculator} value={this.state.promotion.config.internalConfig.calculator} ></SelectPromotionCalculators> */}
                                  <BasicSelect
                                    idProperty="calculator"
                                    data="calculators"
                                    name="calculatorPromotion"
                                    label={t("Promotion calculator")}
                                    addOpts={[{ calculator: "NO_CALCULATOR", name: t("No calculator") }]}
                                    basicInfoName="PromotionCalculators"
                                    onChange={this.handleUpdateCalculator}
                                    value={this.state.promotion.config.internalConfig.calculator}
                                    disableClearable
                                  />
                                </div>

                              </div>
                            </>
                          }
                          {this.state.promotion.type === "VIRTUALGOOD" &&

                            <div className="row">
                              <div className="col-12">
                                {/* <SelectVirtualGoods label={t("Virtual Good")} placeholder={t("Does not apply")}
                                  onChange={this.handleUpdateVirtualGood} value={this.state.promotion.config.internalConfig.virtual_good || -1} company={utils.getSessionItem('Company')}></SelectVirtualGoods> */}
                                <BasicSelect
                                  label={t("Virtual Good")}
                                  idProperty="virtual_good"
                                  name="virtualGoods"
                                  data="virtual_goods"
                                  basicInfoName="VirtualGoods"
                                  onChange={this.handleUpdateVirtualGood}
                                  value={this.state.promotion.config.internalConfig.virtual_good}

                                />
                              </div>
                            </div>

                          }
                          {this.state.promotion.type === "EXTERNAL" &&
                            <div className="row">
                              <div className="col-12 col-sm-6 col-lg-3">
                                {/* <SelectProviders disabled={this.state.externalBLocked} label={t("Provider")} values={this.state.providers}
                                  value={this.state.promotion.config.externalConfig.provider} onChange={this.handleUpdateProvider.bind(this)}  ></SelectProviders> */}
                                <BasicSelect
                                  idProperty="provider"
                                  name="provider"
                                  label={t("Provider")}
                                  addOpts={this.state.providers}
                                  onChange={this.handleUpdateProvider.bind(this)}
                                  value={this.state.promotion.config.externalConfig.provider}
                                  disabled={this.state.externalBLocked}
                                  disableClearable
                                />
                              </div>

                              {this.state.provider !== undefined && this.state.provider !== null &&
                                this.state.provider.config !== undefined && this.state.provider.config.promotions !== undefined
                                && this.state.provider.config.promotions.definition !== undefined &&
                                <>
                                  {this.state.provider.config.promotions.definition.map(this.getDefinitionFields
                                    //   item => (
                                    //   <div className="col-12 col-sm-6 col-lg-3">
                                    //     <TextField disabled={this.state.externalBLocked}
                                    //       label={t(item.label)}
                                    //       type={item.type === 'currency' ? "number" : item.type}
                                    //       value={item.type === 'currency' ? this.state.promotion.config.externalConfig[item.name] / 100 : this.state.promotion.config.externalConfig[item.name]}
                                    //       onChange={(newValue) => {
                                    //         this.handleUpdateExternalDefinition(item.name, newValue);
                                    //       }}
                                    //     />
                                    //   </div>

                                    // )
                                  )}
                                </>
                              }
                              {(this.state.provider === undefined || this.state.provider === null || this.state.provider.config === undefined ||
                                this.state.provider.config.promotions === undefined || this.state.provider.config.promotions.definition === undefined) &&
                                <>
                                  <div className="col-12 col-sm-6 col-lg-3">
                                    <TextField InputLabelProps={{ shrink: this.state.promotion.config.externalConfig.external_promotion_id !== undefined }}
                                      disabled={this.state.externalBLocked} label={t("Provider's Promotion Identifier")} type="text" value={this.state.promotion.config.externalConfig.external_promotion_id} onChange={this.handleUpdateExternalPromotionId} />

                                  </div>
                                </>
                              }
                              {this.state.provider !== undefined && this.state.provider !== null && this.state.provider.config !== undefined && this.state.provider.config.promotions !== undefined
                                && this.state.provider.config.promotions.types !== undefined && (this.state.provider.config.promotions.types.length > 1) &&
                                <div className="col-12 col-sm-6 col-lg-3">
                                  {/* <SelectExternalPromotionType disabled={this.state.externalBLocked} label={t("Type")} values={this.state.provider.config.promotions.types}
                                    value={this.state.promotion.config.externalConfig.external_type_id} onChange={this.handleUpdateExternalPromotionType.bind(this)}  ></SelectExternalPromotionType> */}
                                  <BasicSelect
                                    idProperty="type"
                                    nameProperty="type"
                                    name="provider"
                                    label={t("Type")}
                                    addOpts={this.state.provider.config.promotions.types}
                                    onChange={this.handleUpdateExternalPromotionType.bind(this)}
                                    value={this.state.promotion.config.externalConfig.external_type_id}
                                    disabled={this.state.externalBLocked}
                                    translate={{ property: "type", prefix: "EXTERNAL_PROMOTION_TYPE_" }}
                                    disableClearable
                                  />
                                </div>
                              }

                              {this.state.provider !== undefined && this.state.provider !== null
                                && this.state.provider.config !== undefined && this.state.provider.config.promotions !== undefined
                                && this.state.provider.config.promotions.machines !== undefined && (this.state.provider.config.promotions.machines === true) &&

                                <div className="col-12">

                                  <TreeSelector
                                    label={t('Machine')}
                                    nodes={this.state.providerMachines}
                                    checked={this.state.providerMachinesChecked}
                                    typeTree="providerMachine"
                                    config={this.state.provider.config.promotions.classification || "sub_provider"}
                                    onCheck={this.onTreeProviderMachinesChecked}
                                    extraConfig={{ freeSpin: true }}
                                  ></TreeSelector>

                                </div>
                              }
                            </div>
                          }
                        </div>

                        {this.state.promotion.type === "INTERNAL" &&
                          <div className="tab-pane fade  " id="machines" role="tabpanel" aria-labelledby="machines-tab">
                            <div className="row">
                              {/* <div className="col-12 ">
                                  <Autocomplete
                                    accessKey=""
                                    multiple
                                    id="tags-outlined-internal"
                                    key="tags-outlined-internal"
                                    options={this.state.internalMachines}
                                    getOptionLabel={(option) => option.name + " (" + option.provider + " " + option.machine + ")"}
                                    
                                    onChange={this.handleUpdateManualInternalMachines}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}

                                        label={t("Search")}
                                      />
                                    )}
                                  />
                                </div> */}

                              <div className="col-12">
                                <div className="Border_Label">
                                  <InputLabel id="Marketing_MachinesTreeSelector-label">{t("Allowed Machines")}</InputLabel>
                                  <div>
                                    <TreeSelector
                                      label={t("Machine")}
                                      nodes={this.state.internalMachines}
                                      checked={this.state.treeInternalMachinesChecked}
                                      onCheck={this.onTreeInternalMachinesChecked}
                                      typeTree="machine"
                                    ></TreeSelector>
                                  </div>
                                </div>
                              </div>
                              <div className="col-12 ">
                                <Autocomplete
                                  accessKey=""
                                  multiple
                                  id="tags-outlined-internal"
                                  key="tags-outlined-internal"
                                  options={this.state.tags}
                                  getOptionLabel={(option) => option.name}
                                  value={this.state.selectedInternalAllowedTags}
                                  onChange={this.handleUpdateInternaAllowedTags}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}

                                      label={t("Allowed Tags")}
                                    />
                                  )}
                                />
                              </div>

                              <div className="col-12 ">
                                <Autocomplete
                                  accessKey=""
                                  multiple
                                  id="tags-outlined-internal"
                                  key="tags-outlined-internal"
                                  options={this.state.tags}
                                  getOptionLabel={(option) => option.name}
                                  value={this.state.selectedInternalForbiddenTags}
                                  onChange={this.handleUpdateInternalForbiddenTags}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}

                                      label={t("Forbidden Tags")}
                                    />
                                  )}
                                />
                              </div>


                              <div className="col-12 ">
                                <Autocomplete
                                  accessKey=""
                                  multiple
                                  id="tags-outlined-internal"
                                  key="tags-outlined-internal"
                                  options={this.state.internalMachines}
                                  getOptionLabel={(option) => option.name + " (" + option.provider + " " + option.machine + ")"}
                                  value={this.state.selectedInternalForbiddenMachines}
                                  onChange={this.handleUpdateInternalForbiddenMachines}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}

                                      label={t("Forbidden Machines")}
                                    />
                                  )}
                                />
                              </div>
                            </div>

                          </div>
                        }

                        <div className="tab-pane fade  " id="calendar" role="tabpanel" aria-labelledby="calendar-tab">
                          <div className="row">
                            <div className="col-12 col-sm-3">

                              <DateAndTimePicker
                                label={t("Init Date")}
                                value={this.state.promotion.config.calendar.init_date}
                                showTime={true}
                                callBackFunction={this.handleUpdateInitDate2}
                              />
                            </div>
                            <div className="col-12 col-sm-3">
                              <DateAndTimePicker
                                label={t("End Date")}
                                value={this.state.promotion.config.calendar.end_date}
                                showTime={true}
                                callBackFunction={this.handleUpdateEndDate2}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              {/* <SelectWeekDays handleChange={this.handleUpdateWeekDays} handleChangeAll={this.handleUpdateAllWeekDays} week={this.state.promotion.config.calendar.week_days}></SelectWeekDays> */}

                              <WeeksCheckbox handleChange={this.handleUpdateWeekDays} handleChangeAll={this.handleUpdateAllWeekDays} week={this.state.promotion.config.calendar.week_days}></WeeksCheckbox>
                              {/* </div>
                            <div className="col-12 col-sm-3"> */}
                              <FormControlLabel control={<Checkbox onChange={this.handleUpdateFirstDateOfMonth} checked={this.state.promotion.config.calendar.first_day_of_month} />}
                                label={t("First day of month")} />
                              {/* </div>
                            <div className="col-12 col-sm-3"> */}
                              <FormControlLabel control={<Checkbox onChange={this.handleUpdateLastDateOfMonth} checked={this.state.promotion.config.calendar.last_day_of_month} />}
                                label={t("Last day of month")} />
                            </div>
                          </div>
                          {/* <div className="row">
                            <div className="col-12 col-sm-6">
                              <FormControlLabel control={<Checkbox onChange={this.handleUpdateFirstDateOfMonth} checked={this.state.promotion.config.calendar.first_day_of_month} />}
                                label={t("First day of month")} />
                            </div>
                            <div className="col-12 col-sm-6">
                              <FormControlLabel control={<Checkbox onChange={this.handleUpdateLastDateOfMonth} checked={this.state.promotion.config.calendar.last_day_of_month} />}
                                label={t("Last day of month")} />
                            </div>
                          </div> */}
                        </div>

                        <div className="tab-pane fade  " id="codes" role="tabpanel" aria-labelledby="codes-tab">
                          <div className="row">
                            <div className="col-12">

                              {/* <FormControl variant="outlined" margin={"none"} style={{ width: "100%", marginBottom: 32 }}>
                                <InputLabel id="code-type-select-label">{t("Codes")}</InputLabel>
                                < Select labelId="code-type-select-label" label={t("Codes")}
                                  id="promotion_code_type" key="promotion_code_type"
                                  value={this.state.promotion.config.code_type} onChange={this.handleUpdateCodeType} >
                                  <MenuItem key="NO_CODE" value="NO_CODE">{t("CODE_TYPE_NO_CODE")}</MenuItem>
                                  <MenuItem key="UNIQUE" value="UNIQUE">{t("CODE_TYPE_UNIQUE")}</MenuItem>
                                  <MenuItem key="MULTIPLE" value="MULTIPLE">{t("CODE_TYPE_MULTIPLE")}</MenuItem>
                                  <MenuItem key="MULTIPLE_AUTO" value="MULTIPLE_AUTO">{t("CODE_TYPE_MULTIPLE_AUTO")}</MenuItem>
                                </Select>
                              </FormControl> */}

                              <BasicSelect
                                idProperty='type'
                                disabled={!this.state.editCodes}
                                value={this.state.promotion.config.code_type}
                                name='value'
                                label={t('Codes')}
                                addOpts={[{ type: "NO_CODE", name: "NO_CODE" }, { type: "UNIQUE", name: "UNIQUE" },
                                { type: "MULTIPLE", name: "MULTIPLE" }, { type: "MULTIPLE_AUTO", name: "MULTIPLE_AUTO" }]}
                                onChange={this.handleUpdateCodeType}
                                translate={{ property: 'name', prefix: 'CODE_TYPE_' }}
                                multiple={false}
                                noEmptyValue={false}
                                disableClearable={true}
                              />


                              {(this.state.promotion.config.code_type === 'UNIQUE') &&
                                <TextField disabled={!this.state.editCodes} label={t("Unique code")} type="text" value={this.state.promotion.config.codes} onChange={this.handleUpdateCodes} />
                              }
                              {this.state.promotion.config.code_type === 'MULTIPLE' &&
                                <TextField disabled={!this.state.editCodes} multiline label={t("Enter codes comma separated")} type="text" value={this.state.promotion.config.codes} onChange={this.handleUpdateCodes} />
                              }
                              {this.state.promotion.config.code_type === 'MULTIPLE_AUTO' &&
                                <div className="row">
                                  <div className="col-12 col-sm-3">
                                    <TextField disabled={!this.state.editCodes} label={t("Num of codes")} type="number" value={this.state.promotion.config.totalCodes || ''} onChange={this.handleUpdateTotalCodes} />
                                  </div>
                                  <div className="col-12 col-sm-3">
                                    <TextField disabled={!this.state.editCodes} label={t("Codes length")} type="number" value={this.state.promotion.config.codesLength || ''} onChange={this.handleUpdateCodesLength} />
                                  </div>
                                  <div className="col-12 col-sm-3">
                                    <TextField disabled={!this.state.editCodes} label={t("Codes prefix")} type="text" value={this.state.promotion.config.codesPrefix || ''} onChange={this.handleUpdateCodesPrefix} />
                                  </div>
                                  <div className={this.state.editCodes ? 'col-12 col-sm-3' : 'col-12 col-sm-3 hide'}>
                                    <button disabled={!this.state.editCodes} type="button" className="btn btn-primary" onClick={this.generateCodes}>{t("Generate codes")}</button>
                                  </div>
                                  <div className="col-12">
                                    <TextField multiline disabled label={t("Generated codes")} type="text" value={this.state.promotion.config.codes} />
                                  </div>
                                </div>
                              }
                              <Report windowRef="windowRefPromotionCodes" className="mt-3" reportId="promotions_codes" viewFilter="promotions-codes" handleClick={this.handleClick} view="table" rowId="id" ></Report>

                            </div>
                          </div>
                        </div>

                        <div className="tab-pane fade  " id="groups" role="tabpanel" aria-labelledby="groups-tab">
                          <AllowedForbiddenList
                            allowed={this.state.groups_allowed}
                            availables={this.state.groups_availables}
                            forbidden={this.state.groups_forbbiden}
                            idProperty={"group"}
                            nameProperty={"name"}
                            handleUpdateGroups={this.updateGroups.bind(this)}
                            pageSize={5}
                          ></AllowedForbiddenList>
                        </div>

                        <div className="tab-pane fade" id="events" role="tabpanel" aria-labelledby="events-tab">
                          <DoubleList
                            availables={this.state.events_availables}
                            configured={this.state.events}
                            idProperty={"event_id"}
                            configuredListName={t("Includedsm")}
                            useTranslationsId={true}
                            translatePrefix="EVENT_"
                            handleUpdateList={this.updateEvents.bind(this)}
                          ></DoubleList>
                        </div>

                        <div className="tab-pane fade" id="methods" role="tabpanel" aria-labelledby="methods-tab">
                          <DoubleList
                            availables={this.state.methods_availables}
                            configured={this.state.methods}
                            idProperty={"method"}
                            configuredListName={t("Includedsm")}
                            handleUpdateList={this.updateMethods.bind(this)}
                          ></DoubleList>
                        </div>

                        <div className="tab-pane fade" id="cms" role="tabpanel" aria-labelledby="cms-tab">
                          <WebContentData
                            details={{ "promotion": this.state.promotion.promotion }}
                            cms={this.state.promotion.config.cms}
                            id={this.state.promotion.promotion}
                            type="PROMOTION"
                            pathImage="/cms/img/promotions/"
                            showAlert={this.props.showAlert}
                            onChangeHandler={this.onChangeHandlerConfigCMS2}
                          />
                          {/* <Tab key="tabMachineDetailsI18n" active={0}>
                            {this.state.langs.map((lang, idx) =>
                              <Tab.TabPane key={`TabMachineDetails-${idx}`} tab={lang}>
                                <div className="row">

                                  <CMS
                                    lang={this.state.langs.length > 1 ? lang : undefined}
                                    details={this.state.promotion}
                                    onChangeHandler={this.onChangeHandlerConfigCMS}
                                    cmsInfo={this.state.promotion.config.cms[lang] || this.state.promotion.config.cms}
                                    prefix="summary_"
                                    titleLabel={t("Summary title")}
                                    bodyLabel={t("Summary body")}
                                    saveImageCMS={this.saveDataCMSSumary}
                                    pathImage="/cms/img/promotions/"
                                    id={this.state.promotion.promotion}
                                    type="PROMOTION"
                                    showAlert={this.props.showAlert}
                                  />
                                  <CMS
                                    lang={this.state.langs.length > 1 ? lang : undefined}
                                    details={this.state.promotion}
                                    onChangeHandler={this.onChangeHandlerConfigCMS}
                                    cmsInfo={this.state.promotion.config.cms[lang] || this.state.promotion.config.cms}
                                    titleLabel={t("Title")}
                                    bodyLabel={t("Body")}
                                    saveImageCMS={this.saveDataCMS}
                                    pathImage="/cms/img/promotions/"
                                    id={this.state.promotion.promotion}
                                    type="PROMOTION"
                                    showAlert={this.props.showAlert}
                                  />
                                </div>
                              </Tab.TabPane>
                            )}
                          </Tab> */}

                        </div>

                        <div className="tab-pane fade" id="terms" role="tabpanel" aria-labelledby="terms-tab">
                          <Tab key="tabTermsDetailsI18n" active={0}>
                            {this.state.langs.map((lang, idx) => (
                              <Tab.TabPane key={`terms-${lang}`} tab={lang}>
                                <div className="row">
                                  <div className="col-12">
                                    <Editor
                                      height={400}
                                      info={this.state.langs.length > 1 ? this.state?.promotion?.config?.terms[lang] || "" : this.state?.promotion?.config?.terms || ""}
                                      onChangeHandler={({ value }) => {
                                        let promotion = { ...this.state.promotion };
                                        if (this.state.langs.length > 1) {
                                          promotion.config.terms[lang] = value;
                                        } else {
                                          promotion.config.terms = "" + value;
                                        }
                                        this.setState({ promotion });
                                      }}
                                      oneTime
                                    />
                                  </div>
                                </div>
                              </Tab.TabPane>
                            ))}
                          </Tab>
                        </div>

                        <div className="tab-pane fade" id="email" role="tabpanel" aria-labelledby="email-tab">
                          <div className="row">
                            <div className="col-12  ">

                              <div role="tabpanel" >
                                <TextField InputLabelProps={{ shrink: this.state.promotion.config.email_subject !== undefined }} label={t("Subject")} type="text" value={this.state.promotion.config.email_subject} onChange={this.handleUpdateEmailSubject} />
                              </div>

                              <div className="CalimacoEditorDiv">

                                <CKEditor

                                  config={{ height: 1200, allowedContent: true, versionCheck: false }}
                                  initData=""
                                  onChange={(event) => {
                                    const data = event.editor.getData();
                                    var promotion = this.state.promotion;
                                    promotion.config.email = "" + data;
                                    this.setState({ promotion: promotion });

                                  }}
                                  onInstanceReady={(evt) => {
                                    this.setState({ emailCKEditor: evt.editor });
                                    console.log('Email: ckeditor is ready! ' + evt.editor);
                                  }}

                                />



                              </div>

                            </div>
                          </div>

                        </div>

                        <div className="tab-pane fade" id="images" role="tabpanel" aria-labelledby="images-tab">
                          <div className="row">
                            <div className="col-12  ">
                              <div className="Border_Label">
                                <File
                                  dropEnable={true}
                                  showFile={true}
                                  showError={true}
                                  viewerEnable={false}
                                  deleteEnable={true}
                                  saveData={this.saveDataPromotion}
                                  fileName={this.state.promotion.config.images}
                                  fileUrlPref={this.state.company.config.cdn_url + "../"}
                                  getFileUrl={(data) => {
                                    return "/cms/img/promotions/" + this.state.promotion.promotion + "/" + data.fileName;
                                  }}
                                  type={'PROMOTION'}
                                  enableCopyUrl={true}
                                  showAlert={this.props.showAlert}
                                  owner={JSON.stringify({ promotion: this.state.promotion.promotion })}
                                />


                              </div>
                            </div>
                          </div>

                        </div>

                        <div className="tab-pane fade" id="tags" role="tagspanel" aria-labelledby="tags-tab">
                          <div className="row">
                            <div className="col-12  ">
                              <div className="Border_Label">

                                <BasicSelect
                                  idProperty='tag'
                                  data='tags'
                                  basicInfoName='PromotionsTags'
                                  value={(this.state.promotion.tags || []).map((op) => {
                                    return op.tag
                                  })}
                                  name='tags'
                                  label={t('label')}
                                  onChange={this.onTagsChange}
                                  multiple={true}
                                  noEmptyValue={false}
                                  disableClearable={false}
                                />
                                {/* <Autocomplete

                                  multiple={true}
                                  options={this.state.promotion_tags}
                                  getOptionLabel={(option) => option.name}
                                  value={this.state.promotion.tags}
                                  onChange={this.onTagsChange}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}

                                      label={t("Tags")}
                                    />
                                  )}
                                /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="logs" role="logspanel" aria-labelledby="logs-tab">
                          <div className="row">
                            <div className="col-12  ">
                              <div className="Border_Label">
                                <Report windowRef="windowRefPromotionLogs" reportId="promotions_logs" viewFilter="promotions_logs" limit="3"></Report>
                                <Report windowRef="windowRefPromotionCodesLogs" reportId="promotions_codes_logs" viewFilter="promotions_codes_logs" limit="10"></Report>
                                <Report windowRef="windowRefPromotionGroupsLogs" reportId="promotions_groups_logs" viewFilter="promotions_groups_logs" limit="10"></Report>
                                <Report windowRef="windowRefPromotionPromotionsLogs" reportId="promotions_tags_promotions_logs" viewFilter="promotions_tags_promotions_logs" limit="10"></Report>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="errorMessage" id="loginErrorMsg">{this.props.errorMessage}</div>

                  </div>

                </div>





              </div>
            </div>

            <div className="popup d-none" id="popup">
              <div className="content">
                <div className="card mt-3">
                  <div className="card-header">
                    {this.state.popupTitle}
                  </div>
                  <div className="card-body m-0">
                    {this.state.popup}
                  </div>
                </div>
              </div>
            </div>


            <Alert isOpen={this.state.showModal} title={this.state.errorTitle} content={this.state.errorContent} close={this.handleCloseModal} />


          </div>
        </div >

      );
    } else {
      return (<></>);
    }
  }

}

export default withTranslation()(withRouter(MarketingPromotionDetails))
