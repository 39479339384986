import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import Tab from '../../components/basic/BasicTabs';
import BasicSelect from '../../components/basic/BasicSelect';
import Report from '../../components/basic/Report';
import { withRouter } from 'react-router';
import moment from 'moment';
import AgentsMenu from '../../components/AgentsMenu';
import { SendAndRetrieve, Password } from '../../components/agents/componentsAgents';


function SubagentDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const user = JSON.parse(utils.getSessionItem("user")).user;
    const companyConfBO = utils.getCurrentCompany().config.backoffice
    const hiddenFields = companyConfBO.agents?.agentHiddenFields || []
    const readFields = companyConfBO.agents?.agentReadFields || []
    const companyViewSelectRoles = companyConfBO?.agents?.viewSelectRoles || { "Agente": [{ "id": "Agente" }], "Vendedor": [{ "id": "Agente" }, { "id": "Vendedor" }], "Master": [{ "id": "Master" }] };


    const [rolesOptions, setRolesOptions] = useState([])



    const adminClient = new AdminClient();
    const { t } = props;


    const { formValidation: formValidationUserDetails } = useFormValidation('Form_AgentsData')
    const [agentInfo, setAgentInfo] = useState({
        user: -1,
        alias: "",
        password: "",
        status: "",
        name: "",
        email: "",
        currency: companyConfBO.base_currency
    });


    const [commissionsInfo, setCommissionsInfo] = useState({
        "BETTING": 0,
        "CASINO": 0
    })
    const [canUserAdmin, setCanUserAdmin] = useState(true)

    const [callFilter, setCallFilter] = useState({
        "AgentSubAgentesSummary": "",
        //"AgentSummary": "",
        "AgentOperations": "",
        "AgentAccounts": ""
    })
    const dataHeader = {
        title: t('Agent details'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    const initCallFilter = (user) => {
        let callfilter =
        {
            "AgentSubAgentesSummary": {
                "data": `${user};${moment(new Date().getTime()).subtract(1, 'days').format('YYYY-MM-DD')};${moment(new Date().getTime()).format('YYYY-MM-DD')};`, "filterReport": [
                    { "field": "t.summary_date", "type": "date_range", "value": [moment(new Date().getTime()).subtract(1, 'days').format('YYYY-MM-DD'), moment(new Date().getTime()).format('YYYY-MM-DD')] }
                ]
            },
            //"AgentSummary": `${resultAdmin.data.user};${moment(datesforAgentSummary[0]).format('YYYY-MM-DD')};${moment(datesforAgentSummary[1]).format('YYYY-MM-DD')}`,
            //"AgentSummary": `${resultAdmin.data.user};`,
            "AgentOperations": {
                "data": `${user};${utils.unformatDate(moment(new Date().getTime()).subtract(1, 'days').tz(utils.getCurrentCompany().config.timezone))};${utils.unformatDate(moment(new Date().getTime()).tz(utils.getCurrentCompany().config.timezone))};;;`, "filterReport": [
                    { "field": "t.operation_date", "type": "time_range", "value": [utils.unformatDate(moment(new Date().getTime()).subtract(1, 'days').tz(utils.getCurrentCompany().config.timezone)), utils.unformatDate(moment(new Date().getTime()).tz(utils.getCurrentCompany().config.timezone))] }
                ]
            },
            "AgentAccounts": { "data": `${user}`, "filterReport": [] },
            "SubAgentPlayers": { "data": `${user};;`, "filterReport": [] }
        }
        utils.setSessionItem("ReportFilter-agents_subagents", JSON.stringify(callfilter["AgentSubAgentesSummary"]["filterReport"]))
        utils.setSessionItem("ReportFilter-subagent_operations", JSON.stringify(callfilter["AgentOperations"]["filterReport"]))
        utils.setSessionItem("ReportFilter-subagents_players", JSON.stringify(callfilter["SubAgentPlayers"]["filterReport"]))
        return callfilter


    }

    useEffect(async () => {
        let path = props.location.pathname.split("/");
        let userId = path[path.length - 1];
        let defaultCommissions = await adminClient.getAgentUserCommissions(session, company, 0)

        if (userId !== undefined && userId !== '' && !isNaN(userId)) {
            adminClient.isParentFromUser(session, company, userId).then((result) => {
                adminClient.getAdminUserDetails(session, company, userId).then(async function (resultAdmin) {

                    let commissionsUser = await adminClient.getAgentUserCommissions(session, company, resultAdmin.data.user)

                    let commissions = {}
                    commissions["BETTING"] = commissionsUser.data["BETTING"] || defaultCommissions.data["BETTING"]
                    commissions["CASINO"] = commissionsUser.data["CASINO"] || defaultCommissions.data["CASINO"]

                    setCommissionsInfo(commissions)
                    setCallFilter(initCallFilter(resultAdmin.data.user))
                    //Buscamos el role dentro de los roles permitidos como roles de Agentes
                    if (resultAdmin.roles.length > 0) {
                        setRolesOptions(companyViewSelectRoles[resultAdmin.roles[0].role])
                    }
                    setAgentInfo((prevState) => ({
                        ...prevState,
                        user: resultAdmin.data.user, alias: resultAdmin.data.alias, status: resultAdmin.data.status, name: resultAdmin.data.name, email: resultAdmin.data.email, role: resultAdmin.roles[0].role, blocked_by: resultAdmin.data.blocked_by, currency: resultAdmin.data.currency
                    }));
                    utils.removeSessionItem("current-user");
                },
                    function (err) {
                        console.error(err);
                        setCanUserAdmin(false)
                    })

            }, (error) => {
                setCanUserAdmin(false)
                console.error(error);
            })
        }
    }, [])

    const onChangeHandlerCommissions = (e) => {
        const { name, value } = e.target || e;
        if (name !== undefined && value !== undefined) {
            setCommissionsInfo((prevState) => ({ ...prevState, [name]: value }));
        }
    }

    const refreshReports = () => {

        setCallFilter(initCallFilter(agentInfo.user))
        // console.log("Calling refreshReports");
        if (window["windowRefAgentAccounts"]) window["windowRefAgentAccounts"].executeReport(0);
        if (window['windowRefAgentOperations']) window['windowRefAgentOperations'].executeReport(0);
        //if (window['windowRefAgentSummary']) window['windowRefAgentSummary'].executeReport(0);
        if (window['windowRefAgentSubAgentesSummary']) window['windowRefAgentSubAgentesSummary'].executeReport(0);
        if (window["windowRefSubAgentsPlayers"]) window["windowRefSubAgentsPlayers"].executeReport(0);

    }


    const saveUser = () => {

        if (formValidationUserDetails.validate()) {
            let adminClient = new AdminClient();
            adminClient.updateAgentUser(session, company, JSON.stringify(agentInfo)).then(
                function (response) {

                    props.showAlert(t("Agents"), <p>{t("Save succesfully")}</p>)
                    setRolesOptions(companyViewSelectRoles[agentInfo.role])

                },
                function (err) {
                    console.error(err);
                    props.showAlert(t("Agents"), <p>{t("Saving error")} {t(err.description)}</p>)
                }
            )
        }

    }

    const saveCommisions = () => {

        if (formValidationUserDetails.validate()) {
            let adminClient = new AdminClient();
            adminClient.saveAgentUserCommissions(session, company, agentInfo.user, JSON.stringify(commissionsInfo)).then(
                function (response) {
                    props.showAlert(t("Agents"), <p>{t("Save succesfully")}</p>)
                },
                function (err) {
                    console.error(err);
                    props.showAlert(t("Agents"), <p>{t("Saving error")}</p>)
                }
            )
        }

    }
    const tokensRender = () => {

        return (<><SendAndRetrieve showActionType={true} agentInfo={agentInfo} finishOperation={refreshReports} />
            <Report reportCSS="min-vh-auto" windowRef="windowRefAgentAccounts" reportId="subagent_accounts" session={session} callFilter={callFilter["AgentAccounts"]["data"]} ></Report></>)
    }


    const commissionsRender = () => {
        return (
            <>
                <form id="Form_commisionsAgent">
                    <div className="row">
                        <div className="col-12 col-md-4">
                            <TextField label={t("Commission") + " BETTING"} name="BETTING" defaultValue={commissionsInfo.BETTING} onChange={onChangeHandlerCommissions} />
                        </div>
                        <div className="col-12 col-md-4">
                            <TextField label={t("Commission") + " CASINO"} name="CASINO" defaultValue={commissionsInfo.CASINO} onChange={onChangeHandlerCommissions} />
                        </div>
                    </div>
                </form>
                {utils.isGranted("AGENTS_SAVE_AGENT") &&
                    <div className="row">
                        <div className="col-12 ">
                            <button className="btn btn-primary" onClick={saveCommisions}>{t("Save")}</button>
                        </div>
                    </div>
                }
            </>
        );
    }


    // const onSubAgentsDetailed = (filterData) => {
    //     if (filterData.length > 0) setCallFilter((prevVal) => ({ ...prevVal, ['AgentSummary']: `${agentInfo.user};${filterData[0]['value'][0] || ''};${filterData[0]['value'][1] || ''}` }))
    //     else props.showAlert(t("Agents"), <p>{t("Select a Filter")}</p>)

    // }

    const onSubAgents = (filterData) => {

        let filters = [5]
        filters[0] = agentInfo.user
        filters[1] = ''
        filters[2] = ''
        filters[3] = ''

        if (filterData.length > 0) {
            filterData.forEach(element => {
                if (element.field === 't.summary_date' && element.value.length === 2 && element.value[0] !== '' && element.value[1] !== '') {
                    filters[1] = element.value[0]
                    filters[2] = element.value[1]
                }
                if (element.field === 't.alias' && element.value !== '') filters[3] = element.value
            });
        }
        let agentsSubAgentsCallFilter = {
            "data": filters.join(';'),
            "filterReport": [
                { "field": "t.alias", "operator": "=", "value": filters[3] },
                { "field": "t.summary_date", "type": "date_range", "value": [filters[1], filters[2]] }
            ]
        }
        utils.setSessionItem("ReportFilter-agents_subagents", JSON.stringify(agentsSubAgentsCallFilter["filterReport"]))
        setCallFilter((prevVal) => ({ ...prevVal, ['AgentSubAgentesSummary']: agentsSubAgentsCallFilter }))

    }

    const subAgentsRender = () => {
        //utils.setSessionItem("ReportFilter-agents_subagents", JSON.stringify(callFilter["AgentSubAgentesSummary"]["filterReport"]))
        return (<>
            <Report title=" " className="no-min-height mb-4" windowRef="windowRefAgentSubAgentesSummary" filterId="agents_subagents" reportId="agents_subagents" session={session} callFilter={callFilter["AgentSubAgentesSummary"]["data"]} onHandlerCallFilter={onSubAgents}></Report>
        </>)
    }
    // const ggrSummaryRender = () => {
    //     return (<>
    //         <Report title=" " className="no-min-height mb-4" windowRef="windowRefAgentSummary" reportId="subagents_detailed_summary" session={session} callFilter={callFilter["AgentSummary"]} onHandlerCallFilter={onSubAgentsDetailed}></Report>
    //     </>)
    // }


    const onSubAgentsPlayers = (filterData) => {


        let filters = [3]
        filters[0] = agentInfo.user
        filters[1] = ''
        filters[2] = ''
        if (filterData.length > 0) {
            filterData.forEach(element => {
                if (element.field === 't.user' && element.value !== '') filters[1] = element.value
                if (element.field === 't.alias' && element.value !== '') filters[2] = element.value
            });
        }
        let agentsSubAgentPlayersCallFilter = {
            "data": filters.join(';'),
            "filterReport": [
                { "field": "t.alias", "operator": "=", "value": filters[2] },
                { "field": "t.user", "operator": "=", "value": filters[1] }
            ]
        }
        utils.setSessionItem("ReportFilter-subagents_players", JSON.stringify(agentsSubAgentPlayersCallFilter["filterReport"]))
        setCallFilter((prevVal) => ({ ...prevVal, ['SubAgentPlayers']: agentsSubAgentPlayersCallFilter }))
    }
    const playersRender = () => {
        //utils.setSessionItem("ReportFilter-subagents_players", JSON.stringify(callFilter["SubAgentPlayers"]["filterReport"]))
        return (<>
            <Report title=" " className="no-min-height mb-4" windowRef="windowRefSubAgentsPlayers" filterId="subagents_players" reportId="subagents_players" session={session} callFilter={callFilter["SubAgentPlayers"]["data"]} onHandlerCallFilter={onSubAgentsPlayers}></Report>
        </>)
    }
    const hierarchyRender = () => {
        return (<>
            <Report reportId="agents_supagents" callFilter={agentInfo.user}></Report>
        </>)
    }

    const passwordRender = () => {
        return (<><Password agentUser={agentInfo.user} /></>)
    }

    const onSubAgentsOperations = (filterData) => {

        let filters = [5]
        filters[0] = agentInfo.user
        filters[1] = ''
        filters[2] = ''
        filters[3] = ''
        filters[4] = ''
        filters[5] = ''
        if (filterData.length > 0) {
            filterData.forEach(element => {
                if (element.field === 't.operation_date' && element.value.length === 2 && element.value[0] !== '' && element.value[1] !== '') {
                    filters[1] = element.value[0]
                    filters[2] = element.value[1]
                }
                if (element.field === 't.type' && element.value !== '') filters[3] = element.value
                if (element.field === 't.alias' && element.value !== '') filters[4] = element.value
                if (element.field === 't.player_alias' && element.value !== '') filters[5] = element.value

            });
        }
        let agentsAgentOperationsCallFilter = {
            "data": filters.join(';'),
            "filterReport": [
                { "field": "t.alias", "operator": "=", "value": filters[4] },
                { "field": "t.operation_date", "type": "time_range", "value": [filters[1], filters[2]] },
                { "field": "t.type", "operator": "=", "value": filters[3] },
                { "field": "t.player_alias", "operator": "=", "value": filters[5] }

            ]
        }
        utils.setSessionItem("ReportFilter-subagent_operations", JSON.stringify(agentsAgentOperationsCallFilter["filterReport"]))
        setCallFilter((prevVal) => ({ ...prevVal, ['AgentOperations']: agentsAgentOperationsCallFilter }))

    }


    const [tabsContent, setTabsContent] = useState([])

    useEffect(function () {

        let tabAux = [
            { title: t("Transfer Tokens"), content: tokensRender },
            {
                title: t("Tokens activity"),
                content: () => {
                    // utils.setSessionItem("ReportFilter-subagent_operations", JSON.stringify(callFilter["AgentOperations"]["filterReport"]))
                    return <Report windowRef="windowRefAgentOperations" filterId="subagent_operations" reportId="subagent_operations" session={session} callFilter={callFilter["AgentOperations"]["data"]} onHandlerCallFilter={onSubAgentsOperations}></Report>
                }
            },
            { title: t(t("SubAgents of")), content: subAgentsRender },
            // { title: t("GGR Summary"), content: ggrSummaryRender },
            { title: t("Commissions"), content: commissionsRender },
            { title: t("Hierarchy"), content: hierarchyRender },
            { title: t("Players"), content: playersRender },
            { title: t("Password update"), content: passwordRender }
        ]
        setTabsContent(tabAux);
    }, [JSON.stringify(callFilter), JSON.stringify(agentInfo), JSON.stringify(commissionsInfo)]);


    const onChangeHandlerUser = (e) => {
        const { name, value } = e.target || e;
        setAgentInfo((prevUserDetails) => {
            return { ...prevUserDetails, [name]: value }
        });
    }

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>
                {canUserAdmin && <>
                    <form id="Form_AgentsData">
                        <div className="row">
                            <div className="col-12 col-md-3">
                                <TextField
                                    label={t('Identifier')}
                                    name="user"
                                    required
                                    disabled
                                    type="text"
                                    value={agentInfo.user}
                                />
                            </div>
                            <div className="col-12 col-md-3">
                                <TextField
                                    label={t("Alias")}
                                    name="alias"
                                    required
                                    disabled
                                    type="text"
                                    value={agentInfo.alias}
                                    onChange={onChangeHandlerUser}
                                />
                            </div>
                            <div className="col-12  col-md-3  ">
                                <BasicSelect
                                    idProperty='name'
                                    basicInfoName='EnabledDisabled'
                                    disabled={readFields.includes('status')}
                                    value={agentInfo.status}
                                    name='status'
                                    label={t('Status')}
                                    onChange={onChangeHandlerUser}
                                    translate={{ property: 'name', prefix: '' }}
                                    multiple={false}
                                    noEmptyValue={false}
                                    disableClearable={true}
                                />
                            </div>
                            <div className="col-12  col-md-3  ">
                                <BasicSelect
                                    value={agentInfo.role}
                                    label={t('Role')}
                                    name="role"
                                    nameProperty="id"
                                    disabled={readFields.includes('role')}
                                    addOpts={rolesOptions}
                                    onChange={(evt) => {
                                        onChangeHandlerUser(evt)
                                    }}
                                    disableClearable
                                />
                            </div>
                            {!hiddenFields.includes('name') &&
                                <div className="col-12  col-md-4  ">
                                    <TextField
                                        label={t("Name")}
                                        name="name"
                                        type="text"
                                        onChange={onChangeHandlerUser}
                                        required
                                        value={agentInfo.name}
                                    />
                                </div>}
                            {!hiddenFields.includes('email') &&
                                <div className="col-12  col-md-4  ">
                                    <TextField
                                        label={t("Email")}
                                        type="text"
                                        name="email"
                                        onChange={onChangeHandlerUser}
                                        required
                                        value={agentInfo.email}
                                    />
                                </div>}
                            {!hiddenFields.includes('currency') &&
                                <div className="col-12  col-md-4 ">
                                    <BasicSelect
                                        idProperty='currency'
                                        nameProperty='currency'
                                        data='currencies'
                                        basicInfoName='CurrenciesCompany'
                                        value={agentInfo.currency}
                                        name='currency'
                                        label={t('Currency')}
                                        onChange={onChangeHandlerUser}
                                        disableClearable
                                    />
                                </div>}





                        </div>

                    </form>
                    {utils.isGranted("AGENTS_SAVE_AGENT") &&
                        <div className="row">
                            <div className="col-12">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={saveUser}                    >
                                    {t('Save')}
                                </button>

                            </div>
                        </div>}
                    {agentInfo.user !== -1 && agentInfo.user !== 0 && <>
                        <Tab active={0} renderAll={false}>
                            {
                                tabsContent.map((tab, idx) =>
                                    <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                                        {tab.content()}
                                    </Tab.TabPane>
                                )
                            }
                        </Tab>
                    </>}
                </>}
                {!canUserAdmin && <div className='row'>
                    {t('Not privileges to edit this user')}
                </div>}


            </LayoutSection>
            <AgentsMenu></AgentsMenu>
        </>
    )
}
export default withTranslation()(withRouter(SubagentDetails));