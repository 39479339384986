import React, { useState, useEffect, useCallback } from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';


const AgentsTree = (props) => {
    /**
     * Estructura minima de un nodo, user,alias,hasChildren, si no viene siempre intentará llamar al cargar nodos. 
     */

    const [treeData, setTreeData] = useState([]);
    const [flattenedData, setFlattenedData] = useState([]);
    const [isLoadingRoot, setIsLoadingRoot] = useState(false)
    const [hasRootMore, setHasRootMore] = useState(true)
    const [isLoadingChild, setIsLoadingChild] = useState({})
    const [hasChildMore, setHasChildMore] = useState({})

    useEffect(() => {
        setIsLoadingChild({})
        setIsLoadingRoot(false)
        setHasRootMore(true)
        setHasChildMore({})
        setTreeData(props.data)
        setFlattenedData(flattenOpened(props.data));
    }, [props.data]);

    const flattenOpened = (treeData) => {
        const result = [];
        for (let node of treeData) {
            flattenNode(node, node.depth, result);
        }
        return result;
    };

    // Función para buscar un nodo en el TreeData
    const findNodeByParentCollapsed = (treeData, parentCollapsed) => {
        const ids = parentCollapsed.split("___");

        // Primero, recorremos `treeData` para encontrar el nodo raíz (primer nivel)
        for (let rootNode of treeData) {
            if (rootNode.user == ids[0]) {
                // Si el nodo raíz coincide con el primer ID, empezamos la búsqueda recursiva
                ids.shift(); // Eliminamos el primer ID ya que estamos en el primer nivel
                return findInNode(rootNode, ids);
            }
        }

        // Si no encontramos el nodo raíz correspondiente, devolvemos null
        return null;
    }

    // Función recursiva para encontrar el nodo específico
    const findInNode = (node, ids) => {
        // Si no hay más IDs, hemos encontrado el nodo
        if (ids.length === 0) {
            return node;
        }

        // Extraemos el siguiente ID y buscamos en los hijos
        const currentId = ids.shift();

        if (node.children && node.children.length > 0) {
            for (let child of node.children) {
                if (child.user == currentId) {
                    // Llamada recursiva con el siguiente nivel de IDs
                    return findInNode(child, ids);
                }
            }
        }

        // Si no encontramos el nodo en esta rama, devolvemos null
        return null;
    }

    const flattenChilds = (parentNode, collapsed, children, depth, result, indexCount) => {

        if (!collapsed && children) {
            let index = indexCount
            for (let child of children) {
                child.parent = parentNode.user
                child.parentCollapsed = parentNode.parentCollapsed + "___" + child.user
                flattenNode(child, depth + 1, result, index);
                if (index !== undefined) index++;
            }
        }
    }


    const flattenNode = (node, depth, result, index) => {
        const { user, alias, children, hasChildren, selected, collapsed, totalChildren, parent, numChild, parentCollapsed } = node;

        let additionalData = {}
        if (props.getAdditionalNodeData !== undefined) additionalData = props.getAdditionalNodeData(node)

        result.push({
            ...additionalData,
            user,
            alias,
            children,
            hasChildren: hasChildren === undefined ? true : hasChildren,
            totalChildren: totalChildren || 0,
            numChild: index || numChild,
            depth,
            collapsed: collapsed === undefined ? true : collapsed,
            selected: selected || 'none',
            parent: parent,
            parentCollapsed: parentCollapsed || `${user}`
        });
        flattenChilds(node, collapsed, children, depth, result, 1)
    };

    const onOpen = async node => {

        //Si el nodo esta cerrado, comprobamos si tiene hijos 
        if (node.collapsed) {
            if (node.hasChildren && (node.children === undefined || node.children === null)) {
                let newChildNodes = await props.fetchMoreChildNodes(node.user, 0)
                if (newChildNodes.length === 0) {
                    node.hasChildren = false
                    setHasChildMore((prev) => {
                        return { ...prev, [node.user]: false }
                    })
                }
                else {

                    let targetNode = findNodeByParentCollapsed(treeData, node.parentCollapsed)
                    targetNode['children'] = [...newChildNodes]
                    targetNode['totalChildren'] = newChildNodes.length
                    targetNode['parent'] = node.user
                    targetNode['collapsed'] = false
                    targetNode['parentCollapsed'] = node.parentCollapsed || `${node.user}`
                    setHasChildMore((prev) => {
                        return { ...prev, [node.user]: true }
                    })

                    let fopened = flattenOpened(treeData)
                    setFlattenedData(fopened);

                }
            } else {

                let targetNode = findNodeByParentCollapsed(treeData, node.parentCollapsed)
                targetNode.collapsed = false
                let fopened = flattenOpened(treeData)
                setFlattenedData(fopened);


            }
        } else {

            let targetNode = findNodeByParentCollapsed(treeData, node.parentCollapsed)
            targetNode.collapsed = true
            let fopened = flattenOpened(treeData)
            setFlattenedData(fopened);

        }
    };

    const setSelectedNode = (node) => {
        if (props.setSelectedNode !== undefined) {
            props.setSelectedNode(node)
        }
    }

    const selectNode = (nodeId) => {

        let targetNode = findNodeByParentCollapsed(treeData, nodeId)
        targetNode['selected'] = 'node'

        let fopened = flattenOpened(treeData)
        setFlattenedData(fopened);
        setSelectedNode(targetNode);
    };

    const selectNodeAndChildren = (nodeId, selectionType) => {

        let targetNode = findNodeByParentCollapsed(treeData, nodeId)
        targetNode['selected'] = selectionType
        targetNode['collapsed'] = true
        targetNode['children'] = null
        targetNode['totalChildren'] = 0


        let fopened = flattenOpened(treeData)
        setFlattenedData(fopened);
        setSelectedNode(targetNode)

    };

    const clearSelection = (nodeId) => {

        let targetNode = findNodeByParentCollapsed(treeData, nodeId)
        targetNode['selected'] = 'none'
        let fopened = flattenOpened(treeData)
        setFlattenedData(fopened);
        setSelectedNode(targetNode)

    };

    const onSelect = (e, node, choice) => {

        if (choice === 1) {
            selectNode(node.parentCollapsed);
        } else if (choice === 2) {
            selectNodeAndChildren(node.parentCollapsed, 'children');
        } else if (choice === 3) {
            selectNodeAndChildren(node.parentCollapsed, 'nodechildren');
        } else if (choice === 4) {
            clearSelection(node.parentCollapsed);
        }

    };

    const updateNode = async (index, node) => {
        console.log('Actualizando Amount del Nodo')
        let targetNode = findNodeByParentCollapsed(treeData, node.parentCollapsed)

        Object.assign(targetNode, node);

        let fopened = flattenOpened(treeData)
        setFlattenedData(fopened);

    }

    const loadMoreNodes = useCallback(async (dataMore) => {
        const { visibleStopIndex } = dataMore
        const lastNode = flattenedData[visibleStopIndex];
        //Comprobamos si es un nodo hijo o un padre
        if (lastNode.depth === 1 && !isLoadingRoot && visibleStopIndex >= flattenedData.length - 5) {

            setIsLoadingRoot(true)
            //Comprobamos si ya no hay que cargar mas nodos root
            if (hasRootMore) {
                const newRootNodes = await props.fetchMoreRootNodes();
                if (newRootNodes.length > 0) {

                    let newTreeData = [...treeData, ...newRootNodes]
                    setTreeData(newTreeData)
                    let fopened = flattenOpened(newTreeData)
                    setFlattenedData(fopened);
                } else setHasRootMore(false)
                setIsLoadingRoot(false)
            }
        } else if (lastNode.depth !== 1 && !isLoadingChild[lastNode.parent]) {


            if (hasChildMore[lastNode.parent]) {

                // //Buscamos el indice de ese hijo en el padre, 
                // //Buscamos el nodo padre que es visualizado
                const parentNodeFlat = flattenedData.find(objeto => objeto.user === lastNode.parent);
                console.log('Soy el hijo ' + lastNode.numChild + ' de ' + parentNodeFlat.totalChildren)

                if (lastNode.numChild >= parentNodeFlat.totalChildren - 10) {
                    setIsLoadingChild((prev) => { return { ...prev, [lastNode.parent]: true } })
                    console.log('Buscar mas nodos hijo ...' + lastNode.parent)
                    const newChildNodes = await props.fetchMoreChildNodes(lastNode.parent);
                    //     console.log('Tiene ' + newChildNodes.length + 'hijos')
                    //     console.log('Añadir los hijos al final de ' + indexOfLastNode)

                    if (newChildNodes.length > 0) {
                        //Obtenemos el nodo padre real en el arbol, y le añadimos los children 
                        let parentNodeInTree = findNodeByParentCollapsed(treeData, parentNodeFlat.parentCollapsed)
                        parentNodeInTree.children = [...parentNodeInTree.children, ...newChildNodes]
                        parentNodeInTree.totalChildren = parentNodeInTree.totalChildren + newChildNodes.length
                        let fopened = flattenOpened(treeData)
                        setFlattenedData(fopened);
                    } else {
                        setHasChildMore((prev) => { return { ...prev, [lastNode.parent]: false } })
                    }
                }
                setIsLoadingChild((prev) => { return { ...prev, [lastNode.parent]: false } })

            }
        }

    }, [flattenedData, treeData, isLoadingRoot, hasRootMore, isLoadingChild, hasChildMore]);

    const itemData = props.getItemData(onOpen, onSelect, flattenedData, updateNode, props.resources);


    return (
        <AutoSizer>
            {({ height, width }) => (
                <List
                    className="List"
                    height={height}
                    itemCount={flattenedData.length}
                    itemSize={props.itemSize || 35}
                    width={width}
                    itemKey={index => flattenedData[index].user}
                    itemData={itemData}
                    onItemsRendered={loadMoreNodes}
                >
                    {props.row}
                </List>
            )}
        </AutoSizer>
    );
};

export default AgentsTree