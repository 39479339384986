import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import DateAndTimePicker from '../../components/DateAndTimePicker';
import Tab from '../../components/basic/BasicTabs';
import BasicTable from '../../components/basic/BasicTable';
import TournamentPrize from '../../components/TournamentPrize';
import AllowedForbiddenList from '../../components/basic/AllowedForbiddenList';
import TreeSelector from '../../components/basic/TreeSelector';
import Report from '../../components/basic/Report';
import WebContentData from '../../components/basic/WebContentData';
import Editor from '../../components/basic/Editor';
import { loadBasicInfo, getTranslateBasicInfo, getBasicInfo } from '../../libs/utilsBasicInfo';

const DuplicateTournamentAlert = (props) => {

    const { t, showAlert, handleCloseAlert } = props;
    const adminClient = new AdminClient();
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCompany(company);
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const [modalState, setModalState] = useState({
        duplicateTournament: props.lastId + " - NEW",
        duplicateName: ""
    });

    const onChangeHandler = (event) => {
        setModalState((prevState) => ({
            ...prevState,
            [event.target.name]: event.target.value
        }));
    };

    const handleDuplicateClick = async () => {
        let response = await adminClient.tournamentExists(session, company, modalState.duplicateTournament);
        if (response.exists === 1) {
            handleCloseAlert();
            showAlert(t("Duplicate tournament"), <p>{t("Tournament ID exists")}</p>);
        } else {
            props.duplicate(modalState)

            handleCloseAlert();
        }
    };

    return (
        <>
            <TextField
                id="new_tournament"
                key="new_tournament"
                label={t("New Identifier")}
                value={modalState.duplicateTournament}
                name="duplicateTournament"
                onChange={onChangeHandler}
            />
            <TextField
                id="new_tournament_name"
                key="new_tournament_name"
                label={t("New name")}
                value={modalState.duplicateName}
                name="duplicateName"
                onChange={onChangeHandler}
            />
            <button type="button" className="btn btn-primary" onClick={handleDuplicateClick}>
                {t("Duplicate")}
            </button>
        </>
    );
};

function TournamentDetails(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = utils.getCompany(company);
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_TournamentDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    let tournamentId = utils.getSessionItem('current-tournament')
    let urlName = props.location.pathname.split('/')
    if (urlName.length > 4) tournamentId = urlName[urlName.length - 1]
    const langs = companyDetails.langs.split(",")
    const [tabsContent, setTabsContent] = useState([]);
    const [oneTime, setOneTime] = useState(false);

    const statusOptions = [
        { value: 'ENABLED', label: t("TOURNAMENT_STATUS_ENABLED") },
        { value: 'DISABLED', label: t("TOURNAMENT_STATUS_DISABLED") },
        { value: 'FINISHED', label: t("TOURNAMENT_STATUS_FINISHED") }
    ];

    const acceptanceTypes = [
        { value: 'TOURNAMENT_DATE', label: t("TOURNAMENT_ACCEPTANCE_TYPE_TOURNAMENT_DATE") },
        { value: 'ACCEPTANCE_DATE', label: t("TOURNAMENT_ACCEPTANCE_TYPE_ACCEPTANCE_DATE") }
    ]

    const cmsInit = langs.length === 1
        ? {
            summary_title: "",
            summary_body: "",
            summary_image: "",
            title: "",
            body: "",
            image: "",
        }
        : langs.reduce((result, lang) => {
            result[lang] = {
                summary_title: "",
                summary_body: "",
                summary_image: "",
                title: "",
                body: "",
                image: "",
            };
            return result;
        }, {});

    const [tournamentDetails, setTournamentDetails] = useState({
        tournament: {
            name: "",
            status: statusOptions[0].value,
            type: "",
            typeConfig: {},
            prize_type: "",
            tournament: tournamentId === "-1" ? "" : tournamentId,
            company: utils.getSessionItem("Company"),
            cms: cmsInit,
            machines: [],
            machines_availables: [],
            groups: [],
            groups_availables: [],
            min_amount: 0,
            min_rounds: 0,
            end_date: utils.getDateTZTodayObj().getTime(),
            init_date: utils.getDateTZTodayObj().getTime(),
            acceptance_type: "NONE",
            points_multiplier: 0
        },
        machines: [],
        machines_availables: [],
        base_availables: [],
        tournamentPrizes: [],
        groups_allowed: [],
        groups_availables: [],
        groups_forbbiden: [],
        typeOptions: [],
    });

    const goBack = () => {
        props.history.push('/marketing/tournaments/tournaments');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Tournament details'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(async function () {
        let isMounted = true; // Bandera para saber si el componente sigue montado
        let tournamentTypes = await getBasicInfo('TournamentTypes');
        if (tournamentId !== undefined && tournamentId !== '-1') {
            let filter = [{ "field": "t.tournament", "operator": "=", "value": tournamentId }];
            utils.setSessionItem("ReportFilter-tournament-details", JSON.stringify(filter));
            utils.setSessionItem("ReportFilterView-tournament-details", JSON.stringify([]));

            Promise.all([
                adminClient.getTournamentDetails(session, company, tournamentId),
            ]
            ).then(
                async function (result) {
                    setOneTime(true)
                    setAddOrUpdate("update")
                    let tournamentAux = {}
                    let tournament = result[0].data.tournament;
                    let terms = result[0].data.tournament.cms.terms;


                    utils.setSessionItem("current-tournament", tournament.tournament)

                    tournament.init_date = utils.formatDate(tournament.init_date);
                    tournament.end_date = utils.formatDate(tournament.end_date);
                    if (tournament.points_multiplier === undefined || tournament.points_multiplier === null) {
                        tournament.points_multiplier = 0
                    }
                    let groups_allowed = [];
                    let groups_forbbiden = [];
                    tournament.groups.forEach(element => {
                        if (element.relationship === 'ALLOWED') {
                            groups_allowed.push(element);
                        } else {
                            groups_forbbiden.push(element);
                        }

                    });

                    if (tournament.min_amount === undefined || tournament.min_amount === null) {
                        tournament.min_amount = 0;
                    }
                    if (tournament.cms === undefined) {
                        tournament.cms = tournamentDetails.tournament.cms;
                    }
                    else {
                        if (langs.length > 1) {
                            let existeClave = false;
                            let cmsParse;
                            let claves = Object.keys(tournament.cms)
                            for (const lang of langs) {
                                if (claves.includes(lang)) {
                                    existeClave = lang;
                                    break; // Puedes salir del bucle tan pronto como encuentres una coincidencia
                                }
                            }
                            if (!existeClave) {
                                cmsParse = langs.reduce((obj, key) => {
                                    obj[key] = JSON.parse(JSON.stringify(tournament.cms));
                                    // Aseguramos que no se copien propiedades globales como "terms" en cada idioma
                                    if (tournament.cms.terms) {
                                        delete obj[key].terms;
                                    }
                                    return obj;
                                }, {});
                            }
                            else {
                                cmsParse = langs.reduce((obj, key) => {
                                    if (tournament.cms[key] !== undefined) {
                                        obj[key] = tournament.cms[key]
                                    }
                                    else {
                                        obj[key] = JSON.parse(JSON.stringify(tournament.cms[existeClave]));
                                    }
                                    // Aseguramos que propiedades globales como "terms" no se copien dentro de los idiomas
                                    if (tournament.cms.terms) {
                                        delete obj[key].terms;
                                    }
                                    return obj;
                                }, {});
                            }

                            // Agregar la variable "terms" a cada idioma si está definida y no existe en el CMS actual para ese idioma
                            cmsParse = langs.reduce((obj, key) => {
                                if (obj[key].terms === undefined && terms !== undefined) {
                                    obj[key].terms = terms;
                                } else if (obj[key].terms === undefined && terms == undefined) {
                                    obj[key].terms = ' ';
                                } else if (obj[key].terms !== undefined && obj[key].terms == '') {
                                    obj[key].terms = ' ';
                                }
                                return obj;
                            }, cmsParse);

                            tournament.cms = cmsParse
                        }
                    }

                    let allMachines = result[0].data.machines_availables.concat(tournament.machines);
                    let machinesData = {};
                    allMachines.forEach(element => {
                        machinesData[element.machine] = element;
                    });

                    let machinesChecked = [];
                    if (tournament.machines !== undefined && tournament.machines !== undefined) {
                        tournament.machines.forEach(element => {
                            machinesChecked.push(element.machine);
                        });
                    }
                    tournamentAux.tournament = tournament;

                    //Obtener tournament.cms.terms aunque no este en el multidioma
                    if (langs.length == 1 && terms !== undefined) {
                        tournamentAux.tournament.cms.terms = terms
                    } else if (langs.length == 1) {
                        tournamentAux.tournament.cms.terms = ''
                    }

                    if (tournament.prizes !== undefined && tournament.prizes.length > 0) {
                        tournament.prizes.forEach(prize => {
                            prize.currency = utils.getCurrentCompany().base_currency;
                        });
                    }

                    tournamentAux.duplicateTournament = tournament.tournament + " - NEW";
                    tournamentAux.duplicateName = "";
                    tournamentAux.tournamentPrizes = tournament.prizes;
                    tournamentAux.groups_allowed = groups_allowed;
                    tournamentAux.groups_forbbiden = groups_forbbiden;
                    tournamentAux.groups_availables = result[0].data.groups_availables;
                    tournamentAux.machines = machinesChecked;
                    tournamentAux.machines_availables = machinesData;
                    try {
                        let type = tournamentTypes.find((type) => (type.type === tournamentAux.tournament.type))
                        if (type.config != undefined && type.config !== '') {
                            tournamentAux.tournament.typeConfig = JSON.parse(type.config)
                        } else {
                            tournamentAux.tournament.typeConfig = '';
                        }

                    } catch (error) {
                        console.log("error parseo Type")
                    }

                    if (isMounted) { // Solo actualiza el estado si el componente sigue montado
                        setTournamentDetails(tournamentAux)
                    }
                },
                function (err) {
                    if (isMounted) { // Solo muestra el error si el componente sigue montado
                        props.showAlert(t("Error"), t("Cannot get the tournament"))
                    }
                }

            );
        } else {
            let filter = [{ "field": "t.tournament", "operator": "=", "value": "-1" }];
            utils.setSessionItem("ReportFilter-tournament-details", JSON.stringify(filter));
            setOneTime(true)

            Promise.all([
                adminClient.getNewTournamentDetails(session, company, tournamentDetails.tournament.tournament),
            ]
            ).then(
                function (result) {
                    let machinesData = {};
                    result[0].data.machines_availables.forEach(element => {
                        machinesData[element.machine] = element;
                    });
                    setTournamentDetails((prevState) => {
                        let oldState = { ...prevState }

                        // Si hay múltiples lenguajes, recorremos cada uno para asegurar que los términos estén vacíos
                        if (langs.length > 1) {
                            langs.forEach((lang) => {
                                if (!oldState.tournament.cms[lang]) {
                                    oldState.tournament.cms[lang] = {}; // Asegurarse de que el CMS del idioma existe
                                }
                                oldState.tournament.cms[lang].terms = ' '; // Establecer los términos a vacío para cada idioma
                            });
                        } else {
                            // Si solo hay un idioma, establecer directamente los términos vacíos
                            oldState.tournament.cms.terms = ' ';
                        }
                        try {
                            let type = tournamentTypes.find((type) => (type.type === oldState.tournament.type))
                            if (type.config != undefined && type.config !== '') {
                                oldState.tournament.typeConfig = JSON.parse(type.config)
                            } else {
                                oldState.tournament.typeConfig = '';
                            }

                        } catch (error) {
                            console.log("error parseo Type")
                        }
                        oldState.groups_availables = result[0].data.groups_availables
                        oldState.machines_availables = machinesData
                        return oldState
                    });

                },
                function (err) {
                    console.error(err);
                }

            );

            setAddOrUpdate('add');
        }
    }, []);


    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setTournamentDetails((prevState) => ({ ...prevState, [name]: value }));
    };
    const onChangeHandlerTournament = (e) => {
        const { name, value } = e.target || e;
        let processedValue = value;
        if (name === "min_amount") {
            processedValue *= 100;
        }
        setTournamentDetails((prevState) => ({
            ...prevState,
            tournament: {
                ...prevState.tournament,
                [name]: processedValue,
            },
        }));
    };

    const onChangeHandlerTournamentType = (e) => {
        const { name, value, objSelection } = e.target || e;
        if (objSelection !== undefined && objSelection.config != '') {
            setTournamentDetails((prevState) => ({
                ...prevState,
                tournament: {
                    ...prevState.tournament,
                    [name]: value,
                    typeConfig: JSON.parse(objSelection.config)
                },
            }));
        } else {
            setTournamentDetails((prevState) => ({
                ...prevState,
                tournament: {
                    ...prevState.tournament,
                    [name]: value,
                    typeConfig: { currency: false }
                },
            }));
        }
    };

    const onChangeHandlerCMS = (value) => {
        setTournamentDetails((prevState) => {
            let oldState = { ...prevState }
            value.terms = oldState.tournament.cms.terms;
            oldState.tournament.cms = value
            return oldState
        });
    };


    const save = async (evt) => {
        if (formValidation.validate()) {

            let tournament = JSON.parse(JSON.stringify(tournamentDetails.tournament));

            tournament.groups_allowed = tournamentDetails.groups_allowed;
            tournament.groups_forbbiden = tournamentDetails.groups_forbbiden;
            tournament.prizes = tournamentDetails.tournamentPrizes;
            tournament.machines = tournamentDetails.machines;

            console.log("tournament.init_date = " + tournament.init_date);
            tournament.init_date = utils.unformatDate(new Date(tournament.init_date));

            console.log("tournament.init_date = " + tournament.init_date);
            tournament.end_date = utils.unformatDate(new Date(tournament.end_date));

            let error = false;
            let errorContent = <></>;

            if (tournament.tournament.trim().length === 0) {
                error = true;
                errorContent = <><p>{t("Tournament ID can not be empty")}</p></>
            }
            if (addOrUpdate === "add") {
                let response = await adminClient.tournamentExists(session, company, tournamentDetails.tournament.tournament);

                if (response.exists === 1) {
                    error = true;
                    errorContent = <>{errorContent}<p>{t("Tournament ID exists")}</p></>
                }
            }
            if (tournament.name.trim().length === 0) {
                error = true;
                errorContent = <>{errorContent}<p>{t("Tournament name can not be empty")}</p></>
            }
            if (tournament.prizes === undefined || tournament.prizes.length === 0) {
                error = true;
                errorContent = <>{errorContent}<p>{t("You have to configure at least one prize")}</p></>
            }

            if (tournament.groups_allowed === undefined || tournament.groups_allowed.length === 0) {
                error = true;
                errorContent = <>{errorContent}<p>{t("You have to configure at least allowed group")}</p></>
            }

            if (error) {
                props.showAlert(t('Tournament details'), errorContent)
            } else {
                const replacer = (key, value) => {
                    if (key === 'name') {
                        return value.replaceAll("&", ".");
                    } else {
                        return value;
                    }
                }
                //var data = JSON.stringify(tournament, replacer).replace(/&/g, "<amp>");
                var data = JSON.stringify(tournament);
                adminClient.saveTournament(session, data).then(
                    function (msg) {
                        if (msg.result === 'OK') {
                            props.showAlert(t('Tournament details'), t('Tournament saved ok'))
                        } else {
                            props.showAlert(t('Tournament details'), <p>{t("Tournament saved error explanation") + " : " + msg.description}</p>)
                        }
                    }, function (err) {
                        props.showAlert(t('Tournament details'), <p>{t("Tournament saved error explanation") + " : " + err.description}</p>)
                    });
            }
        }
    }

    const duplicateHandle = (data) => {
        setTournamentDetails((prevState) => {
            let oldState = { ...prevState }
            oldState.tournament.tournament = data.duplicateTournament;
            oldState.tournament.name = data.duplicateName === undefined ? "new name" : data.duplicateName;
            return oldState
        });
    }
    const duplicate = (evt) => {
        props.showAlert(t("Duplicate tournament"),
            <DuplicateTournamentAlert
                {...props}
                lastId={tournamentDetails.tournament.tournament}
                duplicate={duplicateHandle}
            />
        );
        window.scrollTo(0, 0);


    }
    const prizesRender = () => {

        const handlePrizeSave = (prize) => {
            setTournamentDetails((prevState) => {
                let oldState = { ...prevState };

                // Encontrar el índice del premio que quieres modificar (puedes usar una clave única como id)
                const prizeIndex = oldState.tournamentPrizes.findIndex((p) => p.from_position === prize.from_position);

                if (prizeIndex !== -1) {
                    // Actualizar los valores del premio existente
                    oldState.tournamentPrizes[prizeIndex] = {
                        ...oldState.tournamentPrizes[prizeIndex], // Mantener las propiedades existentes
                        prize_amount: prize.prize_amount ?? (prize.prize_amount || 0),
                        from_position: prize.from_position,
                        to_position: prize.to_position,
                        prize_promotion: prize.prize_promotion,
                        prize_membership: prize.prize_membership,
                        currency: prize.currency ?? (oldState.tournamentPrizes[prizeIndex].currency || utils.getCurrentCompany().base_currency),

                    };
                } else {
                    console.error("El premio no existe y no se puede modificar.");
                }

                return oldState;
            });
            props.handleCloseAlert();
            console.log("Prizes  : " + JSON.stringify(tournamentDetails.tournamentPrizes));
        }
        const handlePrizeDeleteNew = (prize) => {

            adminClient.deleteTournamentPrize(session, JSON.stringify(prize));
            setTournamentDetails((prevState) => {
                let oldState = { ...prevState }
                let found = -1;
                for (let i = 0; i < oldState.tournamentPrizes.length; i++) {
                    let element = oldState.tournamentPrizes[i];
                    if (element.from_position === prize.from_position) {
                        found = i;
                        break;
                    }
                };
                if (found !== -1) {
                    oldState.tournamentPrizes.splice(found, 1);
                }
                return oldState
            });

            props.handleCloseAlert();
        }

        const handlePrizeSaveNew = (prize) => {
            setTournamentDetails((prevState) => {
                let oldState = { ...prevState }
                let found = -1;
                for (let i = 0; i < oldState.tournamentPrizes.length; i++) {
                    let element = oldState.tournamentPrizes[i];
                    if (element.from_position === prize.from_position) {
                        found = i;
                        break;
                    }
                };
                if (found === -1) {
                    if (prize.prize_amount === undefined || prize.prize_amount === null) {
                        prize.prize_amount = 0
                    }
                    if (prize.currency == undefined) {
                        prize.currency = utils.getCurrentCompany().base_currency;
                    }
                    oldState.tournamentPrizes.push(prize);
                }
                return oldState
            });
            props.handleCloseAlert();

        }
        const handlePrizeDetails = (evt) => {
            let from_position = evt.original.from_position;
            let prize = {};
            for (let i = 0; i < tournamentDetails.tournamentPrizes.length; i++) {
                let element = tournamentDetails.tournamentPrizes[i];
                if (element.from_position === from_position) {
                    prize = element;
                    break;
                }

            };

            if (prize.prize_amount === null) {
                prize.prize_amount = 0
            }

            props.showAlert(t("Prize definition"),
                <TournamentPrize
                    handleSave={handlePrizeSave}
                    handleDelete={handlePrizeDeleteNew}
                    showAlert={props.showAlert}
                    closePopup={props.handleCloseAlert}
                    prize={prize}
                    isCurrency={tournamentDetails?.tournament?.typeConfig?.currency && tournamentDetails?.tournament?.prize_type == 'SHARED' || false}
                />, []);

        }

        const handleNewPrize = (evt) => {
            let prize = {};
            props.showAlert(t("Prize definition"), <TournamentPrize handleSave={handlePrizeSaveNew} isCurrency={tournamentDetails?.tournament?.typeConfig?.currency && tournamentDetails?.tournament?.prize_type == 'SHARED' || false} handleDelete={handlePrizeDeleteNew} showAlert={props.showAlert}
                closePopup={props.handleCloseAlert} prize={prize} />, []);
            window.scrollTo(0, 0);
        }

        const TournamentColumnsDef = [
            { Header: t("From"), accessor: 'from_position', typeCell: tournamentDetails?.tournament?.typeConfig?.currency && tournamentDetails?.tournament?.prize_type == 'SHARED' ? 'currency' : 'text', filter: 'text', },
            { Header: t("To"), accessor: 'to_position', typeCell: tournamentDetails?.tournament?.typeConfig?.currency && tournamentDetails?.tournament?.prize_type == 'SHARED' ? 'currency' : 'text', filter: 'text', },
            {
                Header: t("Prize Cash"), accessor: 'prize_amount', typeCell: 'text', filter: 'text',
                Cell: (props) => {
                    let prize_amount = props.row.original.prize_amount !== undefined ? props.row.original.prize_amount : 0;
                    return prize_amount / 100
                }
            },
        ]
        return (<>
            <button type="button" className="btn btn-primary" onClick={handleNewPrize}>{t("New prize")}</button>
            <BasicTable data={tournamentDetails.tournamentPrizes} columns={TournamentColumnsDef} rowHandle={handlePrizeDetails} showFilterColumn={true} />
        </>)
    }
    const groupsRender = () => {
        const updateGroups = (allowed, forbiden) => {
            setTournamentDetails((prevState) => {
                let oldState = { ...prevState }
                oldState.groups_allowed = allowed;
                oldState.groups_forbbiden = forbiden;
                return oldState
            });
        };
        return (<>
            <AllowedForbiddenList
                allowed={tournamentDetails.groups_allowed}
                availables={tournamentDetails.groups_availables}
                forbidden={tournamentDetails.groups_forbbiden}
                idProperty={"group"}
                nameProperty={"name"}
                handleUpdateGroups={updateGroups}
            ></AllowedForbiddenList>
        </>)
    }
    const machinesRender = () => {
        const updateMachines = (checked) => {
            setTournamentDetails((prevState) => ({ ...prevState, machines: checked }));
        };
        return (<>
            <TreeSelector
                label={t("Machine")}
                nodes={tournamentDetails.machines_availables}
                checked={tournamentDetails.machines}
                onCheck={updateMachines}
                typeTree="machine"
            ></TreeSelector>
        </>)
    }
    const betFilterRender = () => {
        return (<>
            <div className="row">
                <div className="col-12 col-md-6">
                    <TextField id="bet_filter_event"
                        key="bet_filter_event" label={t("Event")} name="bet_filter_event"
                        value={tournamentDetails.tournament.bet_filter_event || ''} onChange={onChangeHandlerTournament}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <TextField id="bet_filter_sport"
                        key="bet_filter_sport" label={t("Sport")} name="bet_filter_sport"
                        value={tournamentDetails.tournament.bet_filter_sport || ''} onChange={onChangeHandlerTournament}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <TextField id="bet_filter_championship"
                        key="bet_filter_championship" label={t("Championship")} name="bet_filter_championship"
                        value={tournamentDetails.tournament.bet_filter_championship || ''} onChange={onChangeHandlerTournament}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <TextField id="bet_filter_gender"
                        key="bet_filter_gener" label={t("Gender")} name="bet_filter_gender"
                        value={tournamentDetails.tournament.bet_filter_gender || ''} onChange={onChangeHandlerTournament}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <TextField id="bet_filter_min_odds"
                        key="bet_filter_gener" label={t("Min odds")} name="bet_filter_min_odds" type="number"
                        value={tournamentDetails.tournament.bet_filter_min_odds || 0} onChange={onChangeHandlerTournament}
                    />
                </div>
            </div>
        </>)
    }
    const rankingRender = () => {
        
        if(tournamentDetails.tournament.prize_type==='SHARED') {
            return (<>
                <Report key="tournament_report_shared" className="mt-3" reportId='tournament_ranking_shared' viewFilter="tournament-details" filterId="tournament-details" view="table" ></Report>
            </>)
        }
        if(tournamentDetails.tournament.prize_type==='REGULAR'){
            return (<>
                <Report key="tournament_report_regular" className="mt-3" reportId='tournament_ranking' viewFilter="tournament-details" filterId="tournament-details" view="table" ></Report>
            </>)
        }
            
        
        
    }
    const cmsRender = () => {
        return (<>
            <WebContentData
                details={tournamentDetails.tournament}
                cms={tournamentDetails.tournament.cms}
                id={tournamentDetails.tournament.tournament}
                type="TOURNAMENT"
                pathImage="/cms/img/tournaments/"
                showAlert={props.showAlert}
                onChangeHandler={onChangeHandlerCMS}
                urlImageEnable
            />
        </>)
    }
    const onChangeHandlerCMSTerms = (value) => {
        setTournamentDetails(prevState => ({
            ...prevState,
            tournament: {
                ...prevState.tournament,
                cms: {
                    ...prevState.tournament.cms,
                    terms: value.value,
                },
            },
        }));

    };

    const handleEditorChangeLang = (data, lang) => {
        if (langs.length > 1) {
            setTournamentDetails((prevState) => {
                let oldState = { ...prevState };
                oldState.tournament.cms[lang].terms = data.value;
                return { ...prevState, oldState }
            });
        }
        else {
            onChangeHandlerCMSTerms({ value: data.value })

        }
    };

    const termsRender = () => {
        return (<>
            <Tab key="tabTermsLangs" active={0}>
                {langs.map((lang, idx) =>
                    <Tab.TabPane key={`tabTermsLangs-${idx}`} tab={lang}>
                        <div className="row">
                            <div className='col-12'>
                                <Editor
                                    onChangeHandler={(data) => handleEditorChangeLang(data, lang)}
                                    info={
                                        langs.length > 1
                                            ? tournamentDetails?.tournament?.cms?.[lang]?.terms || ""
                                            : tournamentDetails?.tournament?.cms?.terms || ""
                                    }
                                    oneTime={oneTime}
                                />
                            </div>
                        </div>
                    </Tab.TabPane>
                )}
            </Tab>
        </>)
    }

    useEffect(() => {
        let tabAux = [
            ...[{ title: t("Prizes"), content: prizesRender }],
            ...[{ title: t("Groups"), content: groupsRender }],
            ...(!(companyDetails?.config?.backoffice?.tournaments?.machinesTabNotShow !== undefined &&
                companyDetails?.config?.backoffice?.tournaments?.machinesTabNotShow.includes(tournamentDetails.tournament.type))
                ? [{ title: t("Machines"), content: machinesRender }] : []),
            ...((companyDetails?.config?.backoffice?.tournaments?.betFilterTabShow !== undefined &&
                companyDetails?.config?.backoffice?.tournaments?.betFilterTabShow.includes(tournamentDetails.tournament.type))
                ? [{ title: t("Bet filter"), content: betFilterRender }] : []),
            ...(addOrUpdate === "update" ? [{ title: t("Ranking"), content: rankingRender }] : []),
            ...[{ title: t("CMS"), content: cmsRender }],
            ...[{ title: t("Terms and conditions"), content: termsRender }]
        ]
        setTabsContent(tabAux)
    }, [JSON.stringify(tournamentDetails.machines_availables), JSON.stringify(tournamentDetails.groups_availables), JSON.stringify(tournamentDetails.tournament), addOrUpdate])
    // [JSON.stringify(tournamentDetails.machines_availables), JSON.stringify(tournamentDetails.groups_availables), JSON.stringify(tournamentDetails.tournament.type), JSON.stringify(tournamentDetails.tournament.prize_type), addOrUpdate, JSON.stringify(tournamentDetails.tournament.cms.terms)])


    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_TournamentDetails">
                <div className="row">
                    <div className="col-12 col-md-6">
                        <TextField
                            label={t('ID')}
                            name="tournament"
                            required
                            disabled={addOrUpdate === 'update'}
                            // inputProps={{ "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
                            InputProps={{
                                //  inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9\\-\\_\\s]+$", "data-parsley-pattern-message": t('validationID') }
                            }}
                            type="text"
                            value={tournamentDetails.tournament.tournament}
                            onChange={onChangeHandlerTournament}
                            key="idTournament"
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <TextField
                            id="name"
                            key="nameTournament"
                            type="text"
                            label={t("Name")}
                            name="name"
                            InputProps={{
                                //inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9\\-\\_\\s]+$", "data-parsley-pattern-message": t('validationName') }
                            }}
                            value={tournamentDetails.tournament.name}
                            onChange={onChangeHandlerTournament}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <BasicSelect
                            idProperty='value'
                            nameProperty='label'
                            value={tournamentDetails.tournament.status}
                            label={t('Status')}
                            name="status"
                            onChange={onChangeHandlerTournament}
                            addOpts={statusOptions}
                            translate={{ property: 'value', prefix: 'TOURNAMENT_STATUS_' }}
                            multiple={false}
                            noEmptyValue={false}
                            disableClearable={false}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <BasicSelect
                            idProperty='type'
                            value={tournamentDetails.tournament.type}
                            label={t('Criterio')}
                            name="type"
                            onChange={onChangeHandlerTournamentType}
                            basicInfoName='TournamentTypes'
                            translate={{ property: 'name', prefix: '' }}
                            multiple={false}
                            noEmptyValue={true}
                            disableClearable={true}
                        />

                    </div>
                    <div className="col-12 col-md-6">
                        <BasicSelect
                            idProperty='prizeType'
                            value={tournamentDetails.tournament.prize_type}
                            label={t('Type')}
                            name="prize_type"
                            onChange={onChangeHandlerTournament}
                            basicInfoName='TournamentsPrizeTypes'
                            translate={{ property: 'name', prefix: '' }}
                            multiple={false}
                            noEmptyValue={true}
                            disableClearable={true}
                        />

                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <DateAndTimePicker
                            label={t("Init Date")}
                            value={tournamentDetails.tournament.init_date}
                            showTime={true}
                            name={"init_date"}
                            callBackFunction={(date) => { onChangeHandlerTournament({ name: "init_date", value: new Date(date) }) }}
                            id={"init_date"}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <DateAndTimePicker
                            label={t("End Date")}
                            value={tournamentDetails.tournament.end_date}
                            showTime={true}
                            callBackFunction={(date) => { onChangeHandlerTournament({ name: "end_date", value: new Date(date) }) }}
                            validations={
                                {
                                    "data-parsley-validate-date-range": "init_date",
                                    "data-parsley-validate-date-range-message":
                                        t("PARSLEY_VALIDATION_DATE_RANGE")
                                }
                            }
                        />
                    </div>
                </div>
                <div className="row">

                    <div className="col-12 col-md-6">
                        <TextField required key="minAmountTournament" label={t("Min round amount")} name="min_amount" type="number" value={tournamentDetails.tournament.min_amount / 100} onChange={onChangeHandlerTournament} />
                    </div>

                    <div className="col-12 col-md-6">
                        <TextField required key="minRoundsTournament" label={t("Min rounds")} name="min_rounds" type="number" value={tournamentDetails.tournament.min_rounds} onChange={onChangeHandlerTournament} />
                    </div>
                    <div className="col-12 col-md-6">

                        <BasicSelect
                            idProperty='value'
                            nameProperty='label'
                            value={tournamentDetails.tournament.acceptance_type}
                            name='acceptance_type'
                            label={t('Acceptance Type')}
                            onChange={onChangeHandlerTournament}
                            addOpts={acceptanceTypes}
                            translate={{ property: 'label', prefix: '' }}
                            multiple={false}
                            required
                            noEmptyValue={false}
                            disableClearable={false}
                        />
                    </div>
                    <div className="col-12 col-md-6">
                        <TextField key="pointsMultiplierTournamnet" label={t("Points Multiplier")} name="points_multiplier" type="number" value={tournamentDetails.tournament.points_multiplier} onChange={onChangeHandlerTournament} />
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={save}>{t("Save")}</button>
                    <button type="button" className="btn btn-secondary" onClick={duplicate}>{t("Duplicate")}</button>
                </div>
            </div>
            <Tab active={0}>
                {
                    tabsContent.map((tab, idx) =>
                        <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                            {tab.content()}
                        </Tab.TabPane>
                    )
                }
            </Tab>
        </LayoutSection>
    )
}
export default withTranslation()(TournamentDetails);