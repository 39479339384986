import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import Tab from '../../components/basic/BasicTabs';
import CMS from '../../components/basic/CMS';
import BasicTable from '../../components/basic/BasicTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import DateAndTimePicker from '../../components/DateAndTimePicker';

function TermsConditionsVersionDetails(props) {
    const session = JSON.parse(utils.getSessionItem('user')).session;

    const company = utils.getSessionItem("Company");
    const companyDetails = utils.getCompany(company);
    const langs = companyDetails.langs.split(",");
    const t = props.t;
    const termsConditions = props.termsConditions;
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const adminClient = new AdminClient();
    const [showVersion, setShowVersion] = useState(false)
    const { formValidation } = useFormValidation('Form_version')
    const [canEditVersion, setCanEditVersion] = useState(true)

    const cmsInit = langs.length === 1
        ? {
            title: "",
            body: "",
            image: ""
        }
        :
        langs.reduce((result, lang) => {
            result[lang] = {
                title: "",
                body: "",
                image: ""
            };
            return result;
        }, {}
        );

    const newDataDetail = {
        version: "",
        config: {
            cms: cmsInit
        },
        status: "DISABLED",
        init_date: new Date(),
    };
    const [termsConditionsVersion, setTermsConditionsVersion] = useState(newDataDetail);
    const [termsConditionsLastVersion, setTermsConditionsLastVersion] = useState(newDataDetail);

    useEffect(() => {
        if (props.versions.length !== 0) {
            let maxVersion = -Infinity;
            let maxVersionDetails;
            for (const version of props.versions) {
                if (version.version > maxVersion) {
                    maxVersion = version.version;
                    maxVersionDetails = version;
                }
            }
            setTermsConditionsLastVersion(maxVersionDetails)
        }
        else {
            setTermsConditionsLastVersion(newDataDetail);
        }


    }, [props.versions])




    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setTermsConditionsVersion((prevState) => ({ ...prevState, [name]: value }));
    };

    const saveDataCMSSumary = (urls, lang) => {
        if (lang === undefined) {
            if (urls.length === 0) {
                setTermsConditionsVersion((prevState) => {
                    let auxConfig = prevState.config;
                    auxConfig.cms.image = "";
                    return { ...prevState, ["config"]: auxConfig };
                });
            } else {
                setTermsConditionsVersion((prevState) => {
                    let auxConfig = prevState.config;
                    auxConfig.cms.image = urls[0];
                    return { ...prevState, ["config"]: auxConfig };
                });
            }
        } else {
            if (urls.length === 0) {
                setTermsConditionsVersion((prevState) => {
                    let auxConfig = prevState.config;
                    auxConfig.cms[lang].image = "";
                    return { ...prevState, ["config"]: auxConfig };
                });
            } else {
                setTermsConditionsVersion((prevState) => {
                    let auxConfig = prevState.config;
                    auxConfig.cms[lang].image = urls[0];
                    return { ...prevState, ["config"]: auxConfig };
                });
            }
        }
    }

    const onChangeHandlerConfigCMS = (e) => {
        const { name, value, lang } = e.target || e;
        if (lang === undefined) {
            if (value !== termsConditionsVersion.config.cms[name]) {

                setTermsConditionsVersion((prevState) => {
                    let auxConfig = prevState.config;
                    auxConfig.cms[name] = value;
                    return { ...prevState, ["config"]: auxConfig };
                });
            }
        }
        else {
            if (value !== termsConditionsVersion.config.cms[lang][name]) {

                setTermsConditionsVersion((prevState) => {
                    let auxConfig = prevState.config;
                    auxConfig.cms[lang][name] = value;
                    return { ...prevState, ["config"]: auxConfig };
                });
            }
        }
    };


    const deleteVersion = () => {
        adminClient.deleteTermsConditionsVersionDetails(session, company, termsConditions.term, termsConditionsVersion.version).then(
            (msg) => {
                if (msg.result === 'OK') {
                    props.showAlert(t('Terms and conditions'), t('Deleted succesfull'))
                    setShowVersion(false)
                    props.updateVersion()
                } else {
                    props.showAlert(t('Terms and conditions'), t('Saving error ') + msg.description)
                }
            },
            (error) => {
                props.showAlert(t('Terms and conditions'), t('Saving error ') + error.description)
            }
        )
    }

    const saveVersion = () => {
        if (formValidation.validate()) {

            let auxVersion = JSON.parse(JSON.stringify(termsConditionsVersion));
            auxVersion.config = JSON.stringify(auxVersion.config, null, 3);
            auxVersion.init_date = utils.unformatDate(new Date(auxVersion.init_date))
            adminClient[addOrUpdate + "TermsConditionsVersionDetails"](session, company, termsConditions.term, JSON.stringify(auxVersion)).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Terms and conditions'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                        setShowVersion(false)
                        props.updateVersion()
                    } else {
                        props.showAlert(t('Terms and conditions'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Terms and conditions'), t('Saving error ') + error.description)
                }
            )
        }
    }


    const columnsDef = [
        {
            Header: t("ID"),
            accessor: "version",
            typeCell: "text",
        },
        {
            Header: t("Name"),
            accessor: "name",
            typeCell: "text",
        },
        {
            Header: t("Init Date"),
            accessor: "init_date",
            typeCell: "date",
        },
        {
            Header: t("Status"),
            accessor: "status",
            typeCell: "translate",
        }
    ];

    const addOrUpdateVersion = (version) => {
        if (version === "-1") {
            //creamos
            setAddOrUpdate("add")
            let termsAux = JSON.parse(JSON.stringify(termsConditionsLastVersion));
            termsAux.name = "NEW VERSION"
            setTermsConditionsVersion(termsAux)
        } else {
            //modificamos
            setAddOrUpdate("update")
            setTermsConditionsVersion(version);
        }
        setShowVersion(true)

    };

    const handleNewVersion = () => {
        setCanEditVersion(true);
        addOrUpdateVersion("-1")
    }
    const handleVersion = (version) => {
        setShowVersion(true)
        adminClient.getTermsConditionsVersionAccepted(session, company, termsConditions.term, version.original.version).then(
            function (res) {
                setCanEditVersion(res.data.length === 0)
                version.original.init_date = utils.formatDate(version.original.init_date)
                addOrUpdateVersion(version.original)

            },
            function (err) {
                console.error(err);
            }
        );
    }
    const dataHeader = {
        backLink: "",
        title: t("Versions"),
        urlHelp: "",
        idDoc: props.idDoc,
        buttons: [
            { name: t("New Version"), handle: handleNewVersion, icon: <FontAwesomeIcon icon="plus-square" /> }
        ]
    }


    return (
        <div>
            <LayoutSection dataHeader={(dataHeader !== null) ? dataHeader : ""}>
                <BasicTable key="table-List" data={props.versions} columns={columnsDef} rowHandle={handleVersion} />
                <div className="row">
                    <div className="col-12">
                        <form id="Form_version">
                            {
                                showVersion &&
                                <>
                                    <div className="row">
                                        <div className="col-4">
                                            <TextField
                                                label={t("Name")}
                                                value={termsConditionsVersion.name || ""}
                                                name="name"
                                                required
                                                onChange={onChangeHandler}
                                            ></TextField>
                                        </div>
                                        <div className="col-4">
                                            <BasicSelect
                                                idProperty='name'
                                                basicInfoName='EnabledDisabled'
                                                value={termsConditionsVersion.status}
                                                name='status'
                                                disabled={!canEditVersion}
                                                label={t('Status')}
                                                onChange={onChangeHandler}
                                                translate={{ property: 'name', prefix: '' }}
                                                disableClearable={true}
                                            />
                                        </div>
                                        <div className="col-4">
                                            <DateAndTimePicker
                                                label={t("Init Date")}
                                                value={termsConditionsVersion.init_date}
                                                showTime={true}
                                                callBackFunction={(value) => {
                                                    onChangeHandler({ target: { name: "init_date", value: value } });
                                                }}
                                                name="init_date"
                                                id={"init_date"}
                                                required={true}
                                            />
                                        </div>
                                    </div>

                                </>
                            }
                        </form>

                        {
                            showVersion &&
                            <>
                                {canEditVersion &&
                                    <div className='row'>
                                        <div className="col-12">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={saveVersion}
                                            >
                                                {t('Save')}
                                            </button>
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                onClick={deleteVersion}
                                            >
                                                {t('Delete')}
                                            </button>
                                        </div>
                                    </div>
                                }
                                <div className="col-12">
                                    <Tab key="tabsTermsConditions" active={0}>
                                        {langs.map((lang, idx) =>
                                            <Tab.TabPane key={`TabTermCondition-${idx}`} tab={lang}>
                                                <div className="row">
                                                    <div className="col-12">

                                                        <CMS
                                                            key={termsConditionsVersion.version}
                                                            disabled={!canEditVersion}
                                                            lang={langs.length > 1 ? lang : undefined}
                                                            details={termsConditionsVersion.company + '_' + termsConditionsVersion.term + '_' + termsConditionsVersion.version}
                                                            onChangeHandler={onChangeHandlerConfigCMS}
                                                            cmsInfo={termsConditionsVersion.config.cms[lang] || termsConditionsVersion.config.cms}
                                                            titleLabel={t("Title")}
                                                            bodyLabel={t("Body")}
                                                            editorHeight={500}
                                                            showAlert={props.showAlert}
                                                            type="TERMSCONDITIONS"
                                                            saveImageCMS={saveDataCMSSumary}
                                                            pathImage="/cms/img/termsConditions/"
                                                            id={termsConditionsVersion.company + '_' + termsConditionsVersion.term + '_' + termsConditionsVersion.version}
                                                            noFile
                                                        />
                                                    </div>

                                                </div>
                                            </Tab.TabPane>
                                        )}
                                    </Tab>
                                </div>
                            </>
                        }
                    </div>
                </div>
            </LayoutSection >



        </div >
    )
}

function TermsConditionsDetails(props) {
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_TermsConditionsDetails')
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const termId = utils.getSessionItem('current-term');
    const company = utils.getSessionItem("Company");
    const [termsConditionsVersions, setTermsConditionsVersions] = useState([])
    const [termsConditions, setTermsConditions] = useState({
        term: termId,
        description: "",
        name: "",
        status: "ENABLED"
    });

    const goBack = () => {
        props.history.push('/reports/report/terms_and_conditions/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Terms and conditions'),
        urlHelp: '',
    };
    useEffect(function () {
        if (termId !== undefined && termId !== '-1') {
            getTermsConditions();
        } else {
            setAddOrUpdate('add');
        }
    }, []);

    const getTermsConditions = () => {
        adminClient.getTermsConditionsDetails(session, company, termsConditions.term).then(
            function (res) {
                let termsDetails = res.data;
                let versions = res.versions;
                versions.forEach(element => {
                    element.config = JSON.parse(element.config);
                });
                setTermsConditions(termsDetails);
                setTermsConditionsVersions(versions);
                setAddOrUpdate('update');
            },
            function (err) {
                console.error(err);
            }
        );
    }
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setTermsConditions((prevState) => ({ ...prevState, [name]: value }));
    };


    const updateVersion = () => {
        getTermsConditions();
    }

    const save = (evt) => {
        if (formValidation.validate()) {
            adminClient[addOrUpdate + "TermsConditionsDetails"](session, company, JSON.stringify(termsConditions)).then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Terms and conditions'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                        }
                    } else {
                        props.showAlert(t('Error'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Error'), t('Saving error ') + error.description)
                }
            )
        }
    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_TermsConditionsDetails">
                <div className="row">
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('ID')}
                            name="term"
                            required
                            disabled={addOrUpdate === 'update'}
                            InputProps={addOrUpdate === 'add' ? {
                                inputProps: { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }
                            } : {}}
                            type="text"
                            value={termsConditions.term}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <TextField
                            label={t('Name')}
                            name="name"
                            required
                            type="text"
                            value={termsConditions.name}
                            onChange={onChangeHandler}
                        />
                    </div>
                    <div className="col-12 col-md-4">
                        <BasicSelect
                            idProperty='name'
                            basicInfoName='EnabledDisabled'
                            value={termsConditions.status}
                            name='status'
                            label={t('Status')}
                            onChange={onChangeHandler}
                            translate={{ property: 'name', prefix: '' }}
                            disableClearable={true}
                        />
                    </div>

                    <div className="col-12 col-md-12">
                        <TextField
                            multiline
                            label={t('Description')}
                            name="description"
                            type="text"
                            value={termsConditions.description}
                            onChange={onChangeHandler}
                        />
                    </div>
                </div>

            </form>

            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={save}
                    >
                        {t('Save')}
                    </button>

                </div>
            </div>
            {addOrUpdate === "update" &&
                <div className='col-12 col-md-12'>
                    <TermsConditionsVersionDetails
                        t={t}
                        termsConditions={termsConditions}
                        versions={termsConditionsVersions}
                        showAlert={props.showAlert}
                        updateVersion={updateVersion}
                    />
                </div>
            }

        </LayoutSection>
    )
}
export default withTranslation()(TermsConditionsDetails);