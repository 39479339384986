import React, { useState, useContext, useEffect } from 'react'
import LoginForm from '../../components/LoginForm';
import AdminClient from '../../AdminClient';
import { Route, withRouter } from 'react-router-dom';
import utils from "../../utils";
import i18next from "i18next";
import { initReactI18next, withTranslation } from "react-i18next";
import { AuthContext } from "../../context/AuthContext";
import PublicLayout from '../../components/PublicLayout';
import 'parsleyjs/dist/parsley'
import moment from 'moment';


import 'parsleyjs/dist/i18n/pt-pt'
import 'parsleyjs/dist/i18n/pt-br'
import 'parsleyjs/dist/i18n/es'

import parsleyValidationGral from "../../context/parsleyValidationGral.js"
import { TextField } from '@mui/material';
import useFormValidation from '../../hooks/parsleyValidation';
import IconButton from '@mui/material/IconButton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';

function Login(props) {

  const { setSessionDataInfo } = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState('')
  const [loginDetails, setLoginDetails] = useState({ alias: "", password: "", otp: "" })
  const [showPassword, setShowPassword] = useState(false); // Nuevo estado para controlar la visibilidad de la contraseña
  const { formValidation } = useFormValidation('Form_login')

  useEffect(function () {
    utils.removeSession();

  }, [])

  const handleKeyPress = (event) => {
    if (event.code === "Enter" || event.code === "NumpadEnter") {
      event.preventDefault();
      handleLoginSubmit();
    }
  }

  useEffect(() => {
    document.title = window.appBOTitle || 'Calimaco';
    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const { t } = props;

  const onChangeHandler = (e) => {
    const { name, value } = e.target || e;
    setLoginDetails((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleLoginSubmit = () => {
    if (formValidation.validate()) {
      const details = { ...loginDetails };
      const alias = details.alias;
      const password = details.password;
      const otp = details.otp;
      console.log("login otp = " + otp);
      var clientAPI = new AdminClient();
      setErrorMessage('')
      var loginPromise = clientAPI.login(alias, password, otp);
      loginPromise.then(async (msg) => {
        console.log("Login ok " + JSON.stringify(msg.user));
        // Asignamos la variable global user
        utils.setSessionItem("user", JSON.stringify(msg.user));
        utils.setSessionItem("calimaco-lang", msg.user.lang);
        let headerDate=await clientAPI.getOneGlobalParam(msg.user.session,msg.user.company,"BACKOFFICE_HIDE_HEADER_DATE")
        utils.setSessionItem("hideHeaderDate", headerDate.param);
       

        var lang = 'es';
        if (msg.user.lang !== undefined && msg.user.lang !== null) {
          lang = msg.user.lang.substring(0, 2);
          moment.globalLocale = lang
          moment.locale(lang)
        }
        utils.setNumberFormat(msg.user.number_format);
        setSessionDataInfo(msg.user.session)

        clientAPI.getI18n(msg.user.company).then((values) => {
          var _res = {};
          values.data.forEach(translate => {
            if (_res[translate.lang] === undefined) {
              _res[translate.lang] = { "translation": {} };
            }
            _res[translate.lang].translation[translate.key] = translate.value;
          });
          //console.log("i18n res = " + JSON.stringify(_res));
          utils.setSessionItem("i18n", JSON.stringify(_res));
          i18next
            .use(initReactI18next) // passes i18n down to react-i18next
            .init({
              resources: _res,
              lng: "es",
              fallbackLng: "en",

              interpolation: {
                escapeValue: false
              }

            });
          i18next.changeLanguage(msg.user.lang.substring(0, 2));
          try {
            let langParsley = msg.user.lang.split("-")[0]
            if (langParsley === 'pt') langParsley = 'pt-br'
            window.Parsley.setLocale(langParsley)
            Object.keys(parsleyValidationGral).forEach((nameFunction) => {
              let configFunction = parsleyValidationGral[nameFunction];
              let message = _res[langParsley].translation['validation_' + nameFunction];
              if (message !== undefined) {
                configFunction.messages = {}
                configFunction.messages[langParsley] = message
              } else {
                configFunction.messages = {}
                configFunction.messages[langParsley] = ' '
              }
              window.Parsley.addValidator(nameFunction, configFunction);
            })
          }
          catch (err) {
            console.log("error cargando funciones de validación en login")
            console.log(err)

          }
        }, (err) => {
          console.log("Error " + err);
        })

        clientAPI.getAdminNotifications(msg.user.session, msg.user.company).then((result) => {
          if (result.data.length > 0) utils.setSessionItem('showAdminNotifications', true)
          else utils.setSessionItem('showAdminNotifications', false)
        })

        // Leemos las secciones del usuario
        var sectionsPromise = clientAPI.getSections(msg.user.session, msg.user.company);
        sectionsPromise.then((msgSections) => {

          utils.setSections(msgSections.sections)

          // Leemos los menus del usuario
          var menusPromise = clientAPI.getMenus(msg.user.session, msg.user.company);
          menusPromise.then((msgMenus) => {

            utils.setMenus(msgMenus.menus)

            var companiesPromise = clientAPI.getUserCompanies(msg.user.session);

            companiesPromise.then((companies) => {
              console.log("Companies = " + JSON.stringify(companies.data));
              utils.setSessionItem("Companies", JSON.stringify(companies.data));
              utils.setSessionItem("Company", msg.user.company);
              var companiesOptions = [];

              companies.data.forEach(element => {
                companiesOptions.push({ value: element.company, label: element.name });
              });
              utils.setSessionItem("companiesOptions", JSON.stringify(companiesOptions));
              //Obtengo las documentaciones de las secciones
              var documentationPagesPromise = clientAPI.getDocumentationPages(msg.user.session, msg.user.company);
              documentationPagesPromise.then(function (documentationPages) {
                utils.setSessionItem("documentationPages", JSON.stringify(documentationPages.data));
              }, function (err) {
                console.log("Login error " + err.code);
                setErrorMessage(err.description);
              });
                            
              var basicInfoPromise = clientAPI.getBasicInfo(msg.user.session);
              basicInfoPromise.then(function (basicInfo) {
                console.log("Basic info = " + JSON.stringify(basicInfo.data));
                let objetoEncontrado = null;

                for (let i = 0; i < msgMenus.menus.length; i++) {
                  if (msgMenus.menus[i].url === msg.user.init_page) {
                    objetoEncontrado = msgMenus.menus[i];
                    break; // Terminamos el bucle una vez que encontramos el objeto
                  }
                }
                let init_user_page = undefined;
                if (objetoEncontrado) {
                  init_user_page = objetoEncontrado.url;
                }
                for (const dataSet in basicInfo.data) {
                  utils.setSessionItem("basicInfo-" + dataSet, JSON.stringify(basicInfo.data[dataSet]));
                }
                let sectionsAux = window.sections;
                const exits = "DASHBOARD" in sectionsAux;

                if (exits) {
                  props.history.push(init_user_page || '/home');
                }
                else {
                  props.history.push(init_user_page || '/defaultHome');
                }

              }, function (err) {
                console.log("Login error " + err.code);
                setErrorMessage(err.description);
              });
            })
          }, (err) => {
            console.log("Login error " + err.code);
            setErrorMessage(err.description);
          })
        },
          (err) => {
            console.log("Login error " + err.code);
            setErrorMessage(err.description)
          })
      },
        (err) => {
          console.log("Login error " + err.description);
          if (err.code === undefined) {
            setErrorMessage(err.description);
          } else {
            setErrorMessage(err.description);
          }

        })
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Alternar el estado de visibilidad de la contraseña
    const passwordInput = document.getElementById('password');
    if (passwordInput) {
      passwordInput.focus(); // Enfocar el campo de contraseña para mantener el cursor en su lugar
      const cursorPosition = passwordInput.selectionStart; // Obtener la posición del cursor
      setTimeout(() => { // Esperar un breve momento para que el tipo de entrada cambie antes de restaurar el valor
        passwordInput.setSelectionRange(cursorPosition, cursorPosition); // Restaurar la posición del cursor
      }, 0);
    }
  };

  return (
    <Route
      {...props}
      render={propsRender => (
        <PublicLayout>
          <div className="container mt-3">
            <div className="row justify-content-center">
              <div className="col-12 col-md-4 col-lg-3 loginPanel">
                <form className="text-center" id="Form_login">
                  <img src={process.env.REACT_APP_LOGO} alt={process.env.REACT_APP_TITLE} className="logo_login"></img>
                  <div className="container loginInputs">
                    <div className="mb-3  text-start">
                      <TextField tabIndex={1} required label={t("Username")} type="text" onChange={onChangeHandler} name="alias"
                        InputProps={{
                          inputProps: { "data-parsley-pattern": "^.{3,}$", "data-parsley-pattern-message": t('Min 3 characters') }
                        }}
                      />
                    </div>

                    <div className="mb-3 text-start">
                      <TextField tabIndex={2} required name="password" label={t("Password")} type={showPassword ? "text" : "password"} onChange={onChangeHandler} // Cambiar el tipo de entrada según el estado de visibilidad onChange={onChangeHandler} name="password"
                        InputProps={{
                          inputProps: { "data-parsley-pattern": "^.{3,}$", "data-parsley-pattern-message": t('Min 3 characters') },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onMouseDown={togglePasswordVisibility} onMouseUp={togglePasswordVisibility} edge="end">
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>

                    <div className="mb-3 text-start  clmc-otp-element " id="OTPinput">
                      <TextField tabIndex={3} label={t("One Time Password")} type="text" onChange={onChangeHandler} name="otp" />
                    </div>

                    <div>{t(errorMessage)}</div>
                    <button tabIndex={4} type="button" className="btn btn-primary" onClick={handleLoginSubmit}>{t("Login")}</button>


                  </div>

                </form>
                <div className="col-12 "><p className=" text-end">v {process.env.REACT_APP_VERSION}</p></div>
              </div>
            </div >
          </div >
          <link rel="stylesheet" href="/customized/css/customized.css"></link>
        </PublicLayout>
      )}
    />
  )
}
export default withRouter(withTranslation()(Login))