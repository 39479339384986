import React, { useMemo, useState } from 'react'
import { MuiTelInput } from 'mui-tel-input';
import { TextField } from '@mui/material';
import utils from '../../utils';

const PhoneInput = (props) => {

  const companyDetails = useMemo(() => utils.getCurrentCompany(), []);

  const isValidatePhone=companyDetails.config?.backoffice?.registerPlayer?.validationPhone || false
  const defaultCountry= companyDetails.config?.register?.defaultCountry||'US'  
  const [value,setValue]=useState(props.value)
  const onChange=(evt)=>{
    setValue(evt.value)
    if(props.onChange) props.onChange(evt)   
  }
  return (<>
    {isValidatePhone?
        <MuiTelInput label={props.label} defaultCountry={defaultCountry} InputProps={{ inputProps: { "data-parsley-validate-phone": true } }} value={value} onChange={(value)=>{onChange({name:props.name,value:value})}} />
    :
        <TextField {...props} />}
    </>
  )
}

export default PhoneInput