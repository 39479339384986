import { TextField } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import Tab from '../../components/basic/BasicTabs';
import { CKEditor } from 'ckeditor4-react';
import File from '../../components/basic/File';
import AllowedForbiddenList from '../../components/basic/AllowedForbiddenList';
import Editor from '../../components/basic/Editor';
function FragmentDetails(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_FragmentDetails');
    let fragment = utils.getSessionItem('current-fragment');
    let companyAux = utils.getCurrentCompany();
    const langs = companyAux.langs.split(",");
    const [addOrUpdate, setAddOrUpdate] = useState('add');
    const [ckEditor, setCkEditors] = useState(undefined);
    const [oneTime, setOneTime] = useState(false);
    const [refreshTabs, setRefreshTabs] = useState(false);

    const contentInit = langs.length === 1
        ? ""
        : langs.reduce((result, lang) => {
            result[lang] = "";
            return result;
        }, {});

    const [fragmentDetails, setFragmentDetails] = useState({
        fragment: {
            fragment: fragment,
            name: "",
            groups: [],
            config: {},
            content: contentInit
        },
        groups_allowed: [],
        groups_availables: [],
        groups_forbbiden: []
    });


    const goBack = () => {
        props.history.push('/reports/report/fragments/table');
    };
    const dataHeader = {
        backLink: goBack,
        title: t('Fragments details'),
        urlHelp: '',
        idDoc: props.idDoc
    };


    useEffect(function () {
        if (fragment !== undefined && fragment !== '-1') {
            fetchFragmentDetails();
        } else {
            setOneTime(true)
            setAddOrUpdate('add');
            let newFragment = { ...fragmentDetails };
            if (langs.length == 1) {
                newFragment.fragment.content = ' '
            }
            setFragmentDetails(newFragment);
        }
    }, []);

    useEffect(function () {
        if (fragment == '-1') {
            handleEditorChangeLang('<p>a</p>', langs[0])
        }
    }, [oneTime]);

    const fetchFragmentDetails = () => {
        adminClient.getFragmentDetails(session, company, fragment).then(
            function (result) {
                let groups_allowed = [];
                let groups_forbbiden = [];
                result.data.fragment.groups.forEach(element => {
                    console.log("element : " + JSON.stringify(element))
                    if (element.relationship === 'ALLOWED') {
                        groups_allowed.push(element);
                    } else {
                        groups_forbbiden.push(element);
                    }

                });

                let newFragment = { ...fragmentDetails };
                newFragment.fragment = result.data.fragment
                newFragment.groups_allowed = groups_allowed
                newFragment.groups_forbbiden = groups_forbbiden
                newFragment.groups_availables = result.data.groups_availables

                if (langs.length > 1) {
                    let existeClave = false;
                    let cmsParse;
                    let contentParse;
                    try {
                        contentParse = JSON.parse(newFragment.fragment.content)
                    } catch (error) {
                        contentParse = newFragment.fragment.content
                    }
                    let claves = Object.keys(contentParse)
                    for (const lang of langs) {
                        if (claves.includes(lang)) {
                            existeClave = lang;
                            break;
                        }
                    }
                    if (!existeClave) {
                        cmsParse = langs.reduce((obj, key) => {
                            obj[key] = JSON.parse(JSON.stringify(newFragment.fragment.content));
                            return obj;
                        }, {});
                    }
                    else {
                        let content = JSON.parse(newFragment.fragment.content)
                        cmsParse = langs.reduce((obj, key) => {
                            if (content[key] !== undefined) {
                                obj[key] = content[key]
                            }
                            else {
                                obj[key] = JSON.parse(JSON.stringify(content[existeClave]));
                            }
                            return obj;
                        }, {});
                    }

                    newFragment.fragment.content = cmsParse
                }
                setOneTime(true)
                setFragmentDetails(newFragment);
                setAddOrUpdate('update');
            },
            function (err) {
                console.error(err);
            }
        );
    }

    const onChangeHandlerFragment = (e) => {
        const { name, value } = e.target || e;
        setFragmentDetails((prevState) => ({
            ...prevState,
            fragment: {
                ...prevState.fragment,
                [name]: value,
            }
        }));
    };

    const handleEditorChange = (event) => {
        const data = event.editor.getData();
        onChangeHandlerFragment({ target: data })
    };

    const handleEditorChangeLang = (data, lang) => {

        if (langs.length > 1) {
            setFragmentDetails((prevState) => {
                let oldState = { ...prevState };
                oldState.fragment.content[lang] = data.value;
                return { ...prevState, oldState }
            });
        }
        else {
            onChangeHandlerFragment({ target: data })

        }
    };

    const saveDataFraments = (urls) => {
        let newFragment = { ...fragmentDetails };
        if (newFragment.fragment.images === undefined || newFragment.fragment.images === null) {
            newFragment.fragment.images = [];
            if (urls.length > 0) {
                newFragment.fragment.images = urls
            }
        }
        else {
            newFragment.fragment.images = urls;
        }
        setFragmentDetails(newFragment);
    }

    const onChangeHandlerGroups = (allowed, forbidden) => {
        setFragmentDetails(prevState => ({
            ...prevState,
            groups_allowed: allowed,
            groups_forbbiden: forbidden
        }));
    }

    const save = (evt) => {
        if (formValidation.validate()) {
            let fragmentAux = JSON.parse(JSON.stringify(fragmentDetails))
            let allowed_groups = [];
            let forbidden_groups = [];


            fragmentAux.groups_allowed.forEach(element => {
                allowed_groups.push(element.group);
            });
            fragmentAux.groups_forbbiden.forEach(element => {
                forbidden_groups.push(element.group);
            });

            fragmentAux.fragment.forbidden_groups = forbidden_groups.join();
            fragmentAux.fragment.allowed_groups = allowed_groups.join();
            if (langs.length > 1) {
                fragmentAux.fragment.content = JSON.stringify(fragmentAux.fragment.content)
            } else {
                fragmentAux.fragment.content = fragmentAux.fragment.content
            }
            let promise;
            if (addOrUpdate === 'add') {
                promise = adminClient.addFragmentDetails(session, company, JSON.stringify(fragmentAux.fragment));
            }
            else {
                promise = adminClient.updateFragmentDetails(session, company, JSON.stringify(fragmentAux.fragment));
            }
            promise.then(
                (msg) => {
                    if (msg.result === 'OK') {
                        props.showAlert(t('Fragments details'), t('Saved succesfull'))
                        if (addOrUpdate === 'add') {
                            setAddOrUpdate('update')
                            fragment = fragmentDetails.fragment.fragment
                            fetchFragmentDetails();
                        }
                    } else {
                        props.showAlert(t('Error'), t('Saving error ') + msg.description)
                    }
                },
                (error) => {
                    props.showAlert(t('Error'), t('Saving error ') + error.description)
                }
            )
        }
    }

    const remove = (evt) => {
        let fragmentAux = JSON.parse(JSON.stringify(fragmentDetails))
        let allowed_groups = [];
        let forbidden_groups = [];

        fragmentAux.groups_allowed.forEach(element => {
            allowed_groups.push(element.group);
        });
        fragmentAux.groups_forbbiden.forEach(element => {
            forbidden_groups.push(element.group);
        });

        fragmentAux.fragment.forbidden_groups = forbidden_groups.join();
        fragmentAux.fragment.allowed_groups = allowed_groups.join();
        if (langs.length > 1) {
            fragmentAux.fragment.content = JSON.stringify(fragmentAux.fragment.content)
        } else {
            fragmentAux.fragment.content = fragmentAux.fragment.content.value
        }

        props.showAlert(
            t('Fragments'),
            <p>{t('DELETE_FRAGMENT_CONFIRMATION')}</p>,
            [<button type='button' className='btn btn-secondary' onClick={props.handleCloseAlert}>{t('Cancel')}</button>,
            <button type='button' className='btn btn-primary'
                onClick={(evt) => {
                    adminClient.deleteFragmentDetails(session, company, JSON.stringify(fragmentAux.fragment)).then(
                        (msg) => {
                            if (msg.result === 'OK') {
                                props.showAlert(t('Fragments'), t('Deleted succesfull'))
                                if (addOrUpdate === 'add') {
                                    setAddOrUpdate('update')
                                }
                                goBack();
                            } else {
                                props.showAlert(t('Error'), t('Deleting error ') + msg.description)
                            }
                        },
                        (error) => {
                            props.showAlert(t('Error'), t('Deleting error ') + error.description)
                        }
                    )
                    props.handleCloseAlert()
                }}>
                {t('Ok')}</button>]
        )
    }

    let contentRender = () => {
        if (fragmentDetails.fragment.content && fragmentDetails.fragment.content == contentInit) {
            return null; // Espera a que el contenido se cargue antes de renderizar
        }
        return (
            <>

                <Tab key="tabFragmentsLangs" active={0}>
                    {langs.map((lang, idx) =>
                        <Tab.TabPane key={`tabFragmentsLangs-${idx}`} tab={lang}>
                            <div className="row">
                                <div className='col-12'>
                                    <Editor
                                        onChangeHandler={(data) => handleEditorChangeLang(data, lang)}
                                        info={
                                            fragmentDetails.fragment?.content?.[lang] ||
                                            fragmentDetails.fragment?.content ||
                                            ""
                                        }
                                        lang={lang}
                                        oneTime={oneTime}
                                    />
                                </div>
                            </div>
                        </Tab.TabPane>
                    )}
                </Tab>

            </>
        )
    }

    let multimediaRender = () => {
        return (
            <div className="row">
                <File
                    dropEnable={true}
                    showFile={true}
                    showError={true}
                    viewerEnable={false}
                    deleteEnable={true}
                    saveData={saveDataFraments}
                    fileName={fragmentDetails.fragment.images}
                    fileUrlPref={companyAux.config.cdn_url + "../"}
                    getFileUrl={(data) => {
                        return "/cms/img/web_fragments/" + fragmentDetails.fragment.fragment + "/" + data.fileName;
                    }}
                    type={'WEB_FRAGMENT'}
                    enableCopyUrl={true}
                    showAlert={props.showAlert}
                    owner={JSON.stringify(fragmentDetails.fragment)}
                />
            </div>
        );
    };
    let groupsRender = () => {
        return (
            < div className="row" >
                <AllowedForbiddenList
                    allowed={fragmentDetails.groups_allowed}
                    availables={fragmentDetails.groups_availables}
                    forbidden={fragmentDetails.groups_forbbiden}
                    idProperty={"group"}
                    nameProperty={"name"}
                    handleUpdateGroups={onChangeHandlerGroups}
                ></AllowedForbiddenList>
            </div >
        );
    };

    const [tabsContent, setTabsContent] = useState([
        { title: t("Content"), content: contentRender },
        { title: t("Multimedia"), content: multimediaRender },
        { title: t("Groups"), content: groupsRender }
    ]);

    useEffect(() => {
        let tabAux;
        if (addOrUpdate === 'update') {


            tabAux = [
                { title: t("Content"), content: contentRender },
                { title: t("Multimedia"), content: multimediaRender },
                { title: t("Groups"), content: groupsRender }
            ];
        }
        else {
            tabAux = [
                { title: t("Content"), content: contentRender }
            ];
        }
        setTabsContent(tabAux);
    }, [JSON.stringify(fragmentDetails), addOrUpdate]);

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_FragmentDetails">
                <div className="row">
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('ID')}
                            name="fragment"
                            required
                            disabled={addOrUpdate === 'update'}
                            // inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
                            type="text"
                            value={fragmentDetails.fragment.fragment}
                            onChange={onChangeHandlerFragment}
                        />
                    </div>
                    <div className="col-12 col-md-3">
                        <TextField
                            label={t('Name')}
                            name="name"
                            required
                            // disabled={addOrUpdate === 'update'}
                            type="text"
                            value={fragmentDetails.fragment.name}
                            onChange={onChangeHandlerFragment}
                        />
                    </div>
                    <div className="col-12">
                        <Tab active={0}>
                            {tabsContent.map((tab, idx) => (
                                <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                                    {tab.content()}
                                </Tab.TabPane>
                            ))}
                        </Tab>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    {utils.isGranted("SAVE_CMS_FRAGMENTS") &&
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={save}
                        >
                            {t('Save')}
                        </button>
                    }
                    {utils.isGranted("SAVE_CMS_FRAGMENTS") &&
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={remove}
                            id="removeBtn"
                        >
                            {t('Delete')}
                        </button>
                    }
                </div>
            </div>
        </LayoutSection>
    )
}
export default withTranslation()(FragmentDetails);