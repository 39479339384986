
import { AgentToolBar } from "../components/agents/componentsAgents";
import { PlayerToolBar } from "../components/agents/componentsUsers";
import RenderReports from "./renderReports";
import GameRender from "../components/renders/GameRender";
import AdminClient from "../AdminClient";
import utils from "../utils";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "@mui/material";



const BalanceUserRender = (props) => {
    const adminClient = new AdminClient()
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session
    const [balanceData, setBalanceData] = useState({ "amount": 0.00, "currency": '' })

    useEffect(() => {
        let mounted = true;
        adminClient.getCurrentUserBalance(session, company, props.row.db, props.row.user, 'CASH').then(
            (result) => {
                if (mounted) setBalanceData({ "amount": result.data[0].amount, "currency": result.data[0].currency })
            },
            (err) => {
                console.log('Error Obteniendo el balance del usuario')
            }
        )
        return () => mounted = false;

    }, [props.row.amount])
    return (props.renderFunction(balanceData.amount, { "currency": balanceData.currency }, props.t, props.cellProps))
}


class CustomRenders extends RenderReports {

    constructor() {
        super()
    }

    renderAgentsPlayersCurrency(value, row, t, cellProps) {

        cellProps.className = 'agents-currency'

        if (row.type === 'agent') return this.getCurrencyRender(value, row, t, cellProps)
        else {

            return <BalanceUserRender row={row} t={t} cellProps={cellProps} renderFunction={this.getCurrencyRender} />
        }

    }
    renderAgentAlias(value, row, t, cellProps) {
        cellProps.className = "agents-alias"
        return <Typography
            variant="body2"
            component="span"
            className="agents-alias-text" >
            {value}
        </Typography>
        // return <span className="agents-alias-text">{value}</span>

    }

    renderAgentsPlayersOperations(value, row, t, cellProps) {
        cellProps.className = 'agents-alias'
        if (row.type === 'agent') {
            return <AgentToolBar row={row} value={value} showAlert={cellProps.showAlert} handleCloseAlert={cellProps.handleCloseAlert} finishOperation={cellProps.extraData.finishOperation} />

        } else {
            return <PlayerToolBar row={row} value={value} showAlert={cellProps.showAlert} handleCloseAlert={cellProps.handleCloseAlert} finishOperation={cellProps.extraData.finishOperation} />

        }
    }
    renderGame(value, row, t, cellProps) {
        return <GameRender row={row} value={value} showAlert={cellProps.showAlert} t={t} handleCloseAlert={cellProps.handleCloseAlert} providers={this.getBasicInfoData('Providers')} />
    }
}

export default CustomRenders;