import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import AdminClient from "../AdminClient";
import utils from "../utils";
import TextField from '@mui/material/TextField';
import BasicSelect from "./basic/BasicSelect";
import { InputAdornment } from "@mui/material";
import { useAlert } from "../hooks/Alert";

const TournamentPrize = ({ prize: initialPrize, isCurrency = false, handleSave, handleDelete, showAlertPopup, t, closePopup }) => {
    const [prize, setPrize] = useState(initialPrize);
    const [selected, alertOpen, showAlert, closeAlert] = useAlert()

    const handleSubmit = () => {
        handleSave(prize);
        showAlert(t("Prize saved"), <p>{t("Prize saved ok")}</p>);
    };

    const handleDeletePrize = () => {
        handleDelete(prize);
        showAlert(t("Prize deleted"), <p>{t("Prize deleted ok")}</p>);
    };

    const handleUpdateFromPosition = (evt) => {
        const value = isCurrency ? Number(evt.target.value) * 100 : Number(evt.target.value);
        setPrize(prev => ({ ...prev, from_position: value }));
    };

    const handleUpdateToPosition = (evt) => {
        const value = isCurrency ? Number(evt.target.value) * 100 : Number(evt.target.value);
        setPrize(prev => ({ ...prev, to_position: value }));
    };

    const handleUpdatPrizeAmount = (evt) => {
        const value = Number(evt.target.value) * 100;
        setPrize(prev => ({ ...prev, prize_amount: value }));
    };

    const handleUpdateGroupMemberShip = (evt) => {
        const value = evt.currentTarget ? Number(evt.currentTarget.value) : Number(evt.value);
        setPrize(prev => ({ ...prev, prize_membership: value }));
    };

    const handleUpdatePromotion = (evt) => {
        const promotion = evt.value === "" ? "-1" : evt.value;
        setPrize(prev => ({ ...prev, prize_promotion: promotion }));
    };

    const handleUpdateVirtualGood = (evt) => {
        const value = evt.currentTarget ? evt.currentTarget.value : evt.target.value;
        setPrize(prev => ({ ...prev, prize_virtualgood: value }));
    };

    return (
        <>
            <div className="row">
                <div className="col-12 col-md-6">
                    <TextField
                        label={t("From")}
                        type="number"
                        value={isCurrency ? prize.from_position / 100 : prize.from_position}
                        InputProps={isCurrency ? {
                            endAdornment: <InputAdornment position="end">{utils.getCurrentCompany().base_currency}</InputAdornment>
                        } : {}}
                        onChange={handleUpdateFromPosition}
                    />
                </div>

                <div className="col-12 col-md-6">
                    <TextField
                        label={t("To")}
                        type="number"
                        value={isCurrency ? prize.to_position / 100 : prize.to_position}
                        InputProps={isCurrency ? {
                            endAdornment: <InputAdornment position="end">{utils.getCurrentCompany().base_currency}</InputAdornment>
                        } : {}}
                        onChange={handleUpdateToPosition}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-12 col-md-6">
                    <TextField
                        label={t("Cash Prize")}
                        type="number"
                        value={prize.prize_amount !== undefined ? prize.prize_amount / 100 : 0}
                        onChange={handleUpdatPrizeAmount}
                    />
                </div>
                <div className="col-12 col-md-6">
                    <BasicSelect
                        label={t("Promotion")}
                        idProperty="promotion"
                        name="promotions"
                        nameProperty="promotion"
                        data="promotions"
                        basicInfoName="ManualPromotions"
                        onChange={handleUpdatePromotion}
                        value={prize.prize_promotion}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12 col-md-6">
                    <BasicSelect
                        idProperty="group"
                        name="group"
                        label="Groups"
                        basicInfoName="Groups"
                        addOpts={[{ group: "-1", name: t("Does not apply") }]}
                        onChange={handleUpdateGroupMemberShip}
                        value={prize.prize_membership}
                        noEmptyValue
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>{t("Add")}</button>
                    <button type="button" className="btn btn-primary btn-delete float-start" onClick={handleDeletePrize}>{t("Delete")}</button>
                    <button type="button" className="btn btn-secondary" onClick={closePopup}>{t("Back")}</button>
                </div>
            </div>
        </>
    );
};

export default withTranslation()(TournamentPrize);
