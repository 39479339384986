import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { withTranslation } from "react-i18next";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import useFormValidation from "../../hooks/parsleyValidation";
import BasicSelect from "../../components/basic/BasicSelect";
import Tab from "../../components/basic/BasicTabs";
import DateAndTimePicker from "../../components/DateAndTimePicker";
import AllowedForbiddenList from "../../components/basic/AllowedForbiddenList";
import File from "../../components/basic/File";
import CMS from "../../components/basic/CMS";
import TreeSelector from "../../components/basic/TreeSelector";
import TableAndDetails from "../../components/basic/TableAndDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WebContentData from "../../components/basic/WebContentData";

//COMPONENTE AUXILIAR DE MISSIONS DETAILS
const MissionObjectives = (props) => {

  const newDataDetail = {
    objective: "",
    name: "",
    description: "",
    type: "WAGERS_COUNT",
    machines: [],
    isNew: true
  };

  const handleObjectives = props.handleObjectives;
  const machinesAvailables = props.machinesAvailables || [];
  const objectivesConfig = props.objectivesConfig || [];
  const [objective, setObjective] = useState({});
  const [objectives, setObjectives] = useState([]);
  const { formValidation } = useFormValidation("Form_objective");


  const getObjectiveConfig = (type) => {
    let objConf = objectivesConfig.find((conf) => conf.type === type);
    return JSON.parse(objConf.config);
  };

  const saveObjective = (newObjective) => {
    if (formValidation.validate()) {
      if (newObjective === undefined) newObjective = { ...objective }
      let createObjective = true
      let indexOf = objectives.findIndex(
        (obj) => obj.objective === newObjective.objective
      );
      let objs = [...objectives];
      if (indexOf !== -1)
        if (newObjective.isNew) {
          props.showAlert(t("Mission details"), t("Duplicate ID"))
          newObjective.objective = ''
          setObjective(newObjective)
          createObjective = false
        }
        else {
          objs[indexOf] = newObjective;
        }
      else {
        newObjective.isNew = false
        setObjective(newObjective)
        objs.push(newObjective)
      }
      if (createObjective) {
        formValidation.reset()
        setObjectives(objs);
        handleObjectives(objs);
      }
    }
  };

  const onChangeHandler = (evt) => {
    const { name, value } = evt.target || evt;

    setObjective((prevState) => {
      // if (name === 'amount') prevState[name] = parseInt(value) * 100
      //else prevState[name] = value;
      prevState[name] = value;
      if (!prevState.isNew) saveObjective(prevState);
      return { ...prevState };
    });

  };
  const onChangeHandlerCurrency = (evt) => {
    const { name, value } = evt.target || evt;
    setObjective((prevState) => {
      prevState[name] = value * 100;
      if (!prevState.isNew) saveObjective(prevState);
      return { ...prevState };
    });
  };

  const onChangeHandlerCheck = (evt) => {
    const { name, checked } = evt.target || evt;
    setObjective((prevState) => {
      prevState[name] = (checked) ? "true" : "false";
      if (!prevState.isNew) saveObjective(prevState);
      return { ...prevState };
    });
  }

  const onTreeMachinesChecked = (machines) => {
    setObjective((prevState) => {
      prevState["machines"] = machines;
      if (!prevState.isNew) saveObjective(prevState);
      return { ...prevState };
    });
  };

  const getDefinitionFields = (item) => {
    switch (item.type) {
      case "select":
        return (
          <div className="col-12 col-sm-4">
            <BasicSelect
              label={t(item.label)}
              name={item.name}
              addOpts={item.opts}
              data={item.data}
              idProperty={item.idProperty}
              nameProperty={item.nameProperty}
              translate_property={item.translate_property}
              translate_prefix={item.translate_prefix}
              onChange={(datos) => {
                onChangeHandler({ target: { name: datos.name, value: datos.value } });
              }}
              basicInfoName={item.basicInfoName}
              disableClearable={item.isRequired}
              noEmptyValue={item.isRequired}
              value={objective[item.name]}
              multiple={item.multiple}
            />
          </div>
        );
      case "currency":
        return (
          <div className="col-12 col-sm-4">
            <TextField
              label={t(item.label)}
              type="number"
              name={item.name}
              required={item.isRequired}
              value={objective[item.name] === undefined ? 0 : objective[item.name] / 100}
              onChange={onChangeHandlerCurrency}
            />
          </div>
        );
      case "integer":
        return (
          <div className="col-12 col-sm-4">
            <TextField
              label={t(item.label)}
              type="number"
              name={item.name}
              required={item.isRequired}
              value={objective[item.name] || 0}
              onChange={onChangeHandler}
            />
          </div>
        );
      case "boolean":
        return (
          <div className="col-12 col-sm-4">
            <FormControlLabel control={<Checkbox
              onChange={onChangeHandlerCheck}
              name={item.name}
              checked={(objective[item.name] === "true") ? true : false} />}
              label={t(item.label)} />
          </div>
        );

      case "machine":
        return (
          <div className="col-12">
            <TreeSelector
              label={t("Machine")}
              name={item.name}
              nodes={machinesAvailables}
              checked={objective.machines}
              typeTree="machine"
              onCheck={onTreeMachinesChecked}
            ></TreeSelector>
          </div>

        );
      default:
        return (
          <div className="col-12 col-sm-4">
            <TextField
              label={t(item.label)}
              name={item.name}
              type="text"
              required={item.isRequired}
              value={objective[item.name] || ''}
              onChange={onChangeHandler}
            />
          </div>
        );
    }
  };

  useEffect(() => {
    setObjectives(props.objectives);
  }, [JSON.stringify(props.objectives)]);

  const addOrUpdateObjective = (objective) => {
    if (objective === -1) {
      //creamos
      setObjective(newDataDetail)
      formValidation.reset()
    } else {
      //modificamos 
      setObjective(objective);
      formValidation.reset()
    }
  };
  const deleteObjective = (objectiveOrder) => {

    setObjectives((prevObjectives) => {
      prevObjectives.splice(parseInt(objectiveOrder), 1)
      handleObjectives(prevObjectives)
      return [...prevObjectives]
    })
  }


  const renderObjective = useCallback(() => {
    //const renderObjective = () => {  
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-4">
            <TextField
              label={t("ID")}
              disabled={!objective.isNew}
              value={objective.objective}
              name="objective"
              required
              onChange={onChangeHandler}
            ></TextField>
          </div>
          <div className="col-12 col-md-4">
            <TextField
              label={t("Name")}
              value={objective.name}
              name="name"
              required
              onChange={onChangeHandler}
            ></TextField>
          </div>
          <div className="col-12 col-md-4">
            <BasicSelect
              idProperty="type"
              name="type"
              label={t("Type")}
              basicInfoName="ObjectivesTypes"
              onChange={onChangeHandler}
              value={objective.type}
              disableClearable
            />
          </div>
          {objectivesConfig.length > 0 &&
            getObjectiveConfig(objective.type).map((obj) => {
              return getDefinitionFields(obj);
            })}
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <TextField
              label={t("Description")}
              value={objective.description}
              name="description"
              onChange={onChangeHandler}
            ></TextField>
          </div>
        </div>
      </>
    );
  }, [JSON.stringify(objective)]);
  //}

  const { t } = props;

  const columnsDef = [
    {
      Header: t("ID"),
      accessor: "objective",
      typeCell: "text",
    },
    {
      Header: t("Name"),
      accessor: "name",
      typeCell: "text",
    },
    {
      Header: t("Type"),
      accessor: "type",
      typeCell: "text",
    }
  ];

  return (
    <>
      <TableAndDetails
        sizeCol={5}
        columns={columnsDef}
        data={objectives}
        renderDetails={renderObjective}
        setDetail={addOrUpdateObjective}
        deletehandler={deleteObjective}
        showSaveButton={objective.isNew}
        handleSaveDetails={() => { saveObjective() }}
        showAlert={props.showAlert}
        handleCloseAlert={props.handleCloseAlert}
        formId="Form_objective"
      />
    </>
  );
};
function MissionDetails(props) {
  const company = utils.getSessionItem("Company");
  const companyDetails = utils.getCompany(company);
  const langs = companyDetails.langs.split(",")

  const session = JSON.parse(utils.getSessionItem("user")).session;
  const adminClient = new AdminClient();
  const { t } = props;
  const { formValidation } = useFormValidation("Form_MissionDetails");
  const [addOrUpdate, setAddOrUpdate] = useState("add");
  const mission = utils.getSessionItem("current-mission");
  const [machinesAvailables, setMachinesAvailables] = useState();
  const [objectivesConfig, setObjectivesConfig] = useState();

  useEffect(async () => {
    let machines = await adminClient.getMachines(session, company);
    setMachinesAvailables(machines.machines);
  }, []);

  useEffect(async () => {
    let objectivesConfig = await adminClient.getDataBasicInfo(
      session,
      company,
      "ObjectivesTypes"
    );
    setObjectivesConfig(objectivesConfig.data);
  }, []);

  const [missionDetails, setMissionDetails] = useState({
    mission: mission === "-1" ? "" : mission,
    name: "",
    status: "ENABLED",
    config: {
      expiration_days: 14,
      allowed_play_groups: [],
      allowed_view_groups: [],
      availables_view_groups: [],
      availables_play_groups: [],
      forbidden_play_groups: [],
      forbidden_view_groups: [],
      cms: {},
      images: "",
      objectives: [],
    },
    prize: 0,
    currency: "",
    init_date: utils.getDateTZTodayObj().getTime(),
    end_date: utils.getDateTZTodayObj().getTime(),
  });
  //HEADER
  const goBack = () => {
    props.history.push("/marketing/missions/missions");
  };
  const dataHeader = {
    backLink: goBack,
    title: t("Mission details"),
    urlHelp: "",
    idDoc: props.idDoc
  };
  //FIN HEADER
  //Carga de datos
  useEffect(
    async function () {
      if (missionDetails.mission !== "") {
        Promise.all([
          adminClient.getMissionDetails(
            session,
            company,
            missionDetails.mission
          ),
          adminClient.getGroups(session, company),
        ]).then(
          async function (result) {
            let groups = Object.values(result[1].groups);

            let aux = { ...missionDetails };
            aux = result[0].data;
            aux.config = JSON.parse(result[0].data.config);
            aux.init_date = utils.formatDate(result[0].data.init_date);
            aux.end_date = utils.formatDate(result[0].data.end_date);

            let playGroups = []; //Para guardar los que se han metido en play y asi luego no meterlos en availables
            let viewGroups = []; //Para guardar los que se han metido en view y asi luego no meterlos en availables
            aux.config.forbidden_play_groups =
              aux.config.forbidden_play_groups.map((groupI) => {
                const foundObject = groups.find(
                  (group) => group.group === groupI
                );
                if (foundObject) {
                  playGroups.push(groupI);
                  return foundObject;
                }
                return null;
              });

            aux.config.forbidden_view_groups =
              aux.config.forbidden_view_groups.map((groupI) => {
                const foundObject = groups.find(
                  (group) => group.group === groupI
                );
                if (foundObject) {
                  viewGroups.push(groupI);
                  return foundObject;
                }
                return null;
              });

            aux.config.allowed_play_groups = aux.config.allowed_play_groups.map(
              (groupI) => {
                const foundObject = groups.find(
                  (group) => group.group === groupI
                );
                if (foundObject) {
                  playGroups.push(groupI);
                  return foundObject;
                }
                return null;
              }
            );
            aux.config.allowed_view_groups = aux.config.allowed_view_groups.map(
              (groupI) => {
                const foundObject = groups.find(
                  (group) => group.group === groupI
                );
                if (foundObject) {
                  viewGroups.push(groupI);
                  return foundObject;
                }
                return null;
              }
            );

            aux.config.availables_view_groups = groups.filter(
              (group) =>
                group.company === company && !viewGroups.includes(group.group)
            );
            aux.config.availables_play_groups = groups.filter(
              (group) =>
                group.company === company && !playGroups.includes(group.group)
            );


            setMissionDetails(aux);
            setAddOrUpdate("update");
          },

          function (err) {
            console.error(err);
          }
        );
      } else {
        setAddOrUpdate("add");
      }
    },
    [addOrUpdate]
  );

  const onChangeHandler = (e) => {
    const { name, value } = e.target || e;
    setMissionDetails((prevMission) => ({ ...prevMission, [name]: value }));
  };
  const onChangeCurrencyHandler = (e) => {
    const { name, value } = e.target || e;
    setMissionDetails((prevMission) => ({ ...prevMission, [name]: value * 100 }));
  };


  const onChangeHandlerConfig = (e) => {
    const { name, value } = e.target || e;
    setMissionDetails((prevMission) => {
      let auxConfig = prevMission.config;
      auxConfig[name] = value;
      return { ...prevMission, ["config"]: auxConfig };
    });
  };

  const onChangeHandlerConfigCMS = (value) => {
    setMissionDetails((prevMission) => {
      let auxConfig = prevMission.config;
      auxConfig.cms = value;
      return { ...prevMission, ["config"]: auxConfig };
    });
  };

  const onChangeHandlerGroupsPlay = (allowed, forbidden) => {
    setMissionDetails((prevMission) => {
      let auxConfig = prevMission.config;
      auxConfig.allowed_play_groups = allowed;
      auxConfig.forbidden_play_groups = forbidden;

      return { ...prevMission, ["config"]: auxConfig };
    });
  };
  const onChangeHandlerGroupsView = (allowed, forbidden) => {
    setMissionDetails((prevMission) => {
      let auxConfig = prevMission.config;
      auxConfig.allowed_view_groups = allowed;
      auxConfig.forbidden_view_groups = forbidden;

      return { ...prevMission, ["config"]: auxConfig };
    });
  };

  const onChangeHandlerObjectives = (objectives) => {
    setMissionDetails((prevMission) => {
      let auxConfig = prevMission.config;
      auxConfig.objectives = objectives;
      return { ...prevMission, ["config"]: auxConfig };
    });
  };

  //Saves para files

  const saveDataMission = (urls) => {
    let mission = { ...missionDetails };
    if (mission.config.images === undefined || mission.config.images === null) {
      mission.config.images = [];
    } else {
      mission.config.images = urls;
    }
    setMissionDetails(mission);
  };

  //Manejadores modificacion
  const save = (evt) => {
    if (formValidation.validate()) {
      let missionDetailsAux = { ...missionDetails };
      missionDetailsAux.init_date = utils.unformatDate(
        new Date(missionDetailsAux.init_date)
      );
      missionDetailsAux.end_date = utils.unformatDate(
        new Date(missionDetailsAux.end_date)
      );

      missionDetailsAux.config = { ...missionDetailsAux.config };
      missionDetailsAux.config.forbidden_play_groups =
        missionDetailsAux.config.forbidden_play_groups.map(
          (group) => group.group
        );
      missionDetailsAux.config.forbidden_view_groups =
        missionDetailsAux.config.forbidden_view_groups.map(
          (group) => group.group
        );
      missionDetailsAux.config.allowed_play_groups =
        missionDetailsAux.config.allowed_play_groups.map(
          (group) => group.group
        );
      missionDetailsAux.config.allowed_view_groups =
        missionDetailsAux.config.allowed_view_groups.map(
          (group) => group.group
        );
      delete missionDetailsAux.config.availables_play_groups;
      delete missionDetailsAux.config.availables_view_groups;

      if (missionDetailsAux.prize_promotion === undefined || missionDetailsAux.prize_promotion === "") {
        missionDetailsAux.prize_promotion = null;
      }
      if (missionDetailsAux.prize_group === undefined || missionDetailsAux.prize_group === "") {
        missionDetailsAux.prize_group = null;
      }
      adminClient[addOrUpdate + "MissionDetails"](
        session,
        company,
        JSON.stringify(missionDetailsAux)
      ).then(
        (msg) => {
          if (msg.result === "OK") {
            props.showAlert(t("Mission details"), t("Saved succesfull"));
            if (addOrUpdate === "add") {
              mission = missionDetails.mission;
              setAddOrUpdate("update");
            }
          } else {
            props.showAlert(
              t("Mission details"),
              t("Saving error") + ": " + t(msg.description)
            );
          }
        },
        (error) => {
          props.showAlert(
            t("Mission details"),
            t("Saving error") + ": " + t(error.description)
          );
        }
      );
    }
  };
  const remove = (evt) => {
    adminClient
      .deleteMissionDetails(session, company, missionDetails.mission)
      .then(
        (msg) => {
          if (msg.result === "OK") {
            props.showAlert(t("Mission details"), t("Deleted succesfull"));
            goBack();
          } else {
            props.showAlert(
              t("Mission details"),
              t("Deleting error") + ": " + t(msg.description)
            );
          }
        },
        (error) => {
          props.showAlert(
            t("Mission details"),
            t("Deleting error") + ": " + t(error.description)
          );
        }
      );
  };

  let groupsPlayRender = () => {
    return (
      <>
        <AllowedForbiddenList
          allowed={missionDetails.config.allowed_play_groups}
          availables={missionDetails.config.availables_play_groups}
          forbidden={missionDetails.config.forbidden_play_groups}
          idProperty={"group"}
          nameProperty={"name"}
          handleUpdateGroups={onChangeHandlerGroupsPlay}
        ></AllowedForbiddenList>
      </>
    );
  };

  let groupsViewRender = () => {
    return (
      <>
        <AllowedForbiddenList
          allowed={missionDetails.config.allowed_view_groups}
          availables={missionDetails.config.availables_view_groups}
          forbidden={missionDetails.config.forbidden_view_groups}
          idProperty={"group"}
          nameProperty={"name"}
          handleUpdateGroups={onChangeHandlerGroupsView}
        ></AllowedForbiddenList>
      </>
    );
  };

  let objetivosRender = () => {
    return (
      <>
        <div className="row">
          <MissionObjectives
            objectives={missionDetails.config.objectives}
            t={props.t}
            objectivesConfig={objectivesConfig}
            machinesAvailables={machinesAvailables}
            showAlert={props.showAlert}
            handleCloseAlert={props.handleCloseAlert}
            handleObjectives={onChangeHandlerObjectives}
          />
        </div>
      </>
    );
  };

  let cmsRender = () => {
    return (
      <WebContentData
        details={missionDetails}
        cms={missionDetails.config.cms}
        id={missionDetails.mission}
        type="MISSION"
        pathImage="/cms/img/missions/"
        showAlert={props.showAlert}
        onChangeHandler={onChangeHandlerConfigCMS}
      />

    );
  };
  let imagesRender = () => {
    return (
      <>
        <div className="row">
          <File
            dropEnable={true}
            showFile={true}
            showError={true}
            viewerEnable={false}
            deleteEnable={true}
            saveData={saveDataMission}
            fileName={missionDetails.config.images}
            fileUrlPref={companyDetails.config.cdn_url + "../"}
            getFileUrl={(data) => {
              return (
                "/cms/img/missions/" +
                missionDetails.mission +
                "/" +
                data.fileName
              );
            }}
            type={"MISSION"}
            enableCopyUrl={true}
            showAlert={props.showAlert}
            owner={JSON.stringify(missionDetails)}
          />
        </div>
      </>
    );
  };

  let prizesRender = () => {
    return (
      <>
        <div className="row">
          <div className="col-12 col-md-4">
            <TextField
              label={t("Prize")}
              name="prize"
              required
              type="number"
              value={missionDetails.prize !== undefined && missionDetails.prize !== '' ? missionDetails.prize / 100 : 0}
              onChange={onChangeCurrencyHandler}
            />
          </div>
          <div className="col-12 col-md-4">
            <BasicSelect
              idProperty='promotion'
              nameProperty='promotion'
              basicInfoName='Promotions'
              value={missionDetails.prize_promotion}
              name='prize_promotion'
              label={t('Promotions')}
              onChange={onChangeHandler}
              multiple={false}
              noEmptyValue={false}
              disableClearable={false}
            />
          </div>
          <div className="col-12 col-md-4">
            <BasicSelect
              idProperty='group'
              basicInfoName='Groups'
              value={missionDetails.prize_group}
              name='prize_group'
              label={t('Groups')}
              onChange={onChangeHandler}
              multiple={false}
              noEmptyValue={false}
              disableClearable={false}
            />
          </div>
        </div>
      </>
    );
  };


  const [tabsContent, setTabsContent] = useState([
    { title: t("Objectives"), content: objetivosRender },
    { title: t("Play Groups"), content: groupsPlayRender },
    { title: t("View Groups"), content: groupsViewRender },
    { title: t("CMS"), content: cmsRender },
    { title: t("Images"), content: imagesRender },
    { title: t("Prizes"), content: prizesRender }
  ]);
  useEffect(() => {
    let tabAux = [
      { title: t("Objectives"), content: objetivosRender },
      { title: t("Play Groups"), content: groupsPlayRender },
      { title: t("View Groups"), content: groupsViewRender },
      { title: t("CMS"), content: cmsRender },
      { title: t("Images"), content: imagesRender },
      { title: t("Prizes"), content: prizesRender }
    ];
    setTabsContent(tabAux);
  }, [JSON.stringify(missionDetails), addOrUpdate, JSON.stringify(machinesAvailables)]);


  return (
    <LayoutSection {...props} dataHeader={dataHeader}>
      <form id="Form_MissionDetails">
        <div className="row">
          <div className="col-12 col-md-4">
            <TextField
              label={t("ID")}
              name="mission"
              required
              disabled={addOrUpdate === 'update'}
              // inputProps={addOrUpdate === 'add' && { "data-parsley-pattern": "^[a-zA-Z0-9]+$", "data-parsley-pattern-message": t('validationID') }}
              type="text"
              value={missionDetails.mission}
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-12 col-md-4">
            <TextField
              label={t("Name")}
              name="name"
              required
              type="text"
              value={missionDetails.name}
              onChange={onChangeHandler}
            />
          </div>
          <div className="col-12 col-md-2">
            <BasicSelect
              idProperty="name"
              basicInfoName="EnabledDisabled"
              value={missionDetails.status}
              name="status"
              label={t("Status")}
              onChange={onChangeHandler}
              translate={{ property: "name", prefix: "" }}
              multiple={false}
              noEmptyValue={true}
              disableClearable={false}
            />
          </div>
          <div className="col-12 col-md-2">
            <BasicSelect
              idProperty="currency"
              nameProperty="currency"
              data="currencies"
              basicInfoName="CurrenciesCompany"
              value={missionDetails.currency}
              name="currency"
              label={t("Currency")}
              onChange={onChangeHandler}
              multiple={false}
              noEmptyValue={true}
              disableClearable={false}
            />
          </div>
          <div className="col-12 col-md-2">
            <TextField
              label={t("Expiration days")}
              name="expiration_days"
              required
              type="text"
              value={missionDetails.config.expiration_days}
              onChange={onChangeHandlerConfig}
            />
          </div>
          <div className="col-12 col-md-4">
            <DateAndTimePicker
              label={t("Init Date")}
              fieldName="init_date"
              value={missionDetails.init_date}
              showTime={true}
              callBackFunction={(value, fieldName) => {
                onChangeHandler({ target: { name: fieldName, value: value } });
              }}
              id={"init_date"}
            />
          </div>
          <div className="col-12 col-md-4">
            <DateAndTimePicker
              label={t("End Date")}
              fieldName="end_date"
              value={missionDetails.end_date}
              showTime={true}
              callBackFunction={(value, fieldName) => {
                onChangeHandler({ target: { name: fieldName, value: value } });
              }}
              validations={
                {
                  "data-parsley-validate-date-range": "init_date",
                  "data-parsley-validate-date-range-message":
                    t("PARSLEY_VALIDATION_DATE_RANGE")
                }
              }
            />
          </div>
        </div>
      </form>
      <div className="row">
        <div className="col-12">
          <button type="button" className="btn btn-primary" onClick={save}>
            {t("Save")}
          </button>
          {addOrUpdate === "update" && (
            <button type="button" className="btn btn-primary" onClick={remove}>
              {t("Delete")}
            </button>
          )}
        </div>
        {addOrUpdate === 'update' &&
          <Tab active={0}>
            {tabsContent.map((tab, idx) => (
              <Tab.TabPane key={`Tab-${idx}`} tab={tab.title}>
                {tab.content()}
              </Tab.TabPane>
            ))}
          </Tab>}
      </div>
    </LayoutSection>
  );
}
export default withTranslation()(MissionDetails);
