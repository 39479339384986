
import React, { useMemo } from "react";
import CustomRenders from "../context/customRenders";

var renderReports = new CustomRenders()

export function cellTableRender(props, t) {
    return cellTableRenderInner(props.value, props.column.typeCell, props.row.original, t, props.column)
}
export function cellTableRenderInner(cellValue, typeCell, rowOriginal, t, cellProps) {

    if (cellValue !== null && cellValue !== undefined) {

        switch (typeCell) {
            case 'time': return renderReports.getTimeRender(cellValue, rowOriginal, t, cellProps)
            case 'date': return renderReports.getDateRender(cellValue, rowOriginal, t, cellProps)
            case 'currency': return renderReports.getCurrencyRender(cellValue, rowOriginal, t, cellProps)
            case 'currenciesSecondary': return renderReports.getCurrencyRender(cellValue, rowOriginal, t, cellProps, true)
            case 'integer': return renderReports.getIntegerRender(cellValue, rowOriginal, t, cellProps)
            case 'percentage': return renderReports.getPercentageRender(cellValue, rowOriginal, t, cellProps)
            case 'permil': return renderReports.getPerMilRender(cellValue, rowOriginal, t, cellProps)
            case 'pertenmil': return renderReports.getPerTenMilRender(cellValue, rowOriginal, t, cellProps)
            case 'translate': return renderReports.getTranslateRender(cellValue, rowOriginal, t, cellProps)
            case 'text_translated': return renderReports.getTextTranslateRender(cellValue, rowOriginal, t, cellProps)
            case 'image': return renderReports.getImageCMSRender(cellValue, rowOriginal, t, cellProps)
            case 'basicInfo': return renderReports.getBasicInfoRender(cellValue, rowOriginal, t, cellProps)
            case 'custom': return renderReports.getCustomRender(cellValue, rowOriginal, t, cellProps)
            case 'light': return renderReports.getLightRender(cellValue, rowOriginal, t, cellProps)
            default: return <div className={cellProps.className}>{cellValue}</div>;
        }
    } else return ''

}

export async function getRendersInfo({ config: { table: confTable } }) {
    let { renders } = confTable;
    return await renderReports.loadPreviousInfoRender(renders)
}
export async function loadRenderInfo(render) {
    return await renderReports.loadInfoRender(render)
}

function getTypeCell(style) {
    if (style.indexOf('image') >= 0) {
        return 'image'
    } else if (style.indexOf('text_translated') >= 0) {
        return 'text_translated'

    } else if (style.indexOf('currency') >= 0) {
        return 'currency'

    } else if (style.indexOf('date') >= 0) {
        return 'date'

    } else if (style.indexOf('time') >= 0) {
        return 'time'

    } else if (style.indexOf('percentage') >= 0) {
        return 'percentage'

    } else return style
}

export function generateColumnsDef({ config: { table: confTable } }, t, extraData = {}, showAlert, handleCloseAlert) {

    let { fields, labels, styles, sort_fields, totals, renders, details } = confTable;
    if (sort_fields === undefined) sort_fields = []
    if (details === undefined) details = []
    if (totals === undefined) totals = {}
    let columnsDef = [];
    for (let i = 0; i < fields.length; i++) {
        let detail = details[i]
        if (detail !== undefined) {
            detail = JSON.parse(detail.replace(/'/g, '"'))
        }
        else {
            detail = { type: "text" }
        }
        columnsDef.push({
            Header: t(labels[i]),
            accessor: fields[i],
            typeCell: getTypeCell(styles[i]),
            className: styles[i],
            numberCell: i,
            details: detail,
            filter: getTypeCell(styles[i]),
            renderData: renders === undefined ? '' : renders[fields[i]],
            extraData: extraData,
            t: t,
            showAlert: showAlert,
            handleCloseAlert: handleCloseAlert,
            defaultCanSort: sort_fields.includes(fields[i]) ? true : false,
            Footer: (props) => {


                const { originalRow, total } = useMemo(() =>
                    props.rows.reduce((value, row) => {
                        const { globalCurrency, originalRow, total, showTotal } = value

                        if (totals[fields[i]] === "currency") {
                            if (globalCurrency == "") {
                                value.globalCurrency = row.original.currency
                            }
                            if (showTotal && (row.original.currency === null || row.original.currency === undefined)) {
                                value.showTotal = false
                                value.total = undefined

                            }
                            if (showTotal && value.globalCurrency !== row.original.currency) {
                                value.showTotal = false
                                value.total = undefined
                            }
                        }

                        if (value.total !== undefined) value.total = total + row.values[fields[i]]
                        value.originalRow = row.original

                        return value
                    }, { globalCurrency: "", originalRow: {}, total: 0, showTotal: true }), [props.rows]
                )

                if (totals[fields[i]] !== undefined && total !== undefined) {
                    return <><strong>{cellTableRenderInner(total, totals[fields[i]], originalRow, t, props.column)}</strong></>
                }
                else return ""

            }
        })
    }
    return columnsDef;

}
