import React, { useCallback, useEffect, useState } from "react"
import { withRouter } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import utils from '../../utils';
import AdminClient from "../../AdminClient";
import BasicSelect from "../../components/basic/BasicSelect";
import { TextField, Checkbox, FormControlLabel } from "@mui/material";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import useFormValidation from "../../hooks/parsleyValidation";
import BasicTable from "../../components/basic/BasicTable";
import DateAndTimePicker from "../../components/DateAndTimePicker";
import moment from 'moment';
const momentTimeZone = require('moment-timezone');

const UserSearch = (props) => {

    const { t } = props
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const { formValidation } = useFormValidation('Form_UserSearch')

    const company = utils.getCurrentCompany().company;
    const initDetails = {
        memberOf: [],
        notMemberOf: [],
        freeText: "",
        alias: "",
        email: "",
        mobile: "",
        user: '',
        agent: 0,
        nationalID: "",
        firstname: "",
        lastname: "",
        verified: "",
        unverified: "",
        registerFrom: null,
        registerTo: null
    }
    const [searchDetails, setSearchDetails] = useState(initDetails);
    const [searchDisabled, setSearchDisabled] = useState(false);

    const [result, setResult] = useState(<table></table>);

    const adminClient = new AdminClient()
    const handleKeyDown = useCallback((e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            search();
        }
    }, [searchDetails]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        }
    }, [searchDetails]);

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setSearchDetails((prevState) => ({ ...prevState, [name]: value }));
    };

    const onChangeHandlerCheckbox = (e) => {
        const { name, checked } = e.target || e;
        setSearchDetails((prevState) => ({ ...prevState, [name]: checked }));
    };

    const handleDetailsRow = (row) => {
        utils.setSessionItem("current-user", row.original.db + "." + row.original.user);
        window.open('/support/user_details/' + row.original.user, '_blank');
    }
    const usersList = (data) => {
        if (data.length > 0) {
            let dataTable = data;
            let columnsTable = []
            adminClient.getConfiPlayerData(session, company).then((data) => {
                let hiddenFields = data.data.hiddenFields || []

                columnsTable.push(
                    {
                        Header: t("DB"), accessor: 'db', typeCell: 'text', filter: 'text', Filter: true,
                        Cell: (props) => {
                            return props.row.original.db
                        }
                    }
                )

                columnsTable.push(
                    {
                        Header: t("Identifier"), accessor: 'user', typeCell: 'text', filter: 'text', Filter: true,
                        Cell: (props) => {
                            return props.row.original.user
                        }
                    }
                )

                if (!hiddenFields.includes("alias")) {
                    columnsTable.push({ Header: t("Username"), accessor: 'alias', typeCell: 'text', filter: 'text', Filter: true })
                }
                if (!(hiddenFields.includes("first_name") && hiddenFields.includes("last_name"))) {
                    columnsTable.push({
                        Header: t("Name"), accessor: 'name', typeCell: 'text', filter: 'text', Filter: true,
                        Cell: (props) => {
                            let first_name = hiddenFields.includes("first_name") ? '' : props.row.original.first_name;
                            let last_name = hiddenFields.includes("last_name") ? '' : props.row.original.last_name;
                            let middle_name = props.row.original.middle_name;
                            return first_name + " " + last_name + " " + (middle_name !== null && middle_name !== undefined ? middle_name : "")
                        }
                    })
                }
                if (!(hiddenFields.includes("mobile") && hiddenFields.includes("mobile"))) {
                    columnsTable.push({ Header: t("Mobile"), accessor: 'mobile', typeCell: 'text', filter: 'text', Filter: true })
                }
                if (!hiddenFields.includes("email")) {
                    columnsTable.push({ Header: t("Email"), accessor: 'email', typeCell: 'text', filter: 'text', Filter: true })
                }
                if (!(hiddenFields.includes("national_id") && hiddenFields.includes("national_id"))) {
                    columnsTable.push({ Header: t("National ID"), accessor: 'national_id', typeCell: 'text', filter: 'text', Filter: true })
                }
                if (!hiddenFields.includes("created_date")) {
                    columnsTable.push({ Header: t("Created Date"), accessor: 'created_date', typeCell: 'time', filter: 'time', Filter: true })
                }
                if (!(hiddenFields.includes("verified") && hiddenFields.includes("verified"))) {
                    columnsTable.push({
                        Header: t("Verified"), accessor: 'verified', typeCell: 'text', filter: 'text_translated', Filter: true,
                        Cell: (props) => {
                            let verified = props.row.original.verified;
                            let htmlValue = t("SEARCH_VERIFIED_USER_" + verified)
                            return <div dangerouslySetInnerHTML={{ __html: htmlValue }} />
                        },
                    })
                }
                if (!hiddenFields.includes("regulatory_status")) {
                    columnsTable.push({ Header: t("Status"), accessor: 'regulatory_status', typeCell: 'text_translated', filter: 'text_translated', Filter: true })
                }

                setResult(<BasicTable data={dataTable} columns={columnsTable} rowHandle={handleDetailsRow} showFilterColumn={true} />);



            }, (err) => {
                console.log("Error al obtener la configuración de campos")
            })

        } else {
            setResult(<div className="no_data_found">{t("No data found")}</div>);
        }
    }

    function generateCSV() {
        showLoading()
        let clientAPI = new AdminClient();
        let verified = '';
        if (searchDetails.verified && (searchDetails.unverified === undefined || !searchDetails.unverified)) {
            verified = true;
        }
        if (searchDetails.unverified && (searchDetails.verified === undefined || !searchDetails.verified)) {
            verified = false;
        }

        let user = searchDetails.user;
        if (searchDetails.user === '' || searchDetails.user === 'undefined') {
            user = undefined;
        }

        let agent = searchDetails.agent;
        if (searchDetails.agent === '' || searchDetails.agent === 'undefined') {
            agent = undefined;
        }

        clientAPI.getCSVUserSearch(
            JSON.parse(utils.getSessionItem("user")).session,
            utils.getSessionItem('Company'),
            searchDetails.freeText,
            searchDetails.alias,
            user,
            searchDetails.email,
            searchDetails.firstname,
            searchDetails.lastname,
            searchDetails.memberOf,
            searchDetails.notMemberOf,
            verified,
            searchDetails.registerFrom ? utils.unformatDate(searchDetails.registerFrom) : null,
            searchDetails.registerTo ? utils.unformatDate(searchDetails.registerTo) : null,
            searchDetails.mobile,
            searchDetails.nationalID,
            agent
        ).then(


            function (resp) {
                closeLoading()
                let a = document.createElement("a");
                var file = new Blob([resp], { type: 'text/csv; charset=UTF-8' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL
                a.download = "search.csv";
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }, function (datos) {
                //Mostramos el error en la respuesta 
                console.log("HAY UN ERROR")
            }
        );
    }

    function generateExcel() {
        showLoading()
        let clientAPI = new AdminClient();
        let memberOf = [];
        searchDetails.memberOf.forEach(element => {
            memberOf.push(element.group);
        });
        let notMemberOf = [];
        searchDetails.notMemberOf.forEach(element => {
            notMemberOf.push(element.group);
        });

        let verified = '';
        if (searchDetails.verified && (searchDetails.unverified === undefined || !searchDetails.unverified)) {
            verified = true;
        }
        if (searchDetails.unverified && (searchDetails.verified === undefined || !searchDetails.verified)) {
            verified = false;
        }

        let user = searchDetails.user;
        if (searchDetails.user === '' || searchDetails.user === 'undefined') {
            user = undefined;
        }

        let agent = searchDetails.agent;
        if (searchDetails.agent === '' || searchDetails.agent === 'undefined') {
            agent = undefined;
        }

        clientAPI.getExcelUserSearch(
            JSON.parse(utils.getSessionItem("user")).session,
            utils.getSessionItem('Company'),
            searchDetails.freeText,
            searchDetails.alias,
            user,
            searchDetails.email,
            searchDetails.firstname,
            searchDetails.lastname,
            searchDetails.memberOf,
            searchDetails.notMemberOf,
            verified,
            searchDetails.registerFrom ? utils.unformatDate(searchDetails.registerFrom) : null,
            searchDetails.registerTo ? utils.unformatDate(searchDetails.registerTo) : null,
            searchDetails.mobile,
            searchDetails.nationalID,
            agent
        ).then(


            function (resp) {
                closeLoading()
                let a = document.createElement("a");
                var file = new Blob([resp], { type: 'application/xls' });
                var fileURL = URL.createObjectURL(file);
                a.href = fileURL
                a.download = "search.xls";
                a.style.display = "none";
                document.body.appendChild(a);
                a.click();
            }, function (datos) {
                //Mostramos el error en la respuesta 
                console.log("HAY UN ERROR")
            }
        );

    }
    const generateExcelURL = () => {

        let memberOf = [];
        searchDetails.memberOf.forEach(element => {
            memberOf.push(element.group);
        });
        let notMemberOf = [];
        searchDetails.notMemberOf.forEach(element => {
            notMemberOf.push(element.group);
        });

        let verified;
        if (searchDetails.verified && (searchDetails.unverified === undefined || !searchDetails.unverified)) {
            verified = true;
        }
        if (searchDetails.unverified && (searchDetails.verified === undefined || !searchDetails.verified)) {
            verified = false;
        }

        let user = searchDetails.user;
        if (searchDetails.user === '' || searchDetails.user === 'undefined') {
            user = undefined;
        }


        let excelURL = adminClient.userSearchExcel(
            JSON.parse(utils.getSessionItem("user")).session,
            utils.getSessionItem('Company'),
            searchDetails.freeText,
            searchDetails.alias,
            user,
            searchDetails.email,
            searchDetails.firstname,
            searchDetails.lastname,
            searchDetails.memberOf,
            searchDetails.notMemberOf,
            verified,
            searchDetails.registerFrom ? utils.unformatDate(searchDetails.registerFrom) : null,
            searchDetails.registerTo ? utils.unformatDate(searchDetails.registerTo) : null,
            searchDetails.mobile,
            searchDetails.nationalID,
            searchDetails.agent
        );


        return excelURL;
    }
    const generateCSVURL = () => {



        let verified;
        if (searchDetails.verified && (searchDetails.unverified === undefined || !searchDetails.unverified)) {
            verified = true;
        }
        if (searchDetails.unverified && (searchDetails.verified === undefined || !searchDetails.verified)) {
            verified = false;
        }

        let user = searchDetails.user;
        if (searchDetails.user === '' || searchDetails.user === 'undefined') {
            user = undefined;
        }



        let CSVUrl = adminClient.userSearchCSV(
            JSON.parse(utils.getSessionItem("user")).session,
            utils.getSessionItem('Company'),
            searchDetails.freeText,
            searchDetails.alias,
            user,
            searchDetails.email,
            searchDetails.firstname,
            searchDetails.lastname,
            searchDetails.memberOf,
            searchDetails.notMemberOf,
            verified,
            searchDetails.registerFrom ? utils.unformatDate(searchDetails.registerFrom) : null,
            searchDetails.registerTo ? utils.unformatDate(searchDetails.registerTo) : null,
            searchDetails.mobile,
            searchDetails.nationalID,
            searchDetails.agent
        );


        return CSVUrl;
    }
    const showLoading = () => {
        if (props.showAlert) props.showAlert("LOADING", t("Searching..."), true);
    }
    const closeLoading = () => {
        if (props.handleCloseAlert) props.handleCloseAlert()
    }
    const search = () => {
        if (formValidation?.validate()) {
            showLoading();
            setSearchDisabled(true);

            let verified;
            if (searchDetails.verified && (searchDetails.unverified === undefined || !searchDetails.unverified)) {
                verified = true;
            }
            if (searchDetails.unverified && (searchDetails.verified === undefined || !searchDetails.verified)) {
                verified = false;
            }
            let user = searchDetails.user;
            if (searchDetails.user === '' || searchDetails.user === 'undefined') {
                user = undefined;
            }
            let agent = searchDetails.agent
            if (searchDetails.agent === "") {
                agent = undefined
            }

            adminClient.userSearch(
                session,
                company,
                searchDetails.freeText,
                searchDetails.alias,
                user,
                searchDetails.email,
                searchDetails.firstname,
                searchDetails.lastname,
                searchDetails.memberOf,
                searchDetails.notMemberOf,
                verified,
                searchDetails.registerFrom ? utils.unformatDate(searchDetails.registerFrom) : null,
                searchDetails.registerTo ? utils.unformatDate(searchDetails.registerTo) : null,
                searchDetails.mobile,
                searchDetails.nationalID,
                agent
            ).then(
                function (msg) {
                    console.log(" Users  = " + JSON.stringify(msg));
                    closeLoading();
                    usersList(msg.users);
                    setSearchDisabled(false);

                }, function (err) {
                    console.log("reportPromise error " + err.code);
                }
            );
        }
    }

    const downloadFile = (title) => {
        let link;
        if (title === "csv") {
            //link = generateCSVURL()
            generateCSV()
        }
        else if (title === "excel") {
            //link = generateExcelURL()
            generateExcel()
        }
        /* let element = document.createElement('a');
         element.setAttribute('href', link);
 
         element.style.display = 'none';
         document.body.appendChild(element);
 
         element.click();
 
         document.body.removeChild(element);*/
    }

    const clear = () => {
        setSearchDetails(initDetails)
    }


    const goBack = () => {
        props.history.goBack();
    };
    const dataHeader = {
        backLink: goBack,
        title: t("User Search"),
        urlHelp: "",
        idDoc: props.idDoc
    };
    return (<>
        <LayoutSection {...props} dataHeader={dataHeader}>

            <div className="content m-2 row">
                <div className="col-12 col-md-4">
                    <TextField tabIndex={1} label={t("Enter search term")} type="text" value={searchDetails.freeText} name="freeText" onChange={onChangeHandler} />
                </div>
                <div className="col-6  col-md-2" >
                    <button disabled={searchDisabled} tabIndex={1} type="button" className="btn btn-secondary  w-100" onClick={clear}>{t("Clear")}</button>
                </div>
                <div className="col-6  col-md-2" >
                    <button disabled={searchDisabled} tabIndex={2} type="button" className="btn btn-primary w-100" onClick={search}>{t("Search")}</button>
                </div>

                {utils.isGranted('EXPORT_USERS_SEARCH') && <>
                    <div className="col-6  col-md-2" >
                        <button disabled={searchDisabled} tabIndex={3} type="button" className="btn btn-secondary  w-100" onClick={() => downloadFile("excel")}>{t("Excel")}</button>
                    </div>
                    <div className="col-6  col-md-2" >
                        <button disabled={searchDisabled} tabIndex={3} type="button" className="btn btn-secondary  w-100" onClick={() => downloadFile("csv")}>{t("CSV")}</button>
                    </div>
                </>
                }
            </div>
            <form id="Form_UserSearch">

                <div className="content row m-0 pt-0">
                    <div className="col-12 col-md-3 col-xl-2">
                        <TextField tabIndex={5} label={t("ID")} value={searchDetails.user} type="number" name="user" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-3 col-xl-2">
                        <TextField tabIndex={6} label={t("Alias")} value={searchDetails.alias} type="text" name="alias" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-3 col-xl-4">
                        <TextField tabIndex={7} label={t("Email")} value={searchDetails.email} type="text" name="email" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-3 col-xl-2">
                        <TextField tabIndex={8} label={t("Mobile")} value={searchDetails.mobile} type="text" name="mobile" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-3 col-xl-2">
                        <TextField tabIndex={9} label={t("National ID")} value={searchDetails.nationalID} type="text" name="nationalID" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-3 col-xl-2">
                        <TextField tabIndex={10} label={t("First name")} value={searchDetails.firstname} type="text" name="firstname" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-3 col-xl-2">
                        <TextField tabIndex={11} label={t("Last name")} value={searchDetails.lastname} type="text" name="lastname" onChange={onChangeHandler} />
                    </div>
                    <div className="col-12 col-md-3 col-xl-2">
                        <BasicSelect
                            idProperty="user"
                            name="agent"
                            label={t("Agent")}
                            basicInfoName="Agents"
                            onChange={onChangeHandler}
                            value={searchDetails.agent}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <FormControlLabel labelPlacement="top" control={<Checkbox name="verified" onChange={onChangeHandlerCheckbox}
                            checked={searchDetails.verified} />} label={t("Verified")} />
                        <FormControlLabel labelPlacement="top" control={<Checkbox name="unverified" onChange={onChangeHandlerCheckbox}
                            checked={searchDetails.unverified} />} label={t("Unverified")} />
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                        <DateAndTimePicker
                            label={t("Register from")}
                            value={searchDetails.registerFrom}
                            showTime={true}
                            callBackFunction={(value) => {
                                onChangeHandler({ target: { name: "registerFrom", value } });
                            }}
                        />
                    </div>
                    <div className="col-12 col-md-6 col-xl-6">
                        <DateAndTimePicker
                            label={t("Register to")}
                            value={searchDetails.registerTo}
                            showTime={true}
                            callBackFunction={(value) => {
                                onChangeHandler({ target: { name: "registerTo", value: value } });
                            }}
                        />

                    </div>
                    <div className="col-12 ">
                        <BasicSelect
                            idProperty='group'
                            basicInfoName='Groups'
                            value={searchDetails.memberOf}
                            name='memberOf'
                            label={t('Member of')}
                            onChange={onChangeHandler}
                            multiple={true}
                            noEmptyValue={false}
                            disableClearable={false}
                        />
                    </div>
                    <div className="col-12 ">
                        <BasicSelect
                            idProperty='group'
                            basicInfoName='Groups'
                            name='notMemberOf'
                            value={searchDetails.notMemberOf}
                            label={t('Not member of')}
                            onChange={onChangeHandler}
                            multiple={true}
                            noEmptyValue={false}
                            disableClearable={false}
                        />
                    </div>

                </div>
            </form>
            {result}


        </LayoutSection >
    </>
    )
}

export default withTranslation()(withRouter(UserSearch))