import React from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import CreateAgent from '../../components/agents/CreateAgent';




function NewAgent(props) {

    const dataHeader = {
        title: props.t('New agent'),
        urlHelp: '',
        idDoc: props.idDoc
    };
    //FIN HEADER


    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>
                <CreateAgent {...props} />
            </LayoutSection>
        </>
    )
}
export default withTranslation()(NewAgent);