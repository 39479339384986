import React, { useState } from "react";
import { Box, Button, List, ListItem, TextField, Typography } from "@mui/material";
import AdminClient from "../AdminClient";
import useFormValidation from "../hooks/parsleyValidation";
import utils from "../utils";


function NotificationAdminAgents({ items, t, showAlert, closeAlert, finishSending,sendNotification }) {
    const company = utils.getSessionItem("Company");
    const session = JSON.parse(utils.getSessionItem("user")).session;
    const adminClient = new AdminClient();
    const [title, setTitle] = useState("");
    const [text, setText] = useState("");
    const { formValidation } = useFormValidation("Form_agentsForNotification");
  
    const handleSend = () => {
      if (formValidation.validate()) {
        let agentsForNotification = items.map((i) => {
          let agentNotification = {};
          agentNotification.agent = i.user;
          agentNotification.selectedType = i.selected||'node';
          return agentNotification;
        });
        adminClient[sendNotification]
          (
            session,
            company,
            JSON.stringify(agentsForNotification),
            title,
            text
          )
          .then(
            function (data) {
              if (data.result === "OK") {
                showAlert(t("Notifications"), t("Notification sent"), [
                  <button type="button"                  
                    className="btn btn-primary"
                    onClick={(evt) => {
                      finishSending();
                      closeAlert();
                    }}
                  >
                    {t("Ok")}
                  </button>,
                ]);
              } else
                showAlert(
                  t("Notifications"),
                  t("Notification sent error") + data.description
                );
            },
            function (err) {
              showAlert(
                t("Notifications"),
                t("Notification sent error") + err.description
              );
            }
          );
      }
    };
  
    return (
      <Box
        display="flex"
        alignItems="center"
        gap={2}
        padding={2}
        sx={{
          flexDirection: { xs: "column", sm: "row" }, // Cambia a columna en móvil
          alignItems: { xs: "flex-start", sm: "center" }, // Alinea a la izquierda en móvil
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{ flex: { xs: 1, sm: 3 }, width: { xs: "100%", sm: "auto" } }}
        >
          <form id="Form_agentsForNotification">
            <TextField
              className="hide"
              label="Título"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              variant="outlined"
              sx={{ marginBottom: 1 }} // Espacio entre título y mensaje
            />
  
            <TextField
              label={t("Notification")}
              multiline
              required
              rows={3}
              value={text}
              onChange={(e) => setText(e.target.value)}
              variant="outlined"
            />
          </form>
        </Box>
  
        <Box
          className="selectedAgents"
          sx={{
            flex: { xs: 1, sm: 4 },
            width: { xs: "100%", sm: "auto" },
            height: "200px",
            border: "1px solid #ccc",
            padding: 1,
            overflowY: "auto",
          }}
        >
          <List dense>
            {items.map((item, index) => (
              <ListItem key={index}>
                <Typography variant="body2">
                  <strong>{item.alias}{" "}</strong>
                  
                    {item.selected === "node"
                      ? <span>{"- "+ t("Agent")}</span>
                      : item.selected === "children"
                      ? <span>{"- "+ t("SubAgents of")}</span>
                      : item.selected === "nodechildren"
                      ? <span>{"- "+ t("All")}</span>
                      : ""}
                  
                </Typography>
              </ListItem>
            ))}
          </List>
        </Box>
  
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          sx={{
            flex: { xs: 1, sm: "none" },
            width: { xs: "100%", sm: "auto" },
            minWidth: "auto",
            padding: "8px 16px",
          }}
        >
          {t("Send")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={finishSending}
          sx={{
            flex: { xs: 1, sm: "none" },
            width: { xs: "100%", sm: "auto" },
            minWidth: "auto",
            padding: "8px 16px",
          }}
        >
          {t("Clear")}
        </Button>
      </Box>
    );
  }

export default (NotificationAdminAgents)