import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import UploaderField from '../../components/basic/UploaderField';
import ProgressBar from '../../components/basic/ProgressBar';

function ShopsUpdateBatch(props) {
    const company = utils.getSessionItem('Company');
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_ShopsUpdateBatch')
    const [onProgress, setOnProgress] = useState(false);
    const [barProgress, setBarProgress] = useState({ show: false, text: "", percentage: "0%", textOK: false, textNOK: false })

    const [shops, setShops] = useState({
        shops: "",
        status: "",
        results: [],
        resultsCSV: "",
        resultsCSVShops: ""
    });

    const goBack = () => {
        props.history.push('/home');
    };

    const dataHeader = {
        backLink: goBack,
        title: t('Shops update batch'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(function () {

    }, []);

    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setShops((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleResponse = (data) => {
        let percentage = (data.partial * 100) / data.total;
        let text = t(data.text)

        setBarProgress(prevBarProgress => {
            let auxBarProgress = { ...prevBarProgress }
            auxBarProgress.show = true;
            auxBarProgress.percentage = percentage + '%';
            auxBarProgress.text = t(text) + ": " + data.partial + "/" + data.total;
            if (data.textOK) auxBarProgress.textOK = data.textOK + " " + t("Shops update correct")
            if (data.textNOK) auxBarProgress.textNOK = JSON.parse(data.textNOK).length !== 0 ? data.textNOK + " " + t("Shops update errors") + ": " + JSON.stringify(data.results) : false
            const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
            return updatedBarProgress;
        });

    };

    const handleSend = () => {
        if (formValidation.validate()) {
            const shopsArray = shops.shops.split(/\r?\n/).map(Number);
            props.showAlert(t("Shops update"), <p>{t("We are going to process") + " " + shopsArray.length + " " + t("shops")}</p>,
                [<button type="button" className="btn btn-secondary" onClick={props.handleCloseAlert}>{t("Cancel")}</button>,
                <button type="button" className="btn btn-primary"
                    onClick={(evt) => {
                        send();
                        props.handleCloseAlert()
                    }}>{t("Ok")}</button>]
            );
        }

    }

    const send = (file) => {
        if (file !== undefined || formValidation.validate()) {
            setBarProgress(prevBarProgress => {
                let auxBarProgress = { ...prevBarProgress }
                auxBarProgress.show = true;
                auxBarProgress.percentage = '0%'
                auxBarProgress.text = t('Updating shops')
                auxBarProgress.textOK = false;
                auxBarProgress.textNOK = false;
                return { ...prevBarProgress, ...auxBarProgress };
            });
            onChangeHandler({ name: "results", value: [] });
            setOnProgress(true)
            let shopsArray = [];
            if (file === undefined) {
                shopsArray = shops.shops.split(/\r?\n/).map(Number);
            }
            adminClient.shopsUpdateBatch(session, company, JSON.stringify(shopsArray), shops.status, handleResponse, file).then(
                (msg) => {
                    console.log('respuesta del batch: ' + JSON.stringify(msg))

                    let resultsCSV = "";
                    let resultsCSVShops = "";

                    for (let idx in msg.results) {
                        let operation = msg.results[idx];
                        if (operation.db === undefined) {
                            resultsCSVShops += operation.operation + "\r\n"
                            resultsCSV += operation.operation + "\t" + 0 + "\t" + operation.status + "\r\n";
                        } else {
                            resultsCSVShops += operation.db + "." + operation.operation + "\r\n"
                            resultsCSV += operation.db + "." + operation.operation + "\t" + operation.amount / 100 + "\t" + operation.status + "\t" + operation.db + "." + operation.user + "\r\n";
                        }

                    }

                    setOnProgress(false);
                    setBarProgress(prevBarProgress => {
                        let auxBarProgress = { ...prevBarProgress }
                        auxBarProgress.percentage = '100%'
                        auxBarProgress.show = true;
                        auxBarProgress.text = t('All shops updated')
                        const updatedBarProgress = { ...prevBarProgress, ...auxBarProgress };
                        return updatedBarProgress;
                    });
                },
                (error) => {
                    setOnProgress(false);
                    props.showAlert(t('Error'), t('Saving error ') + t(error.description))
                }
            )
        }

    }

    return (
        <LayoutSection {...props} dataHeader={dataHeader}>
            <form id="Form_ShopsUpdateBatch">
                <div className="row">
                    <div className="col-12 ">
                        <BasicSelect
                            label={t("Status")}
                            idProperty="status"
                            name="status"
                            basicInfoName="ShopStatus"
                            onChange={onChangeHandler}
                            value={shops.status}
                            translate={{ property: "name", prefix: '' }}
                            noEmptyValue={true}
                            disableClearable={true}
                            multiple={false}
                        />
                    </div>
                    <div className="col-12 ">
                        <TextField required multiline label={t("Shops")} name="shops" value={shops.shops} onChange={onChangeHandler} placeholder="shop1&#10;shop2&#10;shop3 "></TextField>
                    </div>
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={
                            (evt) => {
                                handleSend()
                            }
                        }
                        disabled={onProgress}
                    >
                        {t('Send')}
                    </button>
                    <UploaderField
                        label="CSV"
                        acceptedFiles=".csv"
                        showAlert={props.showAlert}
                        handleCloseAlert={props.handleCloseAlert}
                        callBackFunction={send}
                        disabled={onProgress}
                    />
                </div>
                <div className='col-12'>
                    {barProgress.show &&
                        <ProgressBar percentage={barProgress.percentage}
                            text={barProgress.text}
                            textOK={barProgress.textOK}
                            textNOK={barProgress.textNOK}
                        />
                    }
                </div>
            </div>

        </LayoutSection>
    )
}
export default withTranslation()(ShopsUpdateBatch);