import React, { forwardRef, useMemo, useRef, useState } from "react";
import { FaSortUp, FaSortDown } from "react-icons/fa";
import { withTranslation } from "react-i18next";
import { useTable, useSortBy, usePagination, useGlobalFilter, useFilters, useRowSelect } from "react-table";
import { cellTableRender, cellTableRenderInner, loadRenderInfo } from '../../libs/utilsReports'
import { useAsyncDebounce } from "../../hooks/Debounce";

import Pagination from "./Pagination";
import { Checkbox, TextField } from "@mui/material";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faSquare, faTimes } from "@fortawesome/free-solid-svg-icons";

/*
  Tabla Basica que contiene ordenación de columnas y paginación en cliente, los datos se pasan de la siguiente manera:
  <BasicTable data="datos a presentar" columns="Configuracion de las columnas"/>

  La configuración de las columnas son un elemento por cada columna que queramos visualizar


*/

const GlobalFilter = ({ filter, setFilter, labelFilter }) => {

  const [value, setValue] = useState(filter)

  const onChange = useAsyncDebounce((value) => { setFilter(value || undefined) }, 500)

  return (
    <TextField
      label={labelFilter}
      value={value || ''}
      onChange={(evt) => {
        setValue(evt.target.value)
        onChange(evt.target.value)
      }}
    />
  )
}

const CheckFilter = (props) => {



  const renderData = props.column.renderData?.renderData || {}
  const { toggleAllRowsSelected } = props
  const { setFilter } = props.column
  const [checked, setChecked] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);

  useEffect(()=>{
    if(props.column.filterValue===undefined){
      setChecked(false)
      setIndeterminate(false)
    }

  },[props.column.filterValue])

  const handleChange = () => {
    toggleAllRowsSelected(false)

    if (!checked && !indeterminate) {
      setIndeterminate(true); // Estado indeterminate (aspa)
      setFilter(renderData.ZeroValue || 0)
    } else if (indeterminate) {
      setChecked(true); // Estado checked (tick verde)
      setIndeterminate(false);
      setFilter(renderData.OneValue || 1)
    } else {
      setChecked(); // Estado unchecked (solo cuadrado)
      setFilter(2)
    }
  };

  return (
    <Checkbox

      checkedIcon={
        <span style={{ position: 'inherit', display: 'inline-block' }}>
          <FontAwesomeIcon
            icon={faSquare}
            size="lg"
            style={{ color: '#4caf50' }} // Cuadrado verde
          />
          <FontAwesomeIcon
            icon={faCheck}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '10px',
              color: 'white', // Tick blanco dentro del cuadrado verde
            }}
          />
        </span>
      }
      indeterminateIcon={
        <span style={{ position: 'relative', display: 'inline-block' }}>
          <FontAwesomeIcon icon={faSquare} style={{ color: 'red' }} size="lg" /> {/* Cuadrado */}
          <FontAwesomeIcon
            icon={faTimes}
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              fontSize: '10px',
              color: 'white', // Aspa negra
            }}
          />
        </span>
      }
      checked={checked}
      indeterminate={indeterminate}
      onChange={handleChange}
    />
  );
}

const ColumnFilter = (props) => {

  const { filterValue, setFilter, Header, typeCell } = props.column
  const [value, setValue] = useState(filterValue)

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined)
  }, 500)

  useEffect(() => {
    setValue(filterValue)
  }, [filterValue])

  if (typeCell === "light") {
    return <CheckFilter {...props} />
  } else {
    return (
      <TextField
        label={Header}
        value={value || ''}
        onChange={(evt) => {
          setValue(evt.target.value)
          onChange(evt.target.value)
        }}
      />
    )
  }
}

const BasicTable = (props) => {
  const { t } = props
  const columns = useMemo(() => props.columns, [props.columns]);
  //const data = useMemo(() => props.data, []);    
  const [data, setData] = useState(props.data)
  const defaultColumn = useMemo(() => {
    return {
      typeCell: 'text' || undefined,
      Cell: (props) => cellTableRender(props, t),
      Filter: ColumnFilter
    }
  }, [])
  const getColumnDef = (id) => {
    return columns.find((col) => { if (col.accessor === id) return col })
  }
  const filterTypes = useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          let rowValue = cellTableRenderInner(row.values[id], 'text', row.original, t, { className: 'text' })
          if (rowValue !== '') rowValue = rowValue.props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      date: (rows, id, filterValue) => {

        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'date', row.original, t, { className: 'date' }).props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });

      },
      time: (rows, id, filterValue) => {

        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'time', row.original, t, { className: 'time' }).props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });

      },
      translate: (rows, id, filterValue) => {

        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'translate', row.original, t, { className: 'translate' })
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });

      },
      text_translated: (rows, id, filterValue) => {

        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'text_translated', row.original, t, { className: 'text_translated' }).props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });

      },
      basicInfo: (rows, id, filterValue) => {
        let cellDef = getColumnDef(id[0])
        return rows.filter(row => {
          const rowValue = cellTableRenderInner(row.values[id], 'basicInfo', row.original, t, { renderData: cellDef.renderData, className: 'basicInfo' }).props.children
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .includes(String(filterValue).toLowerCase())
            : true;
        });
      },
      light: (rows, id, filterValue) => {
        if (filterValue == 2) return rows;
        else {
          return rows.filter((row) => {
            return row.values[id] == filterValue;
          })
        }
      },
      custom: (rows, id, filterValue) => {
        let cellDef = getColumnDef(id[0])
        return cellDef.filterCustom(rows, id, filterValue);
      }

    }),
    []
  );
  // { indeterminate, ...rest }
  const IndeterminateCheckbox = forwardRef(
    (rest, ref) => {
      const { indeterminate } = rest
      const defaultRef = useRef()
      const resolvedRef = ref || defaultRef

      useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate
      }, [resolvedRef, indeterminate])

      return (
        <>
          <Checkbox ref={resolvedRef} {...rest}></Checkbox>

        </>
      )
    }
  )

  useEffect(()=>{
    setAllFilters([])    
    },[props.data])

  const {
    getTableProps,
    getTableBodyProps,
    preFilteredRows,
    headerGroups,
    footerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    rows,
    gotoPage,
    pageCount,
    setPageSize,
    setGlobalFilter,
    prepareRow,
    setAllFilters,    
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleHideColumn,
    state
  } = useTable(
    { columns, data, defaultColumn, initialState: { pageIndex: 0, pageSize: props.sizePageTable || 10 }, filterTypes },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {

      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: 'selection',
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => {

            return (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            )
          },
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )



  const { pageIndex, pageSize, globalFilter } = state;


  useEffect(() => {
    props.columns.forEach((column) => {
      if (column.renderData !== undefined) loadRenderInfo(column.renderData)
    })
  }, [])
  useEffect(() => {
    setAllFilters([])
    setData(props.data)
    if (props.hidePagination) setPageSize(props.data.length)
  }, [props.data])

  useEffect(() => {
    
    if (props.setSelected !== undefined){
      let selected = selectedFlatRows.map(
        d => d.original
      )
      props.setSelected(selected)
    }       
    if (props.setAllSelected!== undefined) {
      let totalSelected=preFilteredRows.filter(d=>d.isSelected).map(d=>d.original)
      props.setAllSelected(totalSelected)       
    }
  }, [JSON.stringify(selectedFlatRows.map(
    d => d.original
  ))])

  useEffect(() => {
    if (props.showSelection) {
      toggleHideColumn('selection', false)
    } else {
      toggleHideColumn('selection', true)
    }

  }, [props.showSelection, props.columns])

  return (<>

    {props.showGlobalFilter && <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} labelFilter={t('Search')} />}
    <div className="table-responsive">
      <table {...getTableProps()} className="table table-striped table-hover">
        {!props.hideHeader &&
          <thead>
            {headerGroups.map((headerGroup) => (<>
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th className={column.className} {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <FaSortDown />
                        ) : (
                          <FaSortUp />
                        )
                      ) : (
                        ""
                      )}
                    </span>
                  </th>
                ))}
              </tr>
              {props.showFilterColumn && <tr key="filter_table">
                {headerGroup.headers.map((column, idx) => (
                  <th key={"filter_table_col_" + idx}>
                    {<div>{column.canFilter ? column.render("Filter") : null}</div>}
                  </th>
                ))}
              </tr>}
              {page.length === 0 && <tr>
                <th key={"no_data_col"} colSpan={headerGroup.headers.length}><div className={"row calimaco_no_data_found"}>{t("No data found")}  </div></th>
              </tr>}
            </>
            ))}
          </thead>
        }
        {page.length > 0 &&
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={() => {
                  if (props.rowHandle !== undefined) props.rowHandle(row)
                }}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="table-cell">{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        }
        {!props.hideFooter &&
          <tfoot>
            {footerGroups.map((footerGroup) => (
              <tr {...footerGroup.getFooterGroupProps()}>
                {footerGroup.headers.map((column) => {
                  return (
                    <td {...column.getFooterProps()}>
                      {column.render("Footer")}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tfoot>
        }
      </table>
      {!props.hidePagination &&
        <Pagination
          pageSize={pageSize}
          setPageSize={setPageSize}
          gotoPage={gotoPage}
          previousPage={previousPage}
          nextPage={nextPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageOptions={pageOptions}
          pageIndex={pageIndex}
          showInfoPage={true}
          showPageSize={true}
          showGoToPage={true}
          showTotalRegisters={true}
          totalRegisters={rows.length}
          sizePageOptions={props.sizePageOptions}
        ></Pagination>
      }
    </div></>
  )
}

export default withTranslation()(BasicTable);
