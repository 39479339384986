import React, { useEffect, useState } from 'react'
import AdminClient from '../../AdminClient';
import BasicTable from '../../components/basic/BasicTable';
import utils from '../../utils';
import { generateColumnsDef } from '../../libs/utilsReports';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import { withTranslation } from 'react-i18next';
import NotificationAdminAgents from '../../components/NotificationAdminAgents';
import MixIcon from '../../components/basic/MixIcon';


const UsersList = (props) => {
    const { t } = props
    const session=JSON.parse(utils.getSessionItem("user")).session
    const company=utils.getSessionItem("Company")
    const adminClient = new AdminClient();
    const [usersData,setUsersData]=useState([])
    const [usersColumns,setUsersColumns]=useState([])
    const [showSelection,setShowSelection]=useState(false)
    const [selectedUsers,setSelectedUsers]=useState([])
    

  useEffect(()=>{

    props.showAlert('Loading')
    
    adminClient.getReport(session, company, "users").then((response) => { 
        if (response.result === "OK") {
            let columnsDef = generateColumnsDef(response.data.def, t, {}, props.showAlert, props.handleCloseAlert)            
            setUsersColumns(columnsDef)
            setUsersData(response.data.data)
            props.handleCloseAlert()
        }
     },(err) => { 

      });

  },[])

  const handleNewUser=()=>{
    utils.setSessionItem("current-user", -1);
    props.history.push('/configuration/users/details');
  }
  const handleDetailsRow=(row)=>{
    utils.setSessionItem("current-user",row.original.user);
    props.history.push('/configuration/users/details');
  }

  const getButtons=()=>{
    let buttons=[]
    if(utils.isGranted('ADMIN_SEND_NOTIFICATION',company)){
      if(showSelection) buttons.push({ name: t("Notifications"),handle:()=>{setShowSelection(false)}, icon: <MixIcon mainIcon="times" /> })
      else buttons.push({ name: t("Notifications"),handle:()=>{setShowSelection(true)}, icon: <MixIcon mainIcon="comment" /> })
    }
    if(utils.isGranted('SAVE_CONFIGURATION_USERS',company)){
      buttons.push({ name: t("New User"),handle:handleNewUser,icon: <MixIcon mainIcon="plus-square"/> })
    }
    return buttons
  }
 
  //Configuarción Cabecera Sección
  const dataHeader = {   
    title: t("Users"),   
    idDoc: props.idDoc,
    buttons: getButtons()
  }


  return (
    <LayoutSection dataHeader={dataHeader}>
      {showSelection &&
        <NotificationAdminAgents 
            sendNotification='sendAdminNotification'         
            items={selectedUsers}
            t={props.t}
            showAlert={props.showAlert}
            closeAlert={props.handleCloseAlert}
            finishSending={()=>{
              setSelectedUsers([])   
              setUsersData([...usersData])           
            }}            
          />
      }
      <BasicTable key="table-usersList" rowHandle={showSelection?undefined:handleDetailsRow} setAllSelected={setSelectedUsers} showSelection={showSelection} data={usersData} columns={usersColumns} showFilterColumn={true} />
    </LayoutSection>
  )
}

export default withTranslation()(UsersList)