import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter, Link } from 'react-router-dom';
import AdminClient from "../AdminClient";
import NotificationClient from '../NotificationClient';
import utils from "../utils";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// import SelectCompanies from "./SelectCompanies";
import BasicSelect from "./basic/BasicSelect";
import Clock from 'react-live-clock';
import { LiveChatWidget } from "@livechat/widget-react";
import AdminNotifications from "./AdminNotifications";

class PrivateHeader extends React.Component {

  constructor(props) {
    super(props);
    this.menuVisible = false;

    this.sendToLogout = this.sendToLogout.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.showHelp = this.showHelp.bind(this);
    this.showAlert = props.showAlert.bind(this);
    this.handleCloseAlert = props.handleCloseAlert.bind(this);
    this.closeHelp = this.closeHelp.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.showNotificationsPopup = this.showNotificationsPopup.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.getBalanceUser = this.getBalanceUser.bind(this)
    this.t = props.t

    this.companyDetails = JSON.parse(localStorage.getItem("Companies")).find(company => company.company === localStorage.getItem("Company"));

    //console.log("Private header, user = " + JSON.stringify(utils.getSessionItem("user")));

    if (utils.getSessionItem("user") === undefined) {
      this.props.history.push('/');
    }
    // let notifications = (utils.getCurrentCompany().config.backoffice?.notifications === undefined) ? true : utils.getCurrentCompany().config.backoffice?.notifications
    let balanceData = JSON.parse(utils.getSessionItem("user")).balance
    this.state = { messageAlert: {}, liveChatVisible: false, help: false, alias: '', password: '', show_help_class: 'hidden', showProfileMenu: false, anchorEl: null, userBalance: balanceData, showNotifications: true, open: Boolean(null) };

  }
  async getOneGlobalParam(name) {
    let adminClient = new AdminClient();
    return adminClient.getOneGlobalParam(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), name)
  }

  async componentDidUpdate(prevProps, prevState) {

    if (prevProps.messageAlert !== this.props.messageAlert)
      this.setState({ messageAlert: this.props.messageAlert })
  }


  async componentDidMount() {

    document.getElementById("helpWindow").addEventListener('mousedown', this.pickup);
    document.getElementById("helpWindow").addEventListener('dragend', this.handleDragEnd);
    document.getElementById("helpWindow").addEventListener('dragstart', this.handleDragStart);

    window.addEventListener('showHelp', this.showHelp);

    // Crear un canal de comunicación
    const channel = new BroadcastChannel('updateBalance');

    // Escuchar eventos en todas las pestañas
    channel.onmessage = async function (event) {
      let balanceData = await this.getBalanceUser(utils.getSessionItem("Company"))
      let user = JSON.parse(utils.getSessionItem("user"))
      user.balance = balanceData
      utils.setSessionItem("user", JSON.stringify(user))
      this.setState({ userBalance: balanceData })
    }.bind(this);


    // window.addEventListener('updateBalance', async (evt) => {
    //   let balanceData = await this.getBalanceUser(utils.getSessionItem("Company"))
    //   let user = JSON.parse(utils.getSessionItem("user"))
    //   user.balance = balanceData
    //   utils.setSessionItem("user", JSON.stringify(user))
    //   this.setState({ userBalance: balanceData })
    // })
    //let messageAlert = await this.getOneGlobalParam('BACKOFFICE_NOTIFICATION_ALL_BO')
    // let notifications = (utils.getCurrentCompany().config.backoffice?.notifications === undefined) ? true : utils.getCurrentCompany().config.backoffice?.notifications
    let notifications = await this.getOneGlobalParam("BACKOFFICE_NOTIFICATIONS")
    let balanceData = JSON.parse(utils.getSessionItem("user")).balance

    console.log(utils.getSessionItem('showAdminNotifications'))
    if (utils.getSessionItem('showAdminNotifications') == 'true') {
      this.showNotificationsPopup()
    }

    this.setState({ userBalance: balanceData, showNotifications: (notifications.param === "DISABLED") ? false : true })
  }

  sendToLogout(evt) {
    this.props.history.push('/logout');
  }

  closeHelp() {
    this.setState({ show_help_class: "hidden" });
  }
  showHelp(evt) {
    if (this.state.show_help_class !== "visible") {
      // var help_url = window.location.pathname;
      var adminClient = new AdminClient();

      var promise = adminClient.getHelp(JSON.parse(utils.getSessionItem("user")).session, utils.getSessionItem("Company"), evt.detail.url);
      promise.then(
        function (msg) {
          console.log("getHelp  result " + JSON.stringify(msg));
          if (msg.result === 'OK') {
            console.log("Help content = " + msg.html);
            this.setState({ show_help_class: "visible", help_content: msg.html });
          } else {
            alert("Error " + msg.description);
          }
        }.bind(this), function (err) {
          alert("Error " + err.description);
        });
    } else {
      this.closeHelp();
    }
  }

  handleDragEnd(evt) {
    console.log("drag: handleDragEnd Drop : " + evt);
    document.getElementById('helpWindow').style.top = this.offset[1] + evt.pageY + "px";
    document.getElementById('helpWindow').style.left = this.offset[0] + evt.pageX + "px";
  }

  pickup(e) {
    console.log("drag: pickup");
    var el = document.getElementById('helpWindow');

    if (e.clientX) {
      this.offset = [el.offsetLeft - e.clientX, el.offsetTop - e.clientY];
    } else if (e.touches) {
      this.offset = [el.offsetLeft - e.touches[0].pageX, el.offsetTop - e.touches[0].pageY];
    }
  }

  handleDragStart(evt) {
    console.log("drag: Dragstart : " + evt);
  }


  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget, open: Boolean(event.currentTarget) });
  };


  handleClose = () => {
    this.setState({ anchorEl: null, open: Boolean(null) });
  };

  showMenu() {
    console.log(sessionStorage.getItem("menu-visible"));
    if (sessionStorage.getItem("menu-visible") === "false") {
      document.getElementById("menuContainer").classList.remove("d-none");
      sessionStorage.setItem("menu-visible", true);
    } else {
      document.getElementById("menuContainer").classList.add("d-none");
      sessionStorage.setItem("menu-visible", false);
    }
  }
  showLiveChat = () => {
    this.setState({ liveChatVisible: true });
  }

  onVisibilityChanged = (data) => {
    switch (data.visibility) {
      case "maximized":
        this.setState({ liveChatVisible: true });
        break;
      case "minimized":
        this.setState({ liveChatVisible: false });
        break;
      case "hidden":
        this.setState({ liveChatVisible: false });
        break;
    }
  }

  async getBalanceUser(company) {
    let clientAPI = new AdminClient();
    let userInfo = JSON.parse(utils.getSessionItem("user"))
    let balance = await clientAPI.getBalanceUser(userInfo.session, company)
    this.setState({ userBalance: balance.data })
    return balance.data
  }


  showNotificationsPopup = (event) => {

    this.showAlert(this.t("Notifications"), <AdminNotifications t={this.t} />, undefined, 'xl')
  };

  render() {
    //console.log(utils.getSessionItem("user"));
    const { t } = this.props;

    if (this.state.showHelp) {
      document.getElementById("helpWindow").addEventListener('mousedown', this.pickup);
      document.getElementById("helpWindow").addEventListener('dragend', this.handleDragEnd);
      document.getElementById("helpWindow").addEventListener('dragstart', this.handleDragStart);
    }
    console.log("  utils.getCurrentCompany().config.time = " + utils.getCurrentCompany().config.timezone);
    if (utils.getSessionItem("user") !== undefined || utils.getSessionItem("user") === null) {
      let userConf = JSON.parse(utils.getSessionItem("user"))
      return (

        <>
          {this.state.messageAlert.content && <div className={"marquee " + "marquee_" + this.state.messageAlert.type}><p>{this.state.messageAlert.content}</p></div>}
          <div className="container topHeader">

            <div className="row topBar">


              <div className="col-2 d-md-none mainNavbarButton d-block d-md-none" style={{ height: '0px' }}>
                <button className="navbar-toggler navbar-dark" type="button" onClick={this.showMenu}>
                  <span className="navbar-toggler-icon"></span></button>
              </div>
              <div className="col-4 col-md-4">
                {/* <SelectCompanies className="selectCompanies"></SelectCompanies> */}
                <BasicSelect
                  idProperty="value"
                  name="company"
                  nameProperty="label"
                  onChange={
                    async (evt) => {
                      let clientAPI = new AdminClient();
                      let userAdmin = JSON.parse(utils.getSessionItem("user"))
                      let session = userAdmin.session
                      let company = evt.value
                      userAdmin.balance = await this.getBalanceUser(company)

                      clientAPI.getI18n(company).then((values) => {
                        var _res = {};
                        values.data.forEach(translate => {
                          if (_res[translate.lang] === undefined) {
                            _res[translate.lang] = { "translation": {} };
                          }
                          _res[translate.lang].translation[translate.key] = translate.value;
                        });

                        i18next
                          .use(initReactI18next) // passes i18n down to react-i18next
                          .init({
                            resources: _res,
                            lng: userAdmin.lang.split("-")[0],
                            fallbackLng: "en",

                            interpolation: {
                              escapeValue: false
                            }

                          });
                        utils.setSessionItem("i18n", JSON.stringify(_res));

                      }, (err) => {
                        console.log("Error " + err);
                      })

                      let sectionsPromise = clientAPI.getSections(session, company);
                      sectionsPromise.then((msgSections) => {

                        utils.setSections(msgSections.sections)
                        // Leemos los menus del usuario
                        let menusPromise = clientAPI.getMenus(session, company);
                        menusPromise.then(async (msgMenus) => {
                          utils.setMenus(msgMenus.menus)
                          utils.setSessionItem("Company", evt.value);
                          // let notifications = (utils.getCurrentCompany().config.backoffice?.notifications === undefined) ? true : utils.getCurrentCompany().config.backoffice?.notifications
                          let notifications = await this.getOneGlobalParam("BACKOFFICE_NOTIFICATIONS")

                          this.setState({ showNotifications: (notifications.param === "DISABLED") ? false : true })
                          //Comprobamos la init_page que tiene el usuario para la compañia                           
                          userAdmin.init_page = userAdmin.companiesData[evt.value].init_page
                          userAdmin.currency = userAdmin.companiesData[evt.value].currency
                          utils.setSessionItem("user", JSON.stringify(userAdmin))
                          if (userAdmin.init_page === null || userAdmin.init_page === '') userAdmin.init_page = "/defaultHome"

                          if (this.props.location.pathname === userAdmin.init_page)
                            this.props.history.push(this.props.location.pathname);
                          else this.props.history.push(userAdmin.init_page);

                        });

                      });
                    }
                  }
                  addOpts={JSON.parse(utils.getSessionItem("companiesOptions"))}
                  value={utils.getSessionItem("Company")}
                  disableClearable
                />
              </div>
              
              <div className="col-4 col-md-4 text-center hideClock">
                {!(utils.getSessionItem("hideHeaderDate") == 'true') && <>
                <Clock className="serverTime" format={'YYYY-MM-DD HH:mm:ss'} ticking={true} timezone={utils.getCurrentCompany().config.timezone} /> ({utils.getCurrentCompany().config.timezone})
                </>}
              </div>
              <div className="title col-6 col-md-4 alias justify-content-end" >
                {this.props.help_url !== undefined && this.props.help_url.length > 0 &&
                  <> <Button onClick={this.showHelp} className="help_button hideMobile"></Button>

                  </>
                }
                <div id="helpWindow" draggable className={"hideMobile helpPanel resizable " + this.state.show_help_class}  >
                  <div className="helpHeader">
                    {t("Help")} <a href="#" className="float-end" onClick={this.closeHelp}><span><FontAwesomeIcon icon="window-close" /></span></a>
                  </div>
                  <div className="helpContent" dangerouslySetInnerHTML={{ __html: this.state.help_content }}></div>
                </div>
                <div className="float-end">
                  <div className="btn-group">
                    {this.companyDetails.config.liveChat !== undefined &&
                      this.companyDetails.config.liveChat.url === undefined &&
                      this.companyDetails.config.liveChat !== "14932659" &&

                      <>
                        <LiveChatWidget license={this.companyDetails.config.liveChat} customerName={"Agente " + JSON.parse(localStorage.getItem("user")).alias} visibility={this.state.liveChatVisible ? "maximized" : "hidden"} onVisibilityChanged={this.onVisibilityChanged} />
                        <button className="liveChatButton" onClick={this.showLiveChat}>{t("Soporte")}</button>
                      </>
                    }
                    {this.companyDetails.config.liveChat !== undefined &&
                      (this.companyDetails.config.liveChat.url !== undefined || this.companyDetails.config.liveChat === "14932659") &&
                      <>
                        <a className="liveChatButton" href={this.companyDetails.config.liveChat.url ? this.companyDetails.config.liveChat.url : "https://soporte24.app/agentes"} target="_blank">{t("Soporte")}</a>
                      </>
                    }
                    <span><button className="notificationHeaderOptions" onClick={this.showNotificationsPopup}><FontAwesomeIcon icon="bell" /></button></span>
                    <button className="aliasHeaderOptions" onClick={this.handleClick}>
                      <span className="hideUser">{userConf.alias}</span><span className="hideUserBalance">  {utils.formatCurrency((this.state.userBalance?.amount || 0) / 100, this.state.userBalance?.currency)}</span>
                    </button>
                    <Menu
                      anchorEl={this.state.anchorEl}
                      open={this.state.open}
                      onClose={this.handleClose}
                    >
                      <MenuItem onClick={this.handleClose}><Link to='/profile/'>{t('Configuration')}</Link></MenuItem>
                      <MenuItem onClick={this.handleClose}><Link to='/logout/'>{t("MENU_LOGOUT")}</Link></MenuItem>
                    </Menu>
                  </div>

                  <span className="hideUser">
                    {this.state.showNotifications && <>
                      <NotificationClient ></NotificationClient>
                    </>}
                  </span>
                </div>
              </div>

            </div>



          </div>
        </>
      );
    } else {
      return <></>
    }
  }
}

export default withTranslation()(withRouter(PrivateHeader))
