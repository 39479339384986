import React from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import CreateUser from '../../components/agents/CreateUser';
function NewUser(props) {


    const dataHeader = {
        title: props.t('New user'),
        urlHelp: '',
        idDoc: props.idDoc
    };


    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>
                <CreateUser {...props} />
            </LayoutSection>
        </>
    )
}
export default withTranslation()(NewUser);