import React, { memo, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import AdminClient from "../../AdminClient";
import utils from "../../utils";
import LayoutSection from "../../components/NavigationAndLayouts/LayoutSection";
import { useAlert } from "../../hooks/Alert";
import AlertMessage from "../../components/NavigationAndLayouts/AlertMessage";
import { Menu, MenuItem, TextField } from "@mui/material";
import AgentsTree from "../../components/agents/AgentsTree";
import memoizeOne from "memoize-one";
import { IconButton } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { areEqual } from "react-window";
import CustomRenders from "../../context/customRenders";
import { Box, Button, List, ListItem, Typography } from "@mui/material";
import useFormValidation from "../../hooks/parsleyValidation";
import CreateUser from "../../components/agents/CreateUser";
import CreateAgent from "../../components/agents/CreateAgent";
import MixIcon from "../../components/basic/MixIcon";
import NotificationAdminAgents from "../../components/NotificationAdminAgents";



// Componente Row que representa cada nodo.
const Row = memo(({ data, index, style }) => {
  const { flattenedData, onOpen, onSelect, updateNode, resources } = data;
  const node = flattenedData[index];
  const [anchorEl, setAnchorEl] = useState(null);

  const selectionText =
    node.selected == "node"
      ? resources.t("Agent")
      : node.selected == "children"
        ? resources.t("SubAgents of")
        : node.selected == "nodechildren"
          ? resources.t("All")
          : "";
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const finishOperation = ({ action, type, dataResponse }) => {
    let updatedNode = { ...node };
    switch (action + "_" + type) {
      case "SEND_agent":
      case "RETRIEVE_agent": {
        updatedNode.amount = dataResponse[0].amount;
        updateNode(index, updatedNode);
        break;
      }
      case "SEND_player":
      case "RETRIEVE_player": {
        updatedNode.amount = updatedNode.amount + 1;
        updateNode(index, updatedNode);
        break;
      }
      case "STATUS_agent": {
        let updatedNodes = dataResponse.agentsUpdated.split(" ");
        if (dataResponse.allAgents) {
          //Buscamos a todos los agentes que han sido actualizados su estado
          flattenedData.forEach((n, index) => {
            if (updatedNodes.includes(`${n.user}`)) {
              n.status = dataResponse.status;
              if (dataResponse.status === "DISABLED") {
                n.blocked_by = resources.adminUser;
              } else n.blocked_by = null;
              updateNode(index, n);
            }
          });
        } else {
          updatedNode.status = dataResponse.status
          if (dataResponse.status === "DISABLED") {
            updatedNode.blocked_by = resources.adminUser;
          } else updatedNode.blocked_by = null;
          updateNode(index, updatedNode)
        }
        break;
      }
    }
  };

  return (
    <>
      <div style={style} className="item-background">
        {/* Contenedor principal en una línea con flex */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          position="relative"
        >
          {/* Contenido alineado a la izquierda sin wrap con identación relativa al nodo padre */}
          <Box
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
            ml={`${(node.depth - 1) * 1.5}em`} // Aplica margen solo para hijos
            width="40%" // Asegura que el contenedor ocupe todo el ancho disponible
          >
            {/* Botón para expandir/colapsar el nodo solo si tiene hijos */}

            {node.hasChildren &&
              node.collapsed &&
              (node.selected === "none" || node.selected === "node") && (
                <IconButton
                  className="icon-button"
                  onClick={async () => await onOpen(node)}
                >
                  <FontAwesomeIcon icon="angle-right" className="icon" />
                </IconButton>
              )}
            {node.hasChildren &&
              !node.collapsed &&
              (node.selected === "none" || node.selected === "node") && (
                <IconButton
                  className="icon-button"
                  onClick={async () => await onOpen(node)}
                >
                  <FontAwesomeIcon icon="angle-down" className="icon" />
                </IconButton>
              )}

            {/* Icono de tipo de nodo y alias con elipsis si es necesario */}
            <Box
              display="flex"
              alignItems="center"
              ml={`${node.hasChildren &&
                  node.selected != "children" &&
                  node.selected !== "nodechildren"
                  ? 0
                  : 1
                }em`}
              overflow="hidden"
            >
              <FontAwesomeIcon
                icon={node.type === "agent" ? "user-secret" : "user"} // El tipo cambia el icono
                size="xs"
                style={{ marginRight: "0.2em" }} // Reduce el margen derecho del icono de tipo de nodo
              />
              {resources.renders.renderAgentAlias(
                node.alias,
                node,
                resources.t,
                {}
              )}
            </Box>
          </Box>

          {/* Contenido de iconos a la derecha con margen derecho de 20px */}
          <Box
            display="flex"
            alignItems="center"
            flexWrap="nowrap"
            justifyContent="space-between"
            width="60%"
          >
            {resources.view !== "notification" && (
              <Box display="flex" alignItems="center"
              // width="50%"
              >
                {resources.renders.renderAgentsPlayersCurrency(
                  node.amount,
                  node,
                  resources.t,
                  {}
                )}
              </Box>
            )}

            <Box display="flex" alignItems="center" mr={0.5}
            //width="30%"
            >
              {resources.view === "notification" ? (
                <>
                  <IconButton
                    onClick={
                      node.selected === undefined || node.selected === "none"
                        ? (evt) => {
                          if (node.hasChildren) handleClick(evt)
                          else onSelect(evt, node, 1)
                        }
                        : (evt) => {
                          onSelect(evt, node, 4);
                        }
                    }
                    className="icon-button"
                  >
                    <FontAwesomeIcon
                      icon={
                        node.selected === undefined || node.selected === "none"
                          ? "plus"
                          : "trash"
                      }
                      className="icon"
                    />
                  </IconButton>
                  {selectionText}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={(evt) => {
                        onSelect(evt, node, 1);
                        handleClose();
                      }}
                    >
                      {resources.t("Agent")}
                    </MenuItem>
                    <MenuItem
                      onClick={(evt) => {
                        onSelect(evt, node, 2);
                        handleClose();
                      }}
                    >
                      {resources.t("SubAgents of")}
                    </MenuItem>
                    <MenuItem
                      onClick={(evt) => {
                        onSelect(evt, node, 3);
                        handleClose();
                      }}
                    >
                      {resources.t("All")}
                    </MenuItem>
                  </Menu>
                </>
              ) : (
                <>
                  {/* Renderizado de otros elementos de agentes y operaciones */}
                  {/* {resources.renders.renderAgentsPlayersCurrency(node.amount, node, resources.t, {})} */}
                  {resources.renders.renderAgentsPlayersOperations(
                    "",
                    node,
                    resources.t,
                    {
                      showAlert: resources.showAlert,
                      handleCloseAlert: resources.closeAlert,
                      extraData: { finishOperation: finishOperation },
                    }
                  )}
                </>
              )}
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
}, areEqual);

const getItemData = memoizeOne(
  (onOpen, onSelect, flattenedData, updateNode, resources) => ({
    onOpen,
    onSelect,
    flattenedData,
    updateNode,
    resources,
  })
);

const AgentControlTree = (props) => {
  const t = props.t;
  const company = utils.getSessionItem("Company");
  const session = JSON.parse(utils.getSessionItem("user")).session;
  const adminUser = JSON.parse(utils.getSessionItem("user")).user;
  const [data, setData] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorElSearch, setAnchorElSearch] = useState(null);
  const handleClickSearch = (event) => {
    setAnchorElSearch(event.currentTarget);
  };
  const handleCloseSearch = () => {
    setAnchorElSearch(null);
  };
  const adminClient = new AdminClient();

  //Variables para almacenar el total de nodos pedidos (root y child nodes).
  const [totalRootNodesFetched, setTotalRootNodesFetched] = useState(0);
  const [totalChildNodesFetched, setTotalChildNodesFetched] = useState({});
  const [executeSearch, setExecuteSearch] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [view, setView] = useState("agents");
  const [search, setSearch] = useState("all");
  const [selected, alertOpen, showAlert, closeAlert] = useAlert();
  const [selectedNodes, setSelectedNodes] = useState([]);
  const { formValidation } = useFormValidation("Form_searchAgentAlias");
  const dataHeader = {
    title: t("Agent Control Panel"),
    buttons: [],
    idDoc: props.idDoc,
  };

  const onChangeSearchString = (evt) => {
    const { value } = evt.target;
    setSearchString(value);
  };

  // Función simulada para fetchMoreRootNodes, limitada a 100 nodos.
  const fetchMoreRootNodes = async () => {
    console.log("Fetching more root nodes from", totalRootNodesFetched);

    return new Promise((resolve) => {
      let callFilter = undefined;

      let filterReport = [
        { field: "u.alias", type: "like", value: searchString },
      ];

      if (totalRootNodesFetched != -1) {
        if (search === "users") {
          callFilter = searchString;
          filterReport = [];
        }

        adminClient
          .getReport(
            session,
            company,
            "agents_control_" + search,
            filterReport,
            null,
            null,
            `limit ${totalRootNodesFetched},20`,
            "",
            callFilter
          )
          .then((result) => {
            if (result.data.data.length > 0) {
              let newNodes = result.data.data.map((d) => {
                return {
                  ...d,
                  children: null,
                  depth: 1,
                  parent: 0,
                  hasChildren: d.hasChildren === 1 ? true : false,
                };
              });

              if (newNodes.length === 20) {
                setTotalRootNodesFetched(
                  totalRootNodesFetched + newNodes.length
                ); // Actualizamos el total de nodos root pedidos.
              } else setTotalRootNodesFetched(-1);
              if (view === "notification") {
                newNodes.forEach((elem) => {
                  const matchingObj = selectedNodes.find(
                    (node) => node.user === elem.user
                  );
                  if (matchingObj) {
                    elem.selected = matchingObj.selected;
                  }
                });
              }
              resolve(newNodes);
            } else {
              setTotalRootNodesFetched(-1); // Actualizamos el total de nodos root pedidos.
              resolve([]);
            }
          });
      } else resolve([]);
    });
  };

  const fetchMoreChildNodes = async (parentId, startIndex) => {
    // Inicializamos el contador para el nodo padre si no existe.
    if (startIndex === 0 || !totalChildNodesFetched[parentId]) {
      totalChildNodesFetched[parentId] = 0;
    }
    console.log(
      `Fetching more child nodes for parent ${parentId} from index ${totalChildNodesFetched[parentId]}`
    );

    return new Promise((resolve) => {
      //let searchAux = search

      const callFilter = `${parentId};${search};${searchString}`;

      if (totalChildNodesFetched[parentId] != -1) {
        adminClient
          .getReport(
            session,
            company,
            "agents_control_subagents",
            [],
            null,
            null,
            `limit ${totalChildNodesFetched[parentId]},20`,
            "",
            callFilter
          )
          .then((result) => {
            if (result.data.data.length > 0) {
              let newNodes = result.data.data.map((d) => {
                return {
                  ...d,
                  children: null, // Los nodos con profundidad 2 pueden tener hijos.
                  depth: 0,
                  parent: parentId,
                  hasChildren: d.hasChildren === 1 ? true : false,
                };
              });
              if (newNodes.length === 20) {
                setTotalChildNodesFetched((prev) => {
                  return {
                    ...prev,
                    [parentId]: prev[parentId] + newNodes.length,
                  };
                }); // Actualizamos el total de hijos pedidos para este nodo padre.

                //resolve(newNodes)
              } else {
                setTotalChildNodesFetched((prev) => {
                  return { ...prev, [parentId]: -1 };
                });
              }
              if (view === "notification") {
                newNodes.forEach((elem) => {
                  const matchingObj = selectedNodes.find(
                    (node) => node.user === elem.user
                  );
                  if (matchingObj) {
                    elem.selected = matchingObj.selected;
                  }
                });
              }
              resolve(newNodes);
            } else {
              setTotalChildNodesFetched((prev) => {
                return { ...prev, [parentId]: -1 };
              }); // Actualizamos el total de hijos pedidos para este nodo padre.
              resolve([]);
            }
          });
      } else resolve([]);
    });
  };

  const getAdditionalNodeData = (node) => {
    const { amount, currency, db, type, status, blocked_by } = node;

    return { amount, currency, db, type, status, blocked_by };
  };

  useEffect(() => {
    if (executeSearch) {
      //if (window['ReportResult']) window['ReportResult'].executeReport([{ "field": "u.alias", "type": "like_end", "value": searchString }])
      fetchMoreRootNodes().then((result) => {
        if (view === "notification") {
          result.forEach((elem) => {
            const matchingObj = selectedNodes.find(
              (node) => node.user === elem.user
            );
            if (matchingObj) {
              elem.selected = matchingObj.selected;
            }
          });
        }
        setData(result);
      });
      setExecuteSearch(false);
    }
  }, [executeSearch]);

  const finishSending = () => {
    setSelectedNodes([]);
    setTotalChildNodesFetched({});
    setTotalRootNodesFetched(0);
    setSearchString("");
    setExecuteSearch(true);
  };
  useEffect(() => {
    setSelectedNodes([]);
    setTotalChildNodesFetched({});
    setTotalRootNodesFetched(0);
    setSearchString("");
    setData([])
    if (view !== 'createAgent' && view !== 'createAgent') {
      if (view === "notification") setSearch("agents_notification");
      else setSearch("all");
      setExecuteSearch(true);
    }
  }, [view]);
  const setSelectedNode = (node) => {
    if (node.selected === "none") {
      setSelectedNodes(
        selectedNodes.filter((no) => {
          return no.user !== node.user;
        })
      );
    } else {
      let newSelected = [...selectedNodes];
      if (node.selected === "children" || node.selected === "nodechildren") {
        newSelected = selectedNodes.filter((no) => {
          return no.parent === 0 || !no.parentCollapsed.includes(node.user);
        });
      }
      setSelectedNodes([...newSelected, node]);
    }
  };

  const onChangeSearch = (search) => {
    if (formValidation.validate()) {
      if (view !== "notification") setSelectedNodes([]);
      setTotalChildNodesFetched({});
      setTotalRootNodesFetched(0);
      setSearch(search);
      setExecuteSearch(true);
      if (view !== "notification") handleClose();
    }
  };

  const handleClearSearch = () => {
    setSearch('all')
    setTotalChildNodesFetched({});
    setTotalRootNodesFetched(0);
    setSearchString("");
    setExecuteSearch(true);
    formValidation.reset()
  };
  return (
    <>
      <LayoutSection {...props} dataHeader={dataHeader}>
        <form id="Form_searchAgentAlias">
          <div className="desktop-icons-agent-control">
            <div className="content m-2 row">
              <div className="col-6">
                {view !== "createUser" && view !== "createAgent" && (
                  <>
                    {/* <form id="Form_searchAgentAlias"> */}
                    <TextField
                      className="float-start"
                      label={t("Search") + " " + t("Alias")}
                      name="search"
                      InputProps={{
                        inputProps: {
                          "data-parsley-required": "true",
                          minlength: "3",
                        },
                      }}
                      type="text"
                      value={searchString}
                      onChange={onChangeSearchString}
                    />
                    {/* </form> */}
                  </>
                )}
                {view === "createUser" && (
                  <div className="card-header">{t("New user")}</div>
                )}
                {view === "createAgent" && (
                  <div className="card-header">{t("New agent")}</div>
                )}
              </div>
              <div className="col-2">
                {view === "notification" && (
                  <button type="button"
                    className={"btn btn-primary float-start"}
                    onClick={() => {
                      onChangeSearch("agents_notification");
                    }}
                  >
                    <FontAwesomeIcon icon="search" className="icon" />
                  </button>
                )}
                {view === "notification" && searchString !== "" && (
                  <button type="button"
                    className={"btn btn-primary float-start"}
                    onClick={handleClearSearch}
                  >
                    <FontAwesomeIcon icon="times" className="icon" />
                  </button>
                )}

                {view === "agents" && (
                  <>
                    <button type="button"
                      className={"btn btn-primary float-start"}
                      onClick={handleClickSearch}
                    >
                      <FontAwesomeIcon icon="search" className="icon" />
                    </button>
                    {searchString !== "" && (
                      <button type="button"
                        className={"btn btn-primary float-start"}
                        onClick={handleClearSearch}
                      >
                        <FontAwesomeIcon icon="times" className="icon" />
                      </button>
                    )}

                    <Menu
                      anchorEl={anchorElSearch}
                      open={Boolean(anchorElSearch)}
                      onClose={handleCloseSearch}
                    >
                      <MenuItem
                        onClick={() => {
                          onChangeSearch("all");
                          handleCloseSearch();
                        }}
                      >
                        {t("Direct Agents and Players")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          onChangeSearch("agents");
                          handleCloseSearch();
                        }}
                      >
                        {t("All agents")}
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          onChangeSearch("users");
                          handleCloseSearch();
                        }}
                      >
                        {t("All players")}
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
              <div className="col-4">
                <div className="col-12">
                  {utils.isGranted("AGENTS_CREATE_NEW_USER") && (
                    <button type="button"
                      title={t('New user')}
                      className={
                        view !== "createUser"
                          ? "btn btn-secondary float-end"
                          : "btn btn-primary float-end"
                      }
                      onClick={() => {
                        setView("createUser");
                      }}
                    >
                      <MixIcon mainIcon="user" secondIcon="plus" />

                    </button>
                  )}
                  {utils.isGranted("AGENTS_NEW_AGENT") && (
                    <button type="button"
                      title={t('New agent')}
                      className={
                        view !== "createAgent"
                          ? "btn btn-secondary float-end"
                          : "btn btn-primary float-end"
                      }
                      onClick={() => {
                        setView("createAgent");
                      }}
                    >
                      <MixIcon mainIcon="user-secret" secondIcon="plus" />

                    </button>
                  )}
                  {utils.isGranted("AGENTS_SEND_NOTIFICATION") && (
                    <button type="button"
                      title={t("Notifications")}
                      className={
                        view !== "notification"
                          ? "btn btn-secondary float-end"
                          : "btn btn-primary float-end"
                      }
                      onClick={() => {
                        setView("notification");
                      }}
                    >
                      <MixIcon mainIcon="comment" secondIcon="plus" />
                    </button>
                  )}
                  <button type="button"
                    title={t("Search")}
                    className={
                      view !== "agents"
                        ? "btn btn-secondary float-end"
                        : "btn btn-primary float-end"
                    }
                    onClick={() => {
                      setView("agents");
                    }}
                  >
                    <FontAwesomeIcon icon="users" className="icon" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="mobile-menu-agents-control ">
            {view !== "createUser" && view !== "createAgent" && (
              // <form id="Form_searchAgentAlias">
              <TextField
                className="float-start"
                label={t("Search") + " " + t("Alias")}
                name="search"
                InputProps={{
                  inputProps: {
                    "data-parsley-required": "true",
                    minlength: "3",
                  },
                }}
                type="text"
                value={searchString}
                onChange={onChangeSearchString}
              />
              // </form>
            )}

            {view === "agents" &&
              <button type="button"
                className={"btn btn-primary float-start"}
                onClick={handleClickSearch}
              >
                <FontAwesomeIcon icon="search" className="icon" />
              </button>
            }
            {view === "notification" &&
              <button type="button"
                className={"btn btn-primary float-start"}
                onClick={() => {
                  onChangeSearch("agents_notification");
                }}
              >
                <FontAwesomeIcon icon="search" className="icon" />
              </button>
            }

            {searchString !== "" &&
              <button type="button"
                className={"btn btn-primary float-start"}
                onClick={handleClearSearch}
              >
                <FontAwesomeIcon icon="times" className="icon" />
              </button>
            }

            {view === "createUser" && (
              <div className="card-header float-start">{t("New User")}</div>
            )}
            {view === "createAgent" && (
              <div className="card-header float-start">{t("New Agent")}</div>
            )}
            <button type="button" className={"btn btn-primary float-end"} onClick={handleClick}>
              <FontAwesomeIcon icon="bars" className="icon" />
            </button>
          </div>
        </form>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >

          <MenuItem
            onClick={() => {
              setView("agents");
              handleClose();
            }}
          >
            <MixIcon mainIcon="users" label={t("Search")} />
          </MenuItem>
          {utils.isGranted("AGENTS_SEND_NOTIFICATION") && (
            <MenuItem
              onClick={() => {
                setView("notification");
                handleClose();
              }}
            >
              <MixIcon mainIcon="comment" secondIcon="plus" label={t("Notifications")} />
            </MenuItem>
          )}
          {utils.isGranted("AGENTS_NEW_AGENT") && (
            <MenuItem
              onClick={() => {
                setView("createAgent");
                handleClose();
              }}
            >
              <MixIcon mainIcon="user-secret" secondIcon="plus" label={t("New agent")} />
            </MenuItem>
          )}
          {utils.isGranted("AGENTS_CREATE_NEW_USER") && (
            <MenuItem
              onClick={() => {
                setView("createUser");
                handleClose();
              }}
            >
              <MixIcon mainIcon="user" secondIcon="plus" label={t("New user")} />
            </MenuItem>
          )}

        </Menu>

        {view === "notification" && selectedNodes.length > 0 && (

          <NotificationAdminAgents
            sendNotification="sendAgentNotification"
            items={selectedNodes}
            t={props.t}
            showAlert={showAlert}
            closeAlert={closeAlert}
            finishSending={finishSending}
          />
        )}
        {(view === "agents" || view === "notification") && (
          <div style={{ height: "400px", width: "100%" }}>
            {data.length > 0 && (
              <AgentsTree
                data={data}
                fetchMoreChildNodes={fetchMoreChildNodes}
                fetchMoreRootNodes={fetchMoreRootNodes}
                row={Row}
                getItemData={getItemData}
                getAdditionalNodeData={getAdditionalNodeData}
                itemSize={35}
                setSelectedNode={setSelectedNode}
                resources={{
                  t: props.t,
                  renders: new CustomRenders(),
                  showAlert: showAlert,
                  closeAlert: closeAlert,
                  adminUser: adminUser,
                  view: view,
                }}
              />
            )}
            {data.length === 0 &&
              <div className={"row calimaco_no_data_found"}>{t("No data found")}  </div>
            }
          </div>
        )}
        {view === "createUser" && (
          <CreateUser
            t={props.t}
            showAlert={props.showAlert}
            handleCloseAlert={props.handleCloseAlert}
          />
        )}
        {view === "createAgent" && (
          <CreateAgent
            t={props.t}
            showAlert={props.showAlert}
            handleCloseAlert={props.handleCloseAlert}
          />
        )}
      </LayoutSection>
      <AlertMessage
        key={"AgentControl_alert"}
        isActive={alertOpen}
        size={selected.size}
        content={selected.content}
        title={selected.title}
        buttons={selected.buttons}
        handleCloseAlert={closeAlert}
      />
    </>
  );
};

export default withTranslation()(AgentControlTree);
