import { TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';

import AdminClient from '../../AdminClient';
import utils from '../../utils';
import useFormValidation from '../../hooks/parsleyValidation';
import BasicSelect from '../../components/basic/BasicSelect';
import PhoneInput from '../basic/PhoneInput';

function CreateUser(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = useMemo(() => utils.getCurrentCompany(), []);    
    const hiddenFields = useMemo(() => companyDetails.config.backoffice?.agents?.newUser?.hiddenFields || [], [])
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const { formValidation } = useFormValidation('Form_AgentsNewUser')
    const minLength = utils.getCurrentCompany().config.backoffice?.minPassUser || 11
    const [newUser, setNewUser] = useState({
        popupTitle: "",
        errors: {},
        currency: "",
        code_send: false,
        code_verified: false,
        alias: "",
        password: "",
        national_id: "",
        national_id_type: "",
        email: "",
        first_name: "",
        last_name: "",
        mobile: "",
        comments: ""
    });

    useEffect(function () {
        if (JSON.parse(utils.getSessionItem("user")).otpEnabled) {
            adminClient.getQR(session).then(
                function (response) {
                    onChangeHandler({ name: "qr", value: response.img })
                },
                function (err) {
                    console.error("qr error: " + JSON.stringify(err));
                }
            );
        }
    }, []);
    const onChangeHandler = (e) => {
        const { name, value } = e.target || e;
        setNewUser((prevState) => ({ ...prevState, [name]: value }));
    };

    const onChangeMobileHandler=(value)=>{
        setNewUser((prevState)=>({...prevState,['mobile']:value}))
    }

    const register = (evt) => {
        if (formValidation.validate()) {

            if (hiddenFields.includes("first_name")) newUser.first_name = newUser.alias
            if (hiddenFields.includes("mobile")) newUser.mobile = newUser.alias
            if (hiddenFields.includes("last_name")) newUser.last_name = newUser.alias
            if (hiddenFields.includes("email")) newUser.email = newUser.alias + "@"
            if (hiddenFields.includes("currency")) newUser.currency = companyDetails.base_currency
            if (hiddenFields.includes("national_id")) newUser.national_id = newUser.alias


            adminClient.register(session, company, JSON.stringify(newUser)).then(function (data) {
                if (data.result === 'OK') {
                    props.showAlert(t("Register user"), t("Player registered succesfully"));
                } else {
                    props.showAlert(t("Register user"), t("Error") + " " + data.description);

                }
            }, function (err) {
                props.showAlert(t("Register user"), t("Error") + "" + err.description);
            });
        }
    }

    const verifyCode = (evt) => {
        adminClient.mobileVerifyVerificationCode(session, company, newUser.mobile, newUser.code).then(function (data) {
            if (data.result === 'OK') {
                onChangeHandler({ name: "code_verified", value: true })
                props.showAlert(t("Send mobile code"), t("Code verify succesfully"));

            } else {
                props.showAlert(t("Register user"), t("Error") + data.description);
            }
        }, function (err) {
            props.showAlert(t("Register user"), t("Error") + err.description);
        });
    }

    const sendCode = (evt) => {
        adminClient.mobileCreateVerificationCode(session, company, newUser.mobile).then(function (data) {
            if (data.result === 'OK') {
                onChangeHandler({ name: "code_send", value: true })
                props.showAlert(t("Send mobile code"), t("Code sent succesfully"));
            } else {
                props.showAlert(t("Register user"), t("Error") + data.description);
            }
        }, function (err) {
            props.showAlert(t("Register user"), t("Error") + err.description);
        });
    }


    return (
        <>
            {/* <LayoutSection {...props} dataHeader={dataHeader}> */}
            <form id="Form_AgentsNewUser" autocomplete="off" >
                <div className="row">
                    {companyDetails.config.register !== undefined
                        && companyDetails.config.register.mobile_verification !== undefined
                        && companyDetails.config.register.mobile_verification
                        && !newUser.code_verified
                        ?
                        <div className="row">
                            <div className="col-12  ">
                                <TextField id="mobile" label={t("Mobile")} name="mobile" value={newUser.mobile} type="text" onChange={onChangeHandler} />
                            </div>
                            {newUser.code_send &&
                                <>
                                    <div className="col-12  ">
                                        <TextField id="code" label={t("Code")} type="text" name="code" value={newUser.code} onChange={onChangeHandler} />
                                    </div>
                                    {!newUser.code_verified &&
                                        <div className="col-12">
                                            <button type="button" className="btn btn-primary" onClick={verifyCode}>{t("Verify code")}</button>
                                        </div>
                                    }
                                </>
                            }
                            {!newUser.code_send &&
                                <div className="col-12">
                                    <button type="button" className="btn btn-primary" onClick={sendCode}>{t("Send Code")}</button>
                                </div>

                            }
                        </div>
                        :
                        <div className="row">
                            <div className="col-12 col-sm-4 ">
                                <TextField required autoComplete="off" label={t("Alias")} name="alias" value={newUser.alias} type="text" onChange={onChangeHandler} />
                            </div>
                            <div className="col-12  col-sm-4 ">
                                <TextField required autoComplete="off"
                                    label={t("Password")} name="password" value={newUser.password} type="password"
                                    inputProps={
                                        {
                                            "data-parsley-pattern": "^[a-zA-Z0-9]+$",
                                            "data-parsley-pattern-message": t('Password letters and numbers'),
                                            "minLength": minLength,
                                            "autocomplete": "new-password"
                                        }
                                    }
                                    onChange={onChangeHandler} />
                            </div>
                            {!hiddenFields.includes('national_id_type') &&
                                <div className="col-12   col-sm-4  ">
                                    <BasicSelect
                                        idProperty='national_id'
                                        nameProperty='national_id'
                                        basicInfoName='NationalIdTypes'
                                        label={t('Type')}
                                        inputProps={
                                            {
                                                "autocomplete": "new-password"
                                            }
                                        }
                                        value={newUser.national_id_type}
                                        name="national_id_type"
                                        onChange={onChangeHandler}
                                        translate={{ property: 'national_id', prefix: 'DOCUMENTO_TYPE_' }}
                                    />
                                </div>
                            }
                            {!hiddenFields.includes('national_id') &&
                                <div className="col-12   col-sm-4  ">
                                    <TextField label={t("National ID")} name="national_id" value={newUser.national_id} type="text" onChange={onChangeHandler} />
                                </div>
                            }


                            {!hiddenFields.includes('email') &&
                                <div className="col-12  col-sm-8 ">
                                    <TextField required label={t("Email")} name="email" value={newUser.email} type="text" onChange={onChangeHandler} />
                                </div>
                            }
                            {!hiddenFields.includes('mobile') &&
                                (companyDetails.config.register === undefined ||
                                    companyDetails.config.register.mobile_verification === undefined ||
                                    !companyDetails.config.register.mobile_verification) &&
                                <div className="col-12  col-sm-4 ">

                                    
                                    <PhoneInput label={t("Mobile")} type="text" name="mobile" value={newUser.mobile} onChange={onChangeHandler}/>
                                    {/* <TextField label={t("Mobile")} type="text" name="mobile" value={newUser.mobile} onChange={onChangeHandler} /> */}
                                </div>
                            }

                            {!hiddenFields.includes('currency') &&
                                <div className="col-12  col-sm-4 ">
                                    <BasicSelect
                                        label={t("Currency")}
                                        idProperty="currency"
                                        nameProperty="currency"
                                        name="currency"
                                        data="currencies"
                                        basicInfoName="CurrenciesCompany"
                                        onChange={onChangeHandler}
                                        value={newUser.currency}
                                        noEmptyValue
                                    />

                                </div>
                            }
                            {!hiddenFields.includes('first_name') &&
                                <div className="col-12  col-sm-6  ">
                                    <TextField name="first_name" value={newUser.first_name} label={t("First name")} type="text" onChange={onChangeHandler} />
                                </div>}
                            {!hiddenFields.includes('last_name') &&
                                <div className="col-12  col-sm-6  ">
                                    <TextField name="last_name" value={newUser.last_name} label={t("Last name")} type="text" onChange={onChangeHandler} />
                                </div>
                            }
                            {!hiddenFields.includes('comments') &&
                                <div className="col-12  ">
                                    <TextField multiline name="comments" value={newUser.comments} label={t("Comments")} type="text" onChange={onChangeHandler} />
                                </div>}

                        </div>
                    }
                </div>
            </form>
            <div className="row">
                <div className="col-12">
                    <button
                        type="button"
                        className="btn btn-primary"
                        onClick={register}
                    >
                        {t('Register')}
                    </button>
                </div>
            </div>

        </>
    )
}
export default (CreateUser);