import { TextField } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import LayoutSection from '../../components/NavigationAndLayouts/LayoutSection';
import AdminClient from '../../AdminClient';
import utils from '../../utils';
import BasicTable from '../../components/basic/BasicTable';
import AgentsMenu from '../../components/AgentsMenu';
import CustomRenders from '../../context/customRenders';
import useFormValidation from '../../hooks/parsleyValidation';

function UserSearch(props) {
    const company = utils.getSessionItem('Company');
    const companyDetails = useMemo(() => utils.getCurrentCompany(), []);
    const useAgentsSearch = companyDetails.config.backoffice?.agents?.agentsUserSearch || false
    const session = JSON.parse(utils.getSessionItem('user')).session;
    const adminClient = new AdminClient();
    const { t } = props;
    const [userSearch, setUserSearch] = useState("");
    const [tableData, setTableData] = useState(<></>);

    const { formValidation } = useFormValidation('Form_UserSearch')


    const dataHeader = {
        title: t('User Search'),
        urlHelp: '',
        idDoc: props.idDoc
    };

    useEffect(function () {
        window.addEventListener("keydown", (event) => {
            if (event.key === "Enter" || event.code === "NumpadEnter") {
                document.getElementById("searchButton").click();
            }
        });

    }, []);
    const onChangeHandler = (e) => {
        const { value } = e.target || e;
        setUserSearch(value);
    };

    const goDetails = (user) => {
        utils.setSessionItem("current-user", user);
        let userAux = user.split(".");
        if (!useAgentsSearch) {
            props.history.push('/cashiers/user_accounts')
        } else window.open('/agents/user_accounts/' + userAux[0] + "/" + userAux[1], "_blank")

    }

    const usersList = (data) => {

        let renderReports = new CustomRenders()

        let columnsDef = [

        ]
        if (useAgentsSearch) {
            columnsDef.push({
                Header: t("Username"), accessor: 'alias', typeCell: 'text',
                Cell: (props) => {
                    return <label onClick={() => goDetails(props.cell.row.original.db + "." + props.cell.row.original.user)}>{renderReports.renderAgentAlias(props.cell.row.original.alias, props.row.original, t, props.column)}</label>
                },
            })

            columnsDef.push({
                Header: "", accessor: 'actionsAmount', typeCell: 'text', extraData: { "finishOperation": search },
                showAlert: props.showAlert, handleCloseAlert: props.handleCloseAlert,
                Cell: (props) => {

                    let rowDataOriginal = props.row.original
                    rowDataOriginal['type'] = 'player'
                    return renderReports.renderAgentsPlayersOperations(props.value, rowDataOriginal, t, props.column)
                },
            })
        } else {
            columnsDef.push({
                Header: t("Username"), accessor: 'alias', typeCell: 'text',
                Cell: (props) => {
                    return <label onClick={() => goDetails(props.cell.row.original.db + "." + props.cell.row.original.user)}>{props.cell.row.original.alias}</label>
                },
            })
            columnsDef.push({
                Header: t("Name"), accessor: 'complete_name', typeCell: 'text',
                Cell: (props) => {
                    let complete_name = ""
                    if (props.cell.row.original.first_name) {
                        complete_name = props.cell.row.original.first_name;
                    }
                    if (props.cell.row.original.last_name) {
                        complete_name += " " + props.cell.row.original.last_name;
                    }

                    return <label onClick={() => goDetails(props.cell.row.original.db + "." + props.cell.row.original.user)}>{complete_name}</label>
                },
            })
            columnsDef.push({
                Header: t("Email"), accessor: 'email', typeCell: 'text',
                Cell: (props) => {
                    return <label onClick={() => goDetails(props.cell.row.original.db + "." + props.cell.row.original.user)}>{props.cell.row.original.email}</label>
                },
            })
            columnsDef.push({
                Header: t("Created date"), accessor: 'created_date', typeCell: 'date',
                Cell: (props) => {
                    return <label onClick={() => goDetails(props.cell.row.original.db + "." + props.cell.row.original.user)}>{props.cell.row.original.created_date}</label>
                },
            })

        }

        setTableData(<BasicTable data={data} columns={columnsDef} showFilterColumn={false} />)
    }

    const search = (evt) => {

        if (formValidation.validate()) {
            let promise
            let searchString = userSearch
            if (userSearch.length > 3) searchString = userSearch + "%"
            if (useAgentsSearch) promise = adminClient.agentsUsersSearch(session, company, searchString)
            else promise = adminClient.userSearch(session, company, userSearch)
            promise.then(
                function (msg) {

                    usersList(msg.users);

                }, function (err) {

                    console.log("reportPromise error " + err.code);
                    props.showAlert(t("User search"), <p>{t("Error") + t(err.description)}</p>)
                }
            );
        }

    }

    return (
        <>
            <LayoutSection {...props} dataHeader={dataHeader}>
                <div className="row">
                    <form onSubmit={(evt) => { evt.preventDefault(); return false; }} id="Form_UserSearch">
                        <div className="col-12">
                            <TextField label={t("Enter search term")} type="text" onChange={onChangeHandler} value={userSearch}
                                InputProps={{ inputProps: { "data-parsley-required": "true", "minlength": "3" } }} />
                        </div>
                    </form>
                    <div className="col-12">
                        <button
                            id="searchButton"
                            type="button"
                            className="btn btn-primary"
                            onClick={search}
                        >
                            {t('Search')}
                        </button>
                    </div>
                    <div className='col-12'>
                        {tableData}
                    </div>
                </div>

            </LayoutSection>
            {useAgentsSearch &&
                <AgentsMenu></AgentsMenu>}
        </>
    )
}
export default withTranslation()(UserSearch);